/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import React, { useContext, useEffect, useState } from 'react';
import MapContext from "../../context/MapContext";
import SVGCurveLine from "./SVGCurveLine";
import SVGLLine from "./SVGLLine";
import SVGTag from "./SVGTag";
import classesModule from "./SVGZone.module.scss";

const SVGCardMapCurveLine = ({ from, to, classId, type, visible, id, onUpdatedLine, isFirst, dotter, useOpacity = false, ...props }) => {
  const mapContext = useContext(MapContext);
  const [points, setPoints] = useState();
  useEffect(() => {
    const newPoint = getNewPoints();
    setPoints(newPoint)
  }, [
    _.get(from, "current.current.offsetLeft"), 
    _.get(from, "current.current.offsetTop"), 
    _.get(from, "current.current.offsetWidth"), 
    _.get(from, "current.current.offsetHeight"),
    _.get(to, "current.current.offsetLeft"), 
    _.get(to, "current.current.offsetTop"), 
    _.get(to, "current.current.offsetWidth"), 
    _.get(to, "current.current.offsetHeight")
  ])

  const getNewPoints = () => {
    var source = { x: _.get(from, "current.current.offsetLeft"), y: _.get(from, "current.current.offsetTop"), width: _.get(from, "current.current.offsetWidth"), height: _.get(from, "current.current.offsetHeight") };
    var target = { x: _.get(to, "current.current.offsetLeft"), y: _.get(to, "current.current.offsetTop"), width: _.get(to, "current.current.offsetWidth"), height: _.get(to, "current.current.offsetHeight") };
    let newPoint = {};
    if (type === "curve") {
      newPoint = {
        fromX: (source.x < target.x ? source.x + source.width : source.x),
        fromY: (source.y + source.height / 2),
        toX: (target.x < source.x ? target.x + target.width : target.x),
        toY: (target.y + target.height / 2),
        curve: 66
      }
    } else if (type === "l") {
      newPoint = {
        fromX: source.x + 1,
        fromY: (source.y + (source.height / 5) * 4),
        toX: target.x + 1,
        toY: (target.y + target.height / 5),
        curve: 20
      }
    }
    return newPoint;
  }

  useEffect(() => {
    if (from && to) {
      const newPoint = getNewPoints();
      setPoints(newPoint)
    }
  }, [from, to])

  useEffect(() => {
    if (_.get(mapContext, `lines.${id}`, false)) {
      const newPoint = getNewPoints();
      setPoints(newPoint)
      onUpdatedLine(id)
    }
  }, [JSON.stringify(_.get(mapContext, "lines", {}))])

  return (
    <React.Fragment>
      {points && visible && type === "l" && props.drawLines && (
        <React.Fragment>
          <SVGLLine {...points} id={id} dotter={dotter} displacementX={isFirst ? -11.7 : -52} className={classesModule.line_groupCompany}></SVGLLine>
          <SVGTag {...points} id={id} displacementX={isFirst ? -11.7 : -52} percent={props.percent}></SVGTag>
        </React.Fragment>
      )}
      {points && visible && type === "curve" && (
        <SVGCurveLine {...points} id={id} dotter={dotter} useOpacity={useOpacity} className={`${classesModule.defaultLine} ${classesModule[`line_${classId}`]}`}></SVGCurveLine>
      )}
    </React.Fragment>
  )
};

export default SVGCardMapCurveLine;

