import omit from "lodash/omit";
import { listPossibleSourcesOfWealth } from "../steps/ReviewPersonalDetailSofFormStep";
import _ from "lodash";

const useMapperStakeholderDetails = () => {
  const getInitialData = (stakeholder) => {
    const sourceOfFunds = (stakeholder.sourcesOfFunds || []).find(
      (s) => s.jurisdictionType === "ADGM"
    );
    return {
      stakeholder,
      PEP: {
        isPEP:
          stakeholder?.pep?.isPEP ||
          stakeholder?.pep?.isFamilyMemberOfAPEP ||
          stakeholder?.pep?.isCloseAssociateOfAPEP,
        descriptionOfPEP:
          stakeholder?.pep?.descriptionOfPEP ||
          stakeholder?.pep?.familyMemberOfAPEPDescription ||
          stakeholder?.pep?.closeAssociateOfAPEP,
      },
      sourceOfFunds: sourceOfFunds
        ? listPossibleSourcesOfWealth.some(
            (v) => v.value === sourceOfFunds?.sourceOfWealth
          )
          ? sourceOfFunds
          : {
              ...sourceOfFunds,
              sourceOfWealth: "others",
              otherSourcesOfWealth: sourceOfFunds?.sourceOfWealth,
            }
        : {},
      address: {
        address: stakeholder.address,
      },
    };
  };

  const getSubmitData = (context) => {
    const {
      ReviewPersonalDetailConfirmDetailForm,
      ReviewPersonalDetailAddressesStepForm,
      ReviewPersonalDetailPEPStepForm,
      ReviewPersonalDetailFitAndProperStatementStepForm,
      ReviewPersonalDetailSofFormStep,
      initialData,
    } = context;

    const submitData = {
      stakeholder: {
        ...omit(ReviewPersonalDetailConfirmDetailForm, "countryNationality"),
        pep: {
          ...ReviewPersonalDetailPEPStepForm,
        },
        address: ReviewPersonalDetailAddressesStepForm.address,
        fitAndProperStatement: ReviewPersonalDetailFitAndProperStatementStepForm,
        sourcesOfFunds: [
          {
            ...initialData.sourceOfFunds,
            sourceOfWealth:
              ReviewPersonalDetailSofFormStep.sourceOfWealth === "others"
                ? ReviewPersonalDetailSofFormStep.otherSourcesOfWealth
                : ReviewPersonalDetailSofFormStep.sourceOfWealth,
          },
        ],
      },
    };

    return {
      stakeholder: {
        ...submitData.stakeholder,
        sourcesOfFunds: _.map(
          submitData?.stakeholder?.sourcesOfFunds,
          (source) => _.omit(source, "otherSourcesOfWealth")
        ),
      },
    };
  };

  return { getInitialData, getSubmitData };
};
export default useMapperStakeholderDetails;
