import useSession from "src/modules/session/hooks/useSession";
import updateStakeholderExpiredDocument from "../graphql/useUpdateMainIdentityDocumentStakeholder";

const useServices = ({ initialValues }) => {
  const { stakeholderId, documentId, documentType, country } = initialValues;
  const { startupId } = useSession();
  const mutation = updateStakeholderExpiredDocument();

  return {
    initialData: async () => {
      return initialValues;
    },
    SaveStep: async (context) => {
      const {
        HasBeenRenewedStep,
        DocumentDetailsStep,
        UploadDocumentStep,
      } = context;
      const { renewed, description } = HasBeenRenewedStep;

      let identityDocumentData: {
        id: string;
        type: string;
        nationality: string;
        notRenewed?: boolean;
        notRenewedReason?: string;
        files?: any;
        number?: string;
        expiryDate?: string;
      } = { id: documentId, type: documentType, nationality: country?.code };

      if (renewed === false) {
        identityDocumentData = {
          ...identityDocumentData,
          notRenewed: true,
          notRenewedReason: description,
        };
      } else {
        const { files } = UploadDocumentStep;
        const { passportNumber, expiryDate } = DocumentDetailsStep;

        identityDocumentData = {
          ...identityDocumentData,
          files,
          number: passportNumber,
          expiryDate,
        };
      }

      const variables = {
        stakeholderId,
        startupId,
        identityDocumentData,
      };

      return await mutation({ variables });
    },
  };
};

export default useServices;
