import _ from "lodash";
import { useMemo } from "react";
import useSession from "src/modules/session/hooks/useSession";
import useUploadBulkDocuments from "src/modules/startup/Stakeholder/graphql/mutation/useUploadBulkDocuments";
import useUpsertRenewalData from "src/modules/startup/Stakeholder/graphql/mutation/useUpsertRenewalData";
const useServices = ({ initialValues }) => {
  const { mutation: uploadDocuments } = useUploadBulkDocuments();
  const { startupId } = useSession();
  const mutation = useUpsertRenewalData();

  return useMemo(
    () => ({
      initialData: async (context, event) => {
        return initialValues;
      },

      SaveStep: async (context, event) => {
        const groupCompanyId = context?.initialData?.groupCompanyId;
        const file = _.get(
          context,
          "UploadAnnualAccounts.documentAnnualAccounts",
          ""
        );
        const documents = [];
        documents.push({
          title: file.name,
          file: file,
          type: "ANNUAL_ACCOUNTS",
          status: "Uploaded",
          parties: [],
          groupCompanies: [{ groupCompany: groupCompanyId }],
          subCategories: ["miscellaneous"],
        });
        const result = await uploadDocuments({
          variables: { startupId, documents: documents },
        });
        if (result?.data)
          await mutation({
            variables: {
              startupId,
              groupCompanyId,
              renewalData: {
                annualAccounts: result?.data?.uploadBulkDocuments[0].id,
              },
            },
          });
        return result;
      },
    }),
    [initialValues]
  );
};

export default useServices;
