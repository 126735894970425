import { RELEVANT_ACTIVITIES } from 'src/modules/startup/GroupCompany/Forms/FormBusinessActivityCayman';
import { TOOLTIP } from "@blueprintjs/core/lib/esm/common/classes";

export default {
  RELEVANT_ACTIVITIES_LABELS: {
    ACTING_AS_HOLDING_COMPANY: "Acting as Holding Company",
    BANKING: "Banking",
    DISTRIBUTION_AND_SERVICE: "Distribution and Service Centre",
    FINANCING_AND_LEASING: "Financing and Leasing Business",
    SHIPPING: "Shipping",
    FUND_MANAGEMENT: "Fund Management",
    ACTING_AS_HEADQUARTERS: "Acting as Headquarters",
    INSURANCE: "Insurance",
    INTELLECTUAL_PROPERTY: "Intellectual Property",
    NONE_OF_ABOVE: "None of the above",
  },
  COMMON: {
    SUBTITLE: "Let’s renew your company",
    TITLE: "What happens next",
  },
  WHATHAPPENS: {
    STEP_1: {
      TOOLTIP_1: "It is important to complete the renewal process ahead of the 31st of December to avoid fines for the company.<br><br>If you do not wish to renew, we strongly recommend that you close your Cayman company with Clara’s support. Doing nothing may result in fines and penalties.",
      TOOLTIP_2: "Please note, if the company’s Authorised Share Capital amount is greater than {{{currency}}} {{{amount}}} <strong>additional fees might apply.</strong>",
      CONTENT_1: "Next steps",
      CONTENT_2: "To renew your company, you must first confirm the company details by completing the renewal tasks on the Dashboard and submit the information to Clara for review. If you need to make any changes to this information, you will be able to send a request when completing the tasks (please note that some changes may incur fees).",
      CONTENT_3: "Costs",
      CONTENT_4: "The renewal fee for your Cayman entity is <strong>{{currency}} {{amount}}</strong> .",
      CONTENT_5: "Or click ",
      CONTENT_6: "here",
      CONTENT_7: " if you want to close this company.",
      BTN_START: "Let’s start",
    },
  },
  CLOSEDOWNCOMPANY: {
    STEP_1: {
      SUBTITLE: "Close down {{{companyName}}}",
      TITLE: "Close down your company",
      TOOLTIP_1: "To close down a company, the directors will sign a board resolution approving the striking of the company from the registrar.",
      CONTENT_1: "The costs to close down your company will be <strong>{{{currency}}} {{{amount}}}</strong>.  You must pay the fee and submit your request to Clara. <br><br>A board resolution approving the company’s strike off will then be sent to all the directors for signature via Docusign. Please keep an eye on your email.",
      BTN_START: "Proceed with strike off",
    },
  },
  UPLOAD_ANNUAL_ACCOUNTS_MODAL: {
    STEP_1: {
      TITLE: "Upload Annual Accounts",
      SUBTITLE: "Upload Annual Accounts",
      TOOLTIP_1: "Startup TopCo accounts are often nil as they are not transacting and are acting as pure equity holding companies. If this is the case, you can download our blank template and re-upload it to complete this task.",
      CONTENT_1: "Please submit a copy of the company Annual Accounts. You can download a template",
      FIELD_DOCUMENT: "Document",
    },
  },
  ECONOMIC_SUBSTANCE: {
    GENERIC: {
      SUBTITLE: "Review Economic Substance Confirmation"
    },
    COMPANY_DETAILS: {
      TITLE: "Review and confirm company details",
      DESCRIPTION: "Please confirm the below details to complete the economic substance and annual renewal process.",
      TOOLTIP_1: "To change your company name additional fees will apply.",
      TOOLTIP_2: "Economic Substance: All Cayman companies are required to make an annual statement about whether or not it is carrying out one or more of a defined list of relevant activities. The company is required to meet the economic substance test for this activity. The test varies depending of the relevant activity conducted. Click <a target=\"_blank\" href=\"https://help.clara.co/en/articles/6925004-economic-substance-an-overview-of-requirements-in-the-cayman-islands\">here</a> to read more.",
      NAME_BANNER: "Costs to file this change start from <strong>{{currency}} {{price}}</strong>. This item will be added to your cart once the task is completed. To pay and submit it, go to the Dashboard and click \"View cart\"."
    },
    COMPANY_ADDRESS: {
      TITLE: "Review and confirm company address",
      TOOLTIP: "The <strong>registered address</strong> for your Cayman company is Clara’s registered office address and cannot be changed.",
    },
    RELEVANT_ACTIVITIES: {
      TITLE: "Review and confirm the company's relevant activities",
      TOOLTIP_1: "Cayman imposes different economic substance requirements on the company based on the activity of the company. <br/> <br/>If your activity is NOT acting as a holding company OR none of the above you will be subject to a more strenuous economic substance test.",
      TOOLTIP_2: "Click <a target=\"_blank\" href=\"https://help.clara.co/en/articles/6385789-what-is-a-relevant-activity-for-economic-substance-purposes\">here</a> to read definitions of each relevant activity.",
      TOOLTIP_3: "Click <a target=\"_blank\" href=\"https://help.clara.co/en/articles/6925004-economic-substance-an-overview-of-requirements-in-the-cayman-islands\">here</a> to read about Economic Substance.",
      ACTIVITIES_LABEL: "Relevant activity (Select all that apply)",
      ACTIVITIES_BANNER: "Changing the activity will result in a more strenuous economic substance test. If you decide to proceed, Clara will review and be in touch with further details.",
    },
    TAX_INFORMATION: {
      TITLE: "Review and confirm company tax information",
      TOOLTIP: 'Click <a target="_blank" href="https://help.clara.co/en/articles/8441344-economic-substance-tax-residency-outside-the-cayman-islands">here</a> to learn more about the tax residency outside the Cayman Islands. <br/><br/>' +
        'Click <a target="_blank" href="https://help.clara.co/en/articles/8441357-economic-substance-investment-funds">here</a> to learn more about investment funds.',
      OPTION_1: "Is the entity tax resident outside of the Cayman Islands?",
      OPTION_2: "Can the Entity be characterised as an “Investment Fund”?",
      OPTION_3: "Is the Entity registered with the Cayman Islands Monetary Authority? (CIMA Regulated)",
      OPTION_4: "Does the Entity hold a Cayman Islands trade and business license from the Department of Commerce and Investment? (Trade and Business License)",
      OPTION_5: "Does the Entity hold a Cayman Islands Local Companies (Control) License? (LCCL Holder)",
      OPTION_6: "Does the company have an FI Number issued by the Cayman Islands Department for International Tax Cooperation?",
      OPTION_7: "Does the company have an IRS GIIN?",
    }
  }
}