import _ from "lodash";
import { listPossibleSourcesOfWealth } from "../steps/ReviewPersonalDetailShareholderSofFormStep";

const useMapperStakeholderDetails = () => {
  const getInitialData = (stakeholder) => {
    const sourceOfFunds = stakeholder.sourcesOfFunds.find(
      (s) => s.jurisdictionType === "ADGM"
    );

    return {
      stakeholder,
      PEP: {
        isPEP:
          stakeholder?.pep?.isPEP ||
          stakeholder?.pep?.isFamilyMemberOfAPEP ||
          stakeholder?.pep?.isCloseAssociateOfAPEP,
        descriptionOfPEP:
          stakeholder?.pep?.descriptionOfPEP ||
          stakeholder?.pep?.familyMemberOfAPEPDescription ||
          stakeholder?.pep?.closeAssociateOfAPEP,
      },
      sourceOfFunds: sourceOfFunds
        ? listPossibleSourcesOfWealth.some(
            (v) => v.value === sourceOfFunds?.sourceOfWealth
          )
          ? sourceOfFunds
          : {
              ...sourceOfFunds,
              sourceOfWealth: "others",
              otherSourcesOfWealth: sourceOfFunds?.sourceOfWealth,
            }
        : {},
      address: {
        address: stakeholder.address,
      },
    };
  };

  const getSubmitData = (context) => {
    const {
      ReviewPersonalDetailShareholderConfirmDetailForm,
      ReviewPersonalDetailShareholderAddressesStepForm,
      ReviewPersonalDetailShareholderPEPStepForm,
      ReviewPersonalDetailShareholderSofFormStep,
      initialData,
    } = context;
    const principalAddress =
      ReviewPersonalDetailShareholderAddressesStepForm?.address ?? {};

    const submitData = {
      stakeholder: {
        ..._.omit(
          ReviewPersonalDetailShareholderConfirmDetailForm,
          "countryNationality"
        ),
        pep: {
          ...ReviewPersonalDetailShareholderPEPStepForm,
        },
        address: principalAddress,
        sourcesOfFunds: [
          {
            ...initialData.sourceOfFunds,
          },
        ],
      },
    };

    if(ReviewPersonalDetailShareholderSofFormStep){
      submitData.stakeholder.sourcesOfFunds[0].sourceOfWealth =   ReviewPersonalDetailShareholderSofFormStep.sourceOfWealth === "others"
          ? ReviewPersonalDetailShareholderSofFormStep.otherSourcesOfWealth
          : ReviewPersonalDetailShareholderSofFormStep.sourceOfWealth
    }

    return {
      stakeholder: {
        ...submitData.stakeholder,
        sourcesOfFunds: _.map(
          submitData?.stakeholder?.sourcesOfFunds,
          (source) => _.omit(source, "otherSourcesOfWealth")
        ),
      },
    };
  };

  return { getInitialData, getSubmitData };
};

export default useMapperStakeholderDetails;
