import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import Line from "src/components/generic/Line/Line";
import { LoadingProvider } from "src/modules/generic/hooks/useLoading";
import ModalAssistant from "src/modules/generic/components/Modal/ModalAssistant";
import SkeletonArea from "src/modules/generic/components/Skeletons/Overlays/SkeletonArea";
import SkeletonInput from "src/modules/generic/components/Skeletons/Overlays/SkeletonInput";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import useSession from "src/modules/session/hooks/useSession";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import useUpdateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useUpdateStakeholder";
import AddressesForm from "src/components/forms/modules/startups/individualStakeholder/AddressesForm";
import AddressesFormADGM from "src/components/forms/modules/startups/individualStakeholder/AddressesFormADGM";

const EditAddresses = ({
  showModal,
  open,
  refresh,
  handleCloseModal,
  data,
  isManagedByClara,
  jurisdictionType,
}) => {
  const { translate } = useTranslate();
  const { startupId } = useSession();
  const params = useParams();
  const overlayName = translate("EDIT_DETAILS");

  const handleCloseForm = () => {
    refresh && refresh();
    handleCloseModal();
  };

  const {
    updateStakeholder: upsertIndividualStakeholder,
  } = useUpdateStakeholder({
    isEdit: true,
    onCompleted: handleCloseForm,
    successMessage: "EDIT_INDIVIDUAL_STAKEHOLDER_NOTIFICATION_SUCCESS",
    paramsSuccessMessage: { overlayName },
    showSuccessNotification: true,
  });

  const handleSubmit = async (values) => {
    const { address } = values;
    const stakeholderData = {
      stakeholder: {
        address,
      },
    };
    await upsertIndividualStakeholder(
      {
        variables: { startupId, stakeholderId: params.id, stakeholderData },
      },
      {
        paramsSuccessMessage: { overlayName: stakeholderData?.fullName ?? "" },
      }
    );
  };

  const initialValues = useMemo(() => {
    if (jurisdictionType === "ADGM") {
      return {
        address: {
          country: data?.address?.country?.code,
          street: data?.address?.street,
          city: data?.address?.city,
          state: data?.address?.state,
          zipCode: data?.address?.zipCode,
        }
      };
    }

    return {
      address: {
        country: data?.address?.country?.code,
        street: data?.address?.street,
        city: data?.address?.city,
        state: data?.address?.state,
        zipCode: data?.address?.zipCode,
      },
    };
  }, [data, jurisdictionType]);

  const renderIndividualStakeholderForm = () => {
    if (jurisdictionType === "ADGM") {
      return (
        <AddressesFormADGM
          initialValues={initialValues}
          handleCloseModal={handleCloseModal}
          handleSubmit={handleSubmit}
          Template={TemplateWizard}
          isManagedByClara={isManagedByClara}
          stakeholder={data}
          propsTemplate={{
            title: "Edit addresses",
            skeleton: (
              <>
                <SkeletonInput count={3} />
                <Line />
                <SkeletonInput />
                <SkeletonArea noLabel={true} />
              </>
            ),
          }}
        />
      );
    } else if (jurisdictionType === "CAYMAN") {
      return (
        <AddressesForm
          initialValues={initialValues}
          handleCloseModal={handleCloseModal}
          handleSubmit={handleSubmit}
          Template={TemplateWizard}
          isManagedByClara={isManagedByClara}
          propsTemplate={{
            title: "Edit addresses",
            skeleton: (
              <>
                <SkeletonInput count={3} />
                <Line />
                <SkeletonInput />
                <SkeletonArea noLabel={true} />
              </>
            ),
          }}
        />
      );
    } else {
      return (
        <AddressesForm
          initialValues={initialValues}
          handleCloseModal={handleCloseModal}
          handleSubmit={handleSubmit}
          Template={TemplateWizard}
          propsTemplate={{
            title: "Edit addresses",
            skeleton: (
              <>
                <SkeletonInput count={3} />
                <Line />
                <SkeletonInput />
                <SkeletonArea noLabel={true} />
              </>
            ),
          }}
        />
      );
    }
  };

  return (
    <>
      <ModalAssistant open={showModal || open}>
        <LoadingProvider>{renderIndividualStakeholderForm()}</LoadingProvider>
      </ModalAssistant>
    </>
  );
};

export default EditAddresses;
