import * as Yup from "yup";

import CalendarInputDate from "src/components/inputs/Calendars/CalendarInputDate";
import { Constants } from "src/v1/utils";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import CountryJurisdiction from "src/modules/generic/components/CountryJurisdictionSelector";
import Form from "src/components/forms/Form/Form";
import FormProps from "src/modules/generic/forms/interfaces/FormProps";
import { InputLabelWithBanner } from "src/components/inputs/InputWithBanner";
import React from "react";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "src/modules/generic/templates/Modal";
import TemplateLabel from "src/modules/generic/templates/Label";
import TextInput from "src/components/inputs/Text/TextInput";
import _ from "lodash";
import cls from "./Form.module.scss";
import moment from "moment";
import useTranslate from "src/modules/generic/hooks/useTranslate";

const schema = Yup.object().shape({
  name: Yup.string().required("This field is required").nullable(),
  authorisedShareCapital: Yup.number()
    .required("This field is required")
    .nullable()
    .typeError("This field is required"),
  linkedinURL: Yup.string()
    .matches(Constants.REGEXP.URL_LINKEDIN, {
      message: "Please enter a valid LinkedIn URL",
      excludeEmptyString: true,
    })
    .nullable(),
});

const FormGeneralDetailsCaymanManagedByClara: React.FC<FormProps> = ({
  initialValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}: FormProps) => {
  const { translate } = useTranslate();
  const authorisedShareCapitalValue = initialValues?.pincasData.CHANGE_COMPANY_AUTHORIZED_SHARE_CAPITAL.value

  const handleSubmit = (values) => {
    onCompleteSubmit && onCompleteSubmit(values);
  };

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <Template
        buttons={{
          ...buttons,
          submit: (
            <SubmitButton {...buttonSubmitProps}>Save changes</SubmitButton>
          ),
        }}
        props={propsTemplate}
      >
        <InputLabelWithBanner
          bannerLogicType="touched"
          component={TextInput}
          controllerInputProps={{ name: "name", placeholder: "Company name" }}
          templateLabelProps={{
            bannerTitle:
              'Costs to file this change start from <strong>USD 550</strong>. If you select "Save changes", this items will be added to your cart. Then go to the Dashboard, click "View cart" to pay and submit.',
            label: "Company name",
            isRequired: true,
          }}
        />
        <CountryJurisdiction disabled />
        <TemplateLabel label="Company type" isRequired>
          <ControllerInput
            name="type"
            render={TextInput}
            value={_.startCase(_.toLower(initialValues?.type))}
            className={cls.disabled}
            disabled
          />
        </TemplateLabel>
        <TemplateLabel label="Company registration number" isRequired>
          <ControllerInput
            name="registeredNumber"
            render={TextInput}
            disabled
            className={cls.disabled}
          />
        </TemplateLabel>
        <TemplateLabel label="Incorporation date" isRequired>
          <ControllerInput
            name="incorporation_date"
            render={CalendarInputDate}
            placeholder={translate("MODULES.CLARA.ROLE_DATE_INPUT_HIT")}
            disabled
          />
        </TemplateLabel>
        <TemplateLabel label="End of financial year" isRequired>
          <ControllerInput
            name="nextFinancialYearEnd"
            render={TextInput}
            className={cls.disabled}
            placeholder={translate("MODULES.CLARA.ROLE_DATE_INPUT_HIT")}
            value={
              _.get(initialValues, "nextFinancialYearEnd")
                ? moment(_.get(initialValues, "nextFinancialYearEnd")).format(
                    "D MMM"
                  )
                : "31 Dec"
            }
            disabled
          />
        </TemplateLabel>
        <TemplateLabel label="Currency" isRequired>
          <ControllerInput
            name="currency"
            render={TextInput}
            value={initialValues?.currency || "USD"}
            className={cls.disabled}
            disabled
          />
        </TemplateLabel>
        <InputLabelWithBanner
          bannerLogicType="touched"
          component={TextInput}
          controllerInputProps={{
            name: "authorisedShareCapital",
            placeholder: "Authorised share capital",
            type: "number",
            leftLabel: "USD",
          }}
          templateLabelProps={{
            bannerTitle:
              `Costs to file this change start from <strong>USD ${authorisedShareCapitalValue}</strong>. If you select "Save changes", this items will be added to your cart. Then go to the Dashboard, click "View cart" to pay and submit.`,
            label: "Authorised share capital",
            isRequired: true,
          }}
        />
        <TemplateLabel label="Linkedin URL" className={cls.lastInput}>
          <ControllerInput
            name="linkedinURL"
            placeholder="Linkedin URL"
            render={TextInput}
          />
        </TemplateLabel>
      </Template>
    </Form>
  );
};

export default FormGeneralDetailsCaymanManagedByClara;
