import React from "react";
import IcoMoon from "react-icomoon";
import PropTypes from "prop-types";
const iconSet = require("./icons/selection.json");
const iconClaraSet = require("./clara/selection.json");
 
const Icon = ({ isClara,isClara2023,...props }) => {
  const icoset= isClara?iconClaraSet:iconSet;
  return <IcoMoon iconSet={icoset} disableFill={true} {...props}
  />;
};
Icon.propTypes = {
  /**  Icon */
  icon: PropTypes.string.isRequired,
  /**  Size */
  size: PropTypes.string,
  /**  Type Button */
  isClara: PropTypes.bool,

};
Icon.defaultProps = {
  icon: "plus",
  size: "1rem",
  isClara: false,
};


export default Icon;