






// "TASK_RENEWAL_ADGM_STRIKE_OFF_PROCESS": no se usa




// "TASK_RENEWAL_KY_REVIEW_ECONOMIC_SUBSTANCE_CONFIRMATION"
// "TASK_RENEWAL_KY_START_RENEWAL_PROCESS"
// "TASK_RENEWAL_KY_STRIKE_OFF_PAY"
// "TASK_RENEWAL_KY_STRIKE_OFF_PROCESS"
// "TASK_RENEWAL_KY_STRIKE_OFF_REVIEWING_DATA"
// "TASK_RENEWAL_KY_STRIKE_OFF_SUBMIT"
// "TASK_RENEWAL_KY_SUBMIT"
// "TASK_RENEWAL_KY_UPLOAD_ANNUAL_ACCOUNTS"
// "TASK_SELECT_TOPCO_ACCELERATOR"
// "TASK_SELECT_TOPCO_IN_PROGRESS_ACCELERATOR"


// "TASK_SHARE_CLASSES_ADD"
// "TASK_SHARE_CLASS_ADD"
// "TASK_SHARE_CLASS_AMEND_PUBLISH"
// "TASK_SHARE_CLASS_AMEND_TRANSACTION_PUBLISH"
// "TASK_SHARE_CLASS_PUBLISH"
// "TASK_SHARE_ISSUANCES_ADD"
// "TASK_SHARE_ISSUANCE_ADD"
// "TASK_SHARE_ISSUANCE_CANCEL_TRANSACTION_PUBLISH"
// "TASK_SHARE_ISSUANCE_CANCEL_TRANSACTION_TO_CART_ADD"
// "TASK_SHARE_ISSUANCE_PUBLISH"
// "TASK_SHARE_ISSUANCE_TRANSFER_TRANSACTION_PUBLISH"
// "TASK_SHARE_ISSUANCE_TRANSFER_TRANSACTION_TO_CART_ADD"

// "TASK_SOURCE_OF_FUNDS_DIFC"
// "TASK_SOURCE_OF_FUNDS_GENERIC_BUILD"
// "TASK_SOURCE_OF_FUNDS_LBS_CORP_KYC_INC"

// "TASK_STAKEHOLDER_CORPORATE_KYC_INC"
// "TASK_STAKEHOLDER_INDIVIDUAL_KYC_INC"

// "TASK_TRUST_DEED_TD_CORP_KYC_INC"

// "TASK_UAE_ENTRY_STAMP_KYC_INC"
// "TASK_UAE_ENTRY_STAMP_SEC_SIGNER"
// "TASK_UAE_RESIDENCE_VISA_KYC_INC"
// "TASK_UID_STAMP_KYC_INC"

// "TASK_WARRANTS_ADD"
// "TASK_WARRANT_ADD"
// "TASK_WARRANT_CANCEL_TRANSACTION_PUBLISH"
// "TASK_WARRANT_EXCERCISE_TRANSACTION_PUBLISH"
// "TASK_WARRANT_EXCERCISE_TRANSACTION_TO_CART_ADD"
// "TASK_WARRANT_PUBLISH"


// Tareas que posiblemente sean cambios visuales

// TASK_RENEWAL_ADGM_REVIEWING_DATA: Cambios visuales
// TASK_RENEWAL_ADGM_START_RENEWAL_PROCESS: Cambio visaules
// TASK_RENEWAL_ADGM_STRIKE_OFF_PAY
// TASK_RENEWAL_ADGM_STRIKE_OFF_REVIEWING_DATA
// TASK_RENEWAL_ADGM_STRIKE_OFF_SUBMIT
// "TASK_RENEWAL_KY"
// "TASK_RENEWAL_KY_PAY"
// "TASK_RENEWAL_KY_REVIEWING_DATA"

// TASK_RENEWAL_ADGM_SUBMIT

import useModal from 'src/modules/generic/hooks/useModal';

import ReviewPersonalDetailModal from "src/views/modules/startups/renewal/adgm/modals/ReviewPersonalDetailModal";

import ReviewPersonalDetailUBOModal from "src/views/modules/startups/renewal/adgm/modals/ReviewDetailsUboModal";
import ConfirmationsOfShareholdersAndDirectorsModal
  from "src/views/modules/startups/renewal/adgm/modals/ConfirmationsOfShareholdersAndDirectorsModal";
import ReviewPersonalDetailShareholderModal
  from "src/views/modules/startups/renewal/adgm/modals/ReviewPersonalDetailShareholderModal";
import ReviewDetailsCorporateStakeholderModal
  from 'src/views/modules/startups/renewal/adgm/modals/ReviewDetailsCorporateStakeholderModal';
import ModalProofAddress from "src/modules/startup/Stakeholder/ProofOfAddress/ModalProofAddress";
import TechLicenceModal from "src/views/modules/startups/renewal/adgm/modals/TechLicenceModal";
import { useMemo } from 'react';
import PayFormationBundleModal from "src/modules/startup/PayFormationBundleModal";
import { Constants } from "src/v1/utils";

const useFunctionsTaskRenewal = () => {
  const { openModal } = useModal();

  const getProductCodeByJurisdictionType = (jurisdictionType) => {
    switch (jurisdictionType) {
      case "CAYMAN":
        return Constants.MODULES.CHECKOUT.PRODUCTS.CAYMAN_SHOPPING_CART;
      case "DELAWARE":
        return Constants.MODULES.CHECKOUT.PRODUCTS.DELAWARE_SHOPPING_CART;
      case "ADGM":
        return Constants.MODULES.CHECKOUT.PRODUCTS.ADGM_SHOPPING_CART;
      default:
        return null;
    }
  };

  const dictionary = useMemo(() => ({
    TASK_RENEWAL_ADGM_REVIEW_CONFIRMATION_DIRECTORS_SHAREHOLDERS: {
      onClick: (params) => {
        openModal(ConfirmationsOfShareholdersAndDirectorsModal, {
          initialValues: {
            idToSave: params?.stakeholderId,
          }

        }, 'TASK_RENEWAL_ADGM_REVIEW_CONFIRMATION_DIRECTORS_SHAREHOLDERS');
      },
    },
    TASK_RENEWAL_ADGM_REVIEW_CORPORATE_COMPANY_DETAILS: {

      onClick: (params) => {
        openModal(ReviewDetailsCorporateStakeholderModal, { initialValues: { stakeholderId: params?.stakeholderId } }, 'TASK_RENEWAL_ADGM_REVIEW_CORPORATE_COMPANY_DETAILS');
      },
    },
    "TASK_RENEWAL_ADGM_REVIEW_DETAILS_DIRECTOR": {
      onClick: (params) => {
        openModal(
          ReviewPersonalDetailModal,
          {
            initialValues: {
              groupCompanyId: params?.groupCompanyId,
              stakeholderId: params?.stakeholderId,
            },
          },
          "MODAL_TASK_RENEWAL_ADGM_REVIEW_DETAILS_DIRECTOR"
        );
      },
    },
    "TASK_RENEWAL_ADGM_REVIEW_DETAILS_SHAREHOLDER": {
      onClick: (params) => {
        openModal(
          ReviewPersonalDetailShareholderModal,
          {
            initialValues: {
              groupCompanyId: params?.groupCompanyId,
              stakeholderId: params?.stakeholderId,
            },
          },
          "MODAL_TASK_RENEWAL_ADGM_REVIEW_DETAILS_SHAREHOLDER"
        );
      },
    },
    "TASK_RENEWAL_ADGM_REVIEW_DETAILS_DATA_PROTECTION_CONTACT": {
      onClick: (params) => {
        openModal(
          ReviewPersonalDetailShareholderModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
              groupCompanyId: params?.groupCompanyId,
            },
          },
          "MODAL_TASK_RENEWAL_ADGM_REVIEW_DETAILS_DATA_PROTECTION_CONTACT"
        );
      },
    },
    "TASK_RENEWAL_ADGM_REVIEW_DETAILS_AUTHORISED_SIGNATORY": {
      onClick: (params) => {
        openModal(
          ReviewPersonalDetailModal,
          {
            initialValues: {
              groupCompanyId: params?.groupCompanyId,
              stakeholderId: params?.stakeholderId,
            },
          },
          "MODAL_TASK_RENEWAL_ADGM_REVIEW_DETAILS_AUTHORISED_SIGNATORY"
        );
      },
    },
    "TASK_RENEWAL_ADGM_REVIEW_DETAILS_UBO": {
      onClick: (params) => {
        openModal(
          ReviewPersonalDetailUBOModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
            },
          },
          "MODAL_TASK_RENEWAL_ADGM_REVIEW_DETAILS_UBO"
        );
      },

    },
    "TASK_RENEWAL_ADGM_PROOF_OF_ADDRESS": {
      onClick: (params) => {
        openModal(
          ModalProofAddress,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
              stakeholderName: params?.stakeholderName,
              isRenewal: true,
            },
          },
          "MODAL_TASK_RENEWAL_ADGM_PROOF_OF_ADDRESS"
        );
      },
    },
    "TASK_RENEWAL_ADGM_COMPLETE_TECH_LICENCE_QUESTIONNAIRE": {
      onClick: (params) => {
        openModal(
          TechLicenceModal,
          {
            ...params,
            initialValues: {
              groupCompanyId: params?.company?.id,
              groupCompanyName: params?.company?.name,
            },
          },
          "MODAL_TASK_RENEWAL_ADGM_COMPLETE_TECH_LICENCE_QUESTIONNAIRE"
        );
      },
    },
    TASK_RENEWAL_KY_STRIKE_OFF_PAY: {
      onClick: (params, callback) => {
        openModal(PayFormationBundleModal, {
          initialValues: {
            groupCompanyId: params?.id,
            productCode: getProductCodeByJurisdictionType(params?.jurisdictionType),
            needFirstContact: true,
            disabledPayCart: false,
            isPincasSubmit: false,
            callback
          }
        }, 'MODAL_PAY_FORMATION_BUNDLE');
      },
    },
    TASK_RENEWAL_ADGM_STRIKE_OFF_PAY: {
      onClick: (params, callback) => {
        openModal(PayFormationBundleModal, {
          initialValues: {
            groupCompanyId: params?.id,
            productCode: getProductCodeByJurisdictionType(params?.jurisdictionType),
            needFirstContact: true,
            disabledPayCart: false,
            isPincasSubmit: false,
            callback
          }
        }, 'MODAL_PAY_FORMATION_BUNDLE');
      },
    },
  }), [openModal]);

  return dictionary
}
export default useFunctionsTaskRenewal
