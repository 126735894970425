import { useParams } from "react-router-dom";
import useAddDocumentFormation from "../../../../../hooks/services/modules/individualStakeholder/useAddDocumentFormation";
import useSession from "../../../../session/hooks/useSession";
import _ from "lodash";
import useAddRenewalTasksDoneStakeholder from '../../graphql/queries/useAddRenewalTasksDoneStakeholder';
import useGetManagedByClaraConnectionsStakeholder from '../../graphql/queries/useGetManagedByClaraConnectionsStakeholder';
const useServices = ({ initialValues }) => {

  const { startupId } = useSession();
  const [updateDocument] = useAddDocumentFormation({});
  const { id } = useParams<any>();
  const addRenewalTasksDoneStakeholder = useAddRenewalTasksDoneStakeholder();
  const {manualQuery} = useGetManagedByClaraConnectionsStakeholder()

  return {
    initialData: async () => {
      const stakeholderId = initialValues?.idToSave || id;
      const { data } = await manualQuery({variables:{stakeholderId,startupId}})
      const groupCompany = (data?.getStakeholder?.managedByClaraConnections || []).find((item) => item?.statusOnMatters.isRenewal && item?.jurisdictionType === 'ADGM');
      return {...initialValues,groupCompany};
    },
    uploadDocument: async (context, event) => {
      const passportFile = _.get(context, "configurePassport");
      const idStakeholderToUse = _.get(context, "initialData.idToSave", id);
      const variables = {
        stakeholderId: idStakeholderToUse,
        startupId,
        identityDocumentData: {
          ...passportFile,
          nationality: _.get(context, "configurePassport.nationality"),
          number: _.get(context, "configurePassport.number"),
          type: "PASSPORT_DOCUMENT"
        },
      };
      await updateDocument({ variables });
    },
    confirmNationalitiesDone:async (context,event)=>{
      const groupCompanyId = context?.initialData?.groupCompany?.id
      const stakeholderId = context?.initialData?.idToSave || id;
      await addRenewalTasksDoneStakeholder(groupCompanyId,['areNationalitiesConfirmed','hasNewPassportsBeenUploaded'],stakeholderId)
      return;
    }
  }
}

export default useServices;