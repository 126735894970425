import React from 'react'
import * as Yup from 'yup'
import Form from '../../../../components/forms/Form/Form'
import useTranslate from '../../../generic/hooks/useTranslate'
import TextInput from '../../../../components/inputs/Text/TextInput'
import ControllerInput from '../../../generic/components/Inputs/ReactHookForm/ControllerInput'
import TemplateLabel from '../../../generic/templates/Label'
import SubmitButton from '../../../generic/components/Buttons/SubmitButton'
import FormProps from '../../../generic/forms/interfaces/FormProps';
import TemplateFormDefault from "../../../generic/templates/Modal";
import cls from './Form.module.scss';
import CalendarInputDate from '../../../../components/inputs/Calendars/CalendarInputDate'
import FileUploaderInput from '../../../../components/inputs/FileUploader/FileUploaderInput'

const schema = Yup.object().shape({
  startDate: Yup.string().required('This field is required').nullable()
})

const FormProfessionalHistory: React.FC<FormProps> = ({
  initialValues: defaultValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate
}) => {

  const { translate } = useTranslate();

  const handleSubmit = async values => {
    onCompleteSubmit && await onCompleteSubmit(values);
  }

  return (
    <Form
      defaultValues={defaultValues}
      schema={schema}
      onSubmit={handleSubmit}
      onlySendDirty={false}
      optionsForm={{ mode: "onChange" }}
    >
      <Template buttons={{ ...buttons, submit: <SubmitButton  {...buttonSubmitProps}>Add</SubmitButton> }} props={propsTemplate}>
        <TemplateLabel
          label={'Start date'}
          className={cls.TemplateLabel}
          isRequired
        >
          <ControllerInput
            render={CalendarInputDate}
            name='startDate'
            defaultlabel={''}
            placeholder='dd/mm/yyyy'
          />
        </TemplateLabel>
        <TemplateLabel
          label={'LinkedIn URL'}
          className={cls.TemplateLabel}
        >
          <ControllerInput
            render={TextInput}
            name='linkedinURL'
            defaultlabel={''}
            placeholder='Type or paste link here'
          />
        </TemplateLabel>
        <TemplateLabel
          label={'CV'}
          className={cls.TemplateLabel}
        >
          <ControllerInput
            render={FileUploaderInput}
            name='curriculumVitae'
            defaultlabel={''}
            placeholder='Include numbers only'
          />
        </TemplateLabel>
      </Template>
    </Form>
  )

}

export default FormProfessionalHistory;
