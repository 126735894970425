import ReviewPersonalDetailAddressesStepForm from '../steps/ReviewPersonalDetailAddressesStepForm';
import ReviewPersonalDetailConfirmDetailForm from '../steps/ReviewPersonalDetailConfirmDetailForm';
import ReviewPersonalDetailFitAndProperStatementStepForm from '../steps/ReviewPersonalDetailFitAndProperStatementStepForm';
import ReviewPersonalDetailNationalityStepForm from '../steps/ReviewPersonalDetailNationalityStepForm';
import ReviewPersonalDetailNomineeStepForm from '../steps/ReviewPersonalDetailNomineeStepForm';
import ReviewPersonalDetailPEPStepForm from '../steps/ReviewPersonalDetailPEPStepForm';
import ReviewPersonalDetailSofFormStep from '../steps/ReviewPersonalDetailSofFormStep';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import { useMemo } from 'react';

const useSteps = () => {

  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    ReviewPersonalDetailConfirmDetailForm: {
      render: ReviewPersonalDetailConfirmDetailForm,
    },
    ReviewPersonalDetailNationalityStepForm: {
      render: ReviewPersonalDetailNationalityStepForm,
    },
    ReviewPersonalDetailAddressesStepForm: {
      render: ReviewPersonalDetailAddressesStepForm,
    },
    ReviewPersonalDetailPEPStepForm: {
      render: ReviewPersonalDetailPEPStepForm,
    },
    ReviewPersonalDetailSofFormStep: {
      render: ReviewPersonalDetailSofFormStep,
    },
    ReviewPersonalDetailFitAndProperStatementStepForm: {
      render: ReviewPersonalDetailFitAndProperStatementStepForm,
    },
    ReviewPersonalDetailNomineeStepForm: {
      render: ReviewPersonalDetailNomineeStepForm,
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },
  }), []);
}

export default useSteps;
