import {AuthorizedSignatoryFragment, NoticesFragment} from "../../startup/Stakeholder/graphql/fragments";

const ProfileFragment = `fragment ProfileFragment on StakeHolder {
  avatar
  linkedinURL
  updatedAt
  fullName
  email
  passportNumber
  phoneNumber
  startDate
  endDate
  type
  companyType
  jurisdiction
  registeredNumber
  isAnEntity
  enterType
  entryDate
  nationality {
    code
    name
    __typename
  }
  address {
    street
    city
    state
    country {
      alpha3
      name
      code
      __typename
    }
    zipCode
    __typename
  }
}
`
const StakeholderDocumentsFragment = `fragment StakeholderDocuments on StakeHolder {
  documents {
    name
    id
    kind
    status
    freemiumValid
    type {
      label
      code
      __typename
    }
    groupCompanies {
      groupCompany {
        id
        avatar
        name
      }
      signedBy{
      id
      }      
    }
    stakeholders {
      id
      avatar
      fullName
      __typename
    }
    subCategories
    file {
      id
      name
      size
      type
      __typename
    }
    __typename
  }
}`
const StakeholderPositionsFragment = `fragment StakeholderPositions on StakeHolder {
  startupPosition {
    id
    position {
      name
      isDefault
      code
      responsibilities {
        name
        isDefault
        text
        code
        __typename
      }
    }
    department {
      id
      name
      isDefault
      __typename
    }
    __typename
  }
}`
const ConvertibleFieldsFragment = `fragment ConvertibleFields on Convertible {
  id
  amount { amount currency }
  documents {
    name
    type {
      code
      __typename
    }
    id
    status
    __typename
  }
  # discountRate
  # valuationCap
  # interestRate
  issuedDate
  groupCompany {
    id
    name
    avatar
    transactionCurrency
    __typename
  }
}`

// Main query for Stakeholder Details Page  -----

const GET_STAKEHOLDER_DETAIL_QUERY = `
${NoticesFragment}
${AuthorizedSignatoryFragment}
query getStakeholderDetail($startupId: ID!, $stakeholderId: ID!) {
  getStakeholder(startupId: $startupId, stakeholderId: $stakeholderId) {
    arabicName
    managedByClaraConnections {
      id
      name
      jurisdictionType
      keyPeople {
        dataProtectionContact {
          id
        }
      }
    }
    fitAndProperStatement{
      haveEverBeenInPrison { question description}
	  haveFinancialCharges { question description}
	  haveEverBeenBanned { question description}
	  haveBeenDisciplined { question description}
	  haveEverLostCourtCase { question description}
	  haveBeenDisqualified { question description}
	  haveBeenInBankruptCompany { question description}
	  havePendingProceedings { question description}
	  haveBeenInStatutoryManagement { question description}
    }
    curriculumVitae {
      id
      name
    }
    id
    isLocked
    profession
    sourcesOfFunds {
      jurisdictionType
      sourceOfFunds
      sourceOfWealth
      sourceOfFundsYesNo
    }
    isAnEntity
    isAuthorizedSignatory
    managedByClaraOnJurisdictionType
    isManagedByClara
    statusOnMatters {
      isLocked
      isRenewal
      onFields
    }
    isFounder
    pep {
      isPEP
      descriptionOfPEP
      isFamilyMemberOfAPEP
      familyMemberOfAPEPDescription
      isCloseAssociateOfAPEP
      closeAssociateOfAPEP
    }
    dateOfBirth
    recipientNotice {...NoticesFragment}
    authorizedSignatory {...AuthorizedSignatoryFragment}
    ...ProfileFragment
    ...StakeholderDocuments
    hasAgreements
    roles {
      ...RoleFields
    }
    rolesInProgress{
      toAdd{
        ...RoleFields
      }
      toDelete{
        ...RoleFields
      }
    }
    ...StakeholderPositions
    directors {
      id
      fullName
      avatar
      isAnEntity
    }
    previousName
    taxRegistrationNumber
    title
  }
}`;


export {
    ProfileFragment,
    ConvertibleFieldsFragment,
    StakeholderPositionsFragment,
    StakeholderDocumentsFragment,
    GET_STAKEHOLDER_DETAIL_QUERY,
}
