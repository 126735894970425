import useCustomMachine from '../../../../generic/hooks/useCustomMachine';
import React, { FC } from 'react';
import Button from '../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../generic/components/Wizard/Step";
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard';
import Form from "../../Forms/FormMoreDetailsStakeholder";
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import { addAssistantText, clearAssistatText } from '../../../../generic/store/action';
import useSession from "../../../../session/hooks/useSession";
import { useParams } from "react-router-dom";
import moment from "moment";
import useUpdateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useUpdateStakeholder";

const Step2: FC<StepProps> = () => {

  const { startupId } = useSession();
  const { id: _id } = useParams<any>();
  const { next, send, prev, state } = useCustomMachine();
  const id = state?.context?.data.id ?? _id;
  const dispatch = useDispatch();
  const { updateStakeholder } = useUpdateStakeholder({ showSuccessNotification: false }, {});

  const getValuesMutation = (values, state) => {
    const { personal_information } = state.context.data;
    return { ...personal_information, ...values, startDate: values.startDate ? moment(values.startDate).format('YYYY-MM-DD') : null, dateOfBirth: values.dateOfBirth ? moment(values.dateOfBirth).format('YYYY-MM-DD') : null };
  }

  React.useEffect(() => {
    dispatch(addAssistantText(`Some stakeholders have an additional profession which forms part of their role within the company. If this is the case, add it here. If not, just add their role e.g. 'director'.`));
    return () => {
      dispatch(clearAssistatText());
    }
  }, []);

  const handleCompleteSubmit = async values => {
    const variables = {
      startupId,
      stakeholderId: id,
      stakeholderData: {
        stakeholder: getValuesMutation(values, state)
      }
    }
    await updateStakeholder({ variables });
    next(values)
  }

  const handleCancel = async values => {
    send("CANCEL");
  }

  const handlePrev = () => {
    prev(get(state, 'context.data.more_details', {}));
  }

  return (
    <React.Fragment>
      <Form
        initialValues={get(state, 'context.data.more_details', {})}
        onCompleteSubmit={handleCompleteSubmit} Template={TemplateWizard} propsTemplate={{
          title: 'Now for a few more details...',
          subTitle: "Personal information"
        }}
        buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button>, previous: <Button onClick={handlePrev} variant="secondary">Previous</Button> }}>
      </Form>
    </React.Fragment>
  )
}

export default Step2;
