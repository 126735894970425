import { listPossibleSourcesOfWealth } from "../steps/ReviewUboSofFormStep";

const useMapperStakeholderDetails = () => {
  const getInitialData = (stakeholder) => {
    const sourceOfFunds = stakeholder.sourcesOfFunds.find(
      (s) => s.jurisdictionType === "ADGM"
    );

    return {
      stakeholder,
      PEP: {
        isPEP:
          stakeholder?.pep?.isPEP ||
          stakeholder?.pep?.isFamilyMemberOfAPEP ||
          stakeholder?.pep?.isCloseAssociateOfAPEP,
        descriptionOfPEP:
          stakeholder?.pep?.descriptionOfPEP ||
          stakeholder?.pep?.familyMemberOfAPEPDescription ||
          stakeholder?.pep?.closeAssociateOfAPEP,
      },
      sourceOfFunds: sourceOfFunds
        ? listPossibleSourcesOfWealth.some(
            (v) => v.value === sourceOfFunds?.sourceOfWealth
          )
          ? sourceOfFunds
          : {
              ...sourceOfFunds,
              sourceOfWealth: "others",
              otherSourcesOfWealth: sourceOfFunds?.sourceOfWealth,
            }
        : {},
      address: {
        address: stakeholder.address,
      },
      groupCompany: stakeholder.managedByClaraConnections.find(
        (g) => g.jurisdictionType === "ADGM" && g.statusOnMatters.isRenewal
      ),
    };
  };

  return { getInitialData };
};

export default useMapperStakeholderDetails;
