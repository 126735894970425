import Collapse from '@material-ui/core/Collapse';
import classNames from "classnames";
import _ from "lodash";
import React, { useEffect, useRef, useState, createElement, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import RowAdd from '../../../../components/forms/contents/RowAdd/RowAdd';
import CardInput from "../../../../components/inputs/card/CardInput";
import Checkbox from "../../../../components/inputs/Checkbox/Checkbox";
import HiddenField from "../../../../components/inputs/HiddenField/HiddenField.js";
import CardSelector from "../../../../components/inputs/Select/CardSelector/CardSelector";
import CountrySelector from "../../../../components/inputs/Select/CountrySelector/CountrySelector";
import TextInput from "../../../../components/inputs/Text/TextInput";
import Text from "../../../../components/text/Text/Text";
import Title from "../../../../components/text/Title/Title";
import useTextLang from "../../../../hooks/custom/useTextLang";
import useGetSystemConfig from "../../../../hooks/services/modules/config/useGetSystemConfig";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import classesModule from "./PaymentForm.module.scss";
import usePayment from "../../hooks/usePayment";

const TermsComponent = ({ terms, termsCustomComponent, propsComponent = {} }) => {
  const propsTerms = useMemo(() => (!termsCustomComponent ? { className: classesModule.paymentTerms, dangerouslySetInnerHTML: { __html: terms } } : { terms, ...propsComponent }), [terms, termsCustomComponent, propsComponent]);
  return createElement(termsCustomComponent || 'div', propsTerms);
}

const PaymentFormContent = ({
  errorMessageCard,
  showTitle = true,
  canSelectCard = true,
  onCompleteForm,
  selectCard = false,
  useCard = false,
  setCard,
  setIsZipCodeRequired,
  onErrorForm,
  onChangeVat,
  onChangeFreeVat,
  errorCard,
  product,
  defaultVat,
  enabledVat = true,
  terms,
  termsComponent = null,
  propsTermsComponent = {},
  ...props
}) => {

  const { setValue, getValues } = useFormContext();
  const _errorCardText = useTextLang("PAYMENT_ERROR_CARD");
  const errorCardText = errorMessageCard || _errorCardText
  const { data: zipCodes } = useGetSystemConfig({ "key": "REQUIRE_ZIP_CODE_COUNTRIES" }, { showSuccessNotification: false, showErrorNotification: false });
  const { data: vats } = useGetSystemConfig({ "key": "COUNTRY_VATS" }, { showSuccessNotification: false, showErrorNotification: false });
  const [hiddenSelectCard, setHiddenSelectCard] = useState(false);
  const [cardLocal, setCardLocal] = useState(null);
  const [vat, setVat] = useState({ discountApplies: true });
  const { setCountryCode, setFreeVat } = usePayment()

  useEffect(() => {
    onChangeVat && onChangeVat(vat)
  }, [vat]);

  const afterChangeValidateZipCode = (value, values) => {
    const country = _.find(_.get(product, "countryVAT", []), (element) => {
      return element.country === value;
    });
    if (country) {
      setVat({
        discountApplies: country.discountApplies,
        value: country.value,
        valueWithDiscount: country.valueWithDiscount
      })
    } else {
      setVat(defaultVat)
    }
    const countryZip = _.find(_.get(zipCodes, "getSystemConfig", []), (element) => {
      return element === value;
    });
    if (countryZip) {
      return values.zipCode && values.zipCode !== "";
    }
    return true;
  };

  const handleChangeCountry = (value) => {
    if (handleOnBehalfOfAVATRegisteredCompany && handleOnBehalfOfAVATRegisteredCompany.current && !value) {
      handleOnBehalfOfAVATRegisteredCompany.current.setValue(false);
    }
    setCountryCode && setCountryCode(value);
    const country = _.find(_.get(product, "countryVAT", []), (element) => {
      return element.country === value;
    });
    if (country) {
      setVat({
        discountApplies: country.discountApplies,
        value: country.value,
        valueWithDiscount: country.valueWithDiscount
      });
    } else {
      setVat(defaultVat)
    }
    const countryZip = _.find(_.get(zipCodes, "getSystemConfig", []), (element) => {
      return element === value;
    });
    setIsZipCodeRequired(countryZip);
  };

  const handleChangeFreeVat = (value) => {
    onChangeFreeVat && onChangeFreeVat(value)
    setFreeVat && setFreeVat(value);
  };

  const handleHidden = (value) => {
    setHiddenSelectCard(value);
  };

  const handleSetDefault = (list) => {
    const values = getValues();
    const cardSelect = _.get(values, "cardSelect");
    if (cardSelect) {
      const cardSelectList = _.find(list, (element) => {
        return _.get(element, "id") == cardSelect;
      });
      setValue("cardSelect", cardSelectList.id);
      setLocalCard(cardSelectList);
      return;
    }
    if (useCard) {
      const cardInUse = _.find(list, (element) => {
        return _.get(element, "card.inUse");
      });
      if (cardInUse) {
        setValue("cardSelect", cardInUse.id);
        return;
      } else {
      }
    }
    setValue("cardSelect", -1)
  };

  const setLocalCard = (element) => {
    setCard && setCard(_.get(element, "card"));
    setCardLocal(_.get(element, "card"));
    setValue("address1", _.get(element, "card.billingAddress.addressLine1", ""));
    setValue("address2", _.get(element, "card.billingAddress.addressLine2", ""));
    setValue("holderName", _.get(element, "card.cardHolderName", ""));
    setValue("city", _.get(element, "card.billingAddress.city", ""));
    setValue("country", _.get(element, "card.billingAddress.country"));
    setValue("state", _.get(element, "card.billingAddress.state", ""));
    setValue("zipCode", _.get(element, "card.billingAddress.zipCode", ""));
  };

  const handleOnBehalfOfAVATRegisteredCompany = useRef();

  return (
    <React.Fragment>
      <div>
        {(!hiddenSelectCard && canSelectCard) ? (
          <RowAdd
            label={"PAYMENT_CARD_SELECT_LABEL"}
            asterisk={true}
            input={
              <ControllerInput
                as={CardSelector}
                handleHidden={handleHidden}
                name="cardSelect"
                add={true}
                placeholder={""}
                handleSetDefault={handleSetDefault}
                selectCard={selectCard}
                callbakcs={{
                  updateValues: (element) => {
                    setLocalCard(element);
                  }
                }}
              />
            } />
        ) : (
          <React.Fragment>
            <ControllerInput
              as={HiddenField}
              name="cardSelect"
              defValue={-1}
            />
          </React.Fragment>
        )}
        {(!useCard || !cardLocal || _.get(cardLocal, "id") == -1) && (
          <React.Fragment>
            <RowAdd
              label={"PAYMENT_CARD_DETAILS_LABEL"}
              align={"top"}
              asterisk={true}
              input={
                <ControllerInput
                  as={CardInput}
                  name="card"
                  placeholder={""}
                  error={errorCard ? errorCardText : null}
                  onChange={(e) => { }}
                />
              } />
            <RowAdd
              label={"PAYMENT_CARD_NAME_LABEL"}
              asterisk={true}
              input={
                <ControllerInput
                  as={TextInput}
                  name="holderName"
                  defaultValue={''}
                  placeholder={"PAYMENT_CARD_NAME_PLACEHOLDER"}
                  onChange={(e) => {
                  }}
                />
              }
            />
          </React.Fragment>
        )}
        {terms && <TermsComponent terms={terms} termsCustomComponent={termsComponent}  propsComponent={propsTermsComponent}/>}
      </div>
      <div>
        {showTitle && (
          <div className={classesModule.titleBilling}>
            <Title><Text uuid={"PAYMENT_CARD_TITLE_BILLING_ADDRESS"} /></Title>
          </div>
        )}
        <RowAdd
          label={"PAYMENT_CARD_COUNTRY_LABEL"}
          asterisk={true}
          input={
            <ControllerInput
              as={CountrySelector}
              defaultValue=""
              placeholder={"PAYMENT_CARD_COUNTRY_PLACEHOLDER"}
              name={"country"}
              clear={true}
              resetFields={[{ field: "zipCode", validate: afterChangeValidateZipCode }]}
              onChange={handleChangeCountry}
            />
          }
        />
        {enabledVat && (
          <Collapse in={vat.discountApplies}>
            <RowAdd
              label={""}
              className={classNames({
                [classesModule.rowAddCheckbox]: vat.discountApplies
              }, classesModule.RowAdd)}
              input={
                <ControllerInput
                  as={Checkbox}
                  className={{
                    label: classesModule.labelCheckBox
                  }}
                  name={'onBehalfOfAVATRegisteredCompany'}
                  label={"PAYMENT_SUCCESS_FREE_VAT"}
                  onChange={handleChangeFreeVat}
                  ref={handleOnBehalfOfAVATRegisteredCompany}
                />
              }
            />
          </Collapse>
        )}
        <RowAdd
          label={"PAYMENT_CARD_ADDRESS_LABEL"}
          asterisk={true}
          input={
            <ControllerInput
              as={TextInput}
              defaultValue=""
              placeholder={"PAYMENT_CARD_ADDRESS1_PLACEHOLDER"}
              name={"address1"}
              clear={true}
              onChange={(e) => {
              }}
            />
          }
        />
        <RowAdd
          input={
            <ControllerInput
              as={TextInput}
              defaultValue=""
              placeholder={"PAYMENT_CARD_ADDRESS2_PLACEHOLDER"}
              name={"address2"}
              clear={true}
              onChange={(e) => { }}
            />
          }
        />
        <RowAdd
          label={""}
          input={
            <ControllerInput
              as={TextInput}
              defaultValue=""
              placeholder={"PAYMENT_CARD_CITY_PLACEHOLDER"}
              name={"city"}
              clear={true}
              onChange={(e) => { }}
            />
          }
        />
        <RowAdd
          label={""}
          input={
            <div className={classesModule.ZipCodeRegion}>
              <div>
                <ControllerInput
                  as={TextInput}
                  defaultValue=""
                  placeholder={"PAYMENT_CARD_REGION_PLACEHOLDER"}
                  name={"state"}
                  clear={true}
                  onChange={(e) => { }}
                />
              </div>
              <div>
                <ControllerInput
                  as={TextInput}
                  defaultValue=""
                  placeholder={"PAYMENT_CARD_ZIPCODE_PLACEHOLDER"}
                  name={"zipCode"}
                  clear={true}
                  onChange={(e) => { }}
                />
              </div>
            </div>
          }
        />
      </div>
    </React.Fragment >
  )
}

export default PaymentFormContent;
