import React from "react";
import PropTypes from "prop-types";
import _, { initial } from "lodash";
import DetailsTabTitle from "src/components/templates/DetailsTabTitle";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import TwoColumnsViewer from "src/modules/generic/components/TwoColumsViewer";
import useModal from "src/modules/generic/hooks/useModal";
import { RoleAccess } from "src/v1/components";
import { Constants } from "src/v1/utils";
import EditFitAndProper from "src/modules/startup/Stakeholder/ADGM/EditFitAndProper";
import Button from "src/modules/generic/components/Buttons/Button";
function FitAndProperPanel({ data,lock }) {
  const { translate } = useTranslate();
  const { openModal, closeModal } = useModal();

  const button = <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
  <Button
    disabled={lock}
    variant='card'
    children={translate('VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT')}
    onClick={() => {
      openModal(EditFitAndProper, { handleCloseModal: closeModal, initialValues: { stakeholderId:data?.id } })
    }}
  />
</RoleAccess>;

  return (
    <>
      <DetailsTabTitle title="Fit and proper" buttons={button} />
      <TwoColumnsViewer
        itemsCol1={[
          {
            label: translate("STAKEHOLDER_VIEW_TAB_FIT_AND_PROPER_DECLARATION"),
            value: _.get(data, "fitAndProperStatement")
              ? 'Completed (Click Edit to view)':'',
          },
        ]}
      />
    </>
  );
}

FitAndProperPanel.propTypes = {
  data: PropTypes.any,
  lock: PropTypes.bool,
};

export default FitAndProperPanel;
