
import React, { useEffect, useState, FC } from 'react';
import Button from "../../../../generic/components/Buttons/Button";
import useTranslate from '../../../../generic/hooks/useTranslate';
import useSession from "../../../../session/hooks/useSession";
import { useParams } from 'react-router';
import useUpsertFormationCompany from "../../graphql/mutations/useUpsertFormationCompany";
import TemplateWizard from "../../../../generic/templates/Modal/TemplateWizard";
import FormSelectNatureOfBusiness from "../../Forms/FormBusinessActivity";
import { addAssistantText, clearAssistatText } from '../../../../generic/store/action';
import { useDispatch } from "react-redux";
import useModal from '../../../../generic/hooks/useModal/index';

import ModalAssistant, { ModalAssistantProps } from '../../../../generic/components/Modal/ModalAssistant/index';
type Mode = 'TASK' | 'EDIT'

interface ModalNatureOfBusinessProps extends ModalAssistantProps {
  paramsMutation: { mode: Mode, initialValues?: any },
  onClose(): void
}

const ModalNatureOfBusiness: FC<ModalNatureOfBusinessProps> = ({
  open: openParam = false,
  paramsMutation,
  ...props
}: ModalNatureOfBusinessProps) => {

  const [open, setOpen] = useState(openParam);
  const { closeModal: onClose } = useModal();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>()
  const { startupId } = useSession()
  const { translate } = useTranslate()
  const [mutation] = useUpsertFormationCompany({}, {})


  useEffect(() => {
    setOpen(openParam);
  }, [openParam]);

  const handleClose = (values) => {
    onClose();
    setOpen(false);
  }

  const handleCompleteSubmit = async (values) => {
    const variables = {
      groupCompanyId: paramsMutation?.initialValues?.groupCompanyId ?? id,
      startupId,
      groupCompanyData: {
        formationData: {
          ...values
        }
      }
    }
    await mutation(variables)
    handleClose(values)
  }

  const handleInitModal = () => {
    dispatch(clearAssistatText());
  }

  useEffect(() => {
    handleInitModal()
    dispatch(addAssistantText(translate('MODULES.GROUP_COMPANY.NATURE_OF_BUSINESS.FIRST_STEP.TOOLTIP')))
    return () => {
      dispatch(clearAssistatText());
    }
  }, []);

  return (
    <ModalAssistant open={open}>
      <FormSelectNatureOfBusiness
        initialValues={paramsMutation.initialValues}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: translate(`MODULES.GROUP_COMPANY.NATURE_OF_BUSINESS.FIRST_STEP.TITLE_${paramsMutation.mode}`),
          subTitle: translate('MODULES.GROUP_COMPANY.NATURE_OF_BUSINESS.FIRST_STEP.SUBTITLE')
        }}
        buttons={{
          cancel: (
            <Button onClick={handleClose} variant="secondary">{translate('BUTTON_CANCEL')}</Button>
          ),
        }}
      />
    </ModalAssistant>
  )
}

export default ModalNatureOfBusiness;