import useLazyQuery from 'src/hooks/custom/useLazyQuery';
import useQuery from 'src/hooks/custom/useQuery';

const GET_QUESTIONNAIRE = `
query getRenewalData($startupId: ID!, $groupCompanyId: ID!) {
  getRenewalData(startupId: $startupId, groupCompanyId: $groupCompanyId){
	techLicense {
    isEmployeeShareholder{
      description
      question
    }
    isThereEmployeeWithOtherVisas{
      description
      question
    }
    isThereFutureEmployees{
      description
      question
    }
    isCompanyOperational{
      description
      question
    }
  employeeAmountStart
  employeeAmountNow
  uaeVisasIssuedAmount
  companyRevenue
  futurePlans
  }
}
}`;

export const useGetQuestionnaire = (variables, config) => {
  const { loading, error, data, refetch } = useQuery(GET_QUESTIONNAIRE, variables, config);
  return { loading, error, data, refetch };
};

export default useGetQuestionnaire;