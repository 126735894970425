import Button from "src/modules/generic/components/Buttons/Button";
import { Constants } from 'src/v1/utils/constants';
import DetailsTabTitle from "src/components/templates/DetailsTabTitle";
import Link from "src/components/text/Link/Link.js";
import { EditProfile as ModalEditProfile } from 'src/components/modules/clara/startups/groupCompany/Modals';
import PropTypes from "prop-types"
import React from 'react';
import { RoleAccess } from "src/v1/components";
import TwoColumnsViewer from "src/modules/generic/components/TwoColumsViewer";
import _ from "lodash";
import useModal from "src/modules/generic/hooks/useModal";
import useTranslate from "src/modules/generic/hooks/useTranslate";

function GeneralDetailsPanel({ data, isLock, isFormation }) {
  const { translate } = useTranslate();
  const { openModal, closeModal } = useModal();

  const generalDetailsButtons = <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE} noAccess={undefined} actions={undefined} params={undefined}>
    <Button
      variant='card'
      disabled={isLock}
      children={translate(
        'VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT'
      )}
      onClick={() => {
        openModal(ModalEditProfile, { handleCloseModal: closeModal })
      }}
    />
  </RoleAccess>;

  return (
    <>
      <DetailsTabTitle title="General details" buttons={generalDetailsButtons} />
      <TwoColumnsViewer
        itemsCol1={[
          {
            label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_COUNTRY"),
            value: _.get(data, "country.name"),
          },
          {
            label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_JURISDICTION"),
            value: _.get(data, "jurisdiction"),
          },
          {
            label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_COMPANY_TYPE"),
            value: _.get(data, "type") ? _.startCase(_.toLower(_.get(data, "type"))) : "-",
          },
          {
            label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_COMPANY_NUMBER"),
            value: _.get(data, "registeredNumber"),
          },
        ]}
        itemsCol2={[
          {
            label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_INCORPORATION_DATE"),
            value: _.get(data, "incorporation_date"),
          },
          {
            label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_CURRENCY"),
            value: isFormation ? 'USD' : _.get(data, "transactionCurrency"),
          },
          {
            label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_LINKEDIN"),
            value: _.get(data, 'linkedinURL') ? <Link target={"_blank"} href={_.get(data, 'linkedinURL')}>{_.get(data, 'linkedinURL')} </Link> : null,
          },
        ]}
      />
    </>
  );
}

GeneralDetailsPanel.propTypes = {
  data: PropTypes.any,
  isLock: PropTypes.bool,
  isFormation: PropTypes.bool,
  isEdit: PropTypes.bool,
}

export default GeneralDetailsPanel;