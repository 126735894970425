import ReviewCompanyAddressStep from '../steps/ReviewCompanyAddressStep';
import ReviewCompanyDetailsStep from '../steps/ReviewCompanyDetailsStep';
import ReviewCompanyTaxInformation from '../steps/ReviewCompanyTaxInformation';
import ReviewRelevantActivitiesStep from '../steps/ReviewRelevantActivitiesStep';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import { useMemo } from 'react';

const useSteps = () => {
  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    pincasData: {
      render: TemplateWizardSkeletonMachine,
    },
    getRelevantActivities: {
      render: TemplateWizardSkeletonMachine,
    },
    ReviewCompanyDetailsStep: {
      render: ReviewCompanyDetailsStep,
    },
    ReviewCompanyAddressStep: {
      render: ReviewCompanyAddressStep,
    },
    ReviewRelevantActivitiesStep: {
      render: ReviewRelevantActivitiesStep,
    },
    ReviewCompanyTaxInformation: {
      render: ReviewCompanyTaxInformation,
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },
  }), []);
}

export default useSteps;
