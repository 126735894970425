import { useMemo } from 'react';
import _ from 'lodash';

const useGuards = (initialValues) => {
  return {
    needMore: (context) => {
      return _.get(context, 'moreToAdd.another');
    },
    noNeedMore: (context) => {
      return _.get(context, 'moreToAdd.another') === false;
    },
    isRenewal: (context) => {
      return initialValues?.isRenewal;
    },
    isNotRenewal: (context) => {
      return !initialValues?.isRenewal;
    }
  }
}

export default useGuards;