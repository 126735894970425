import PropTypes from "prop-types";
import React from 'react';
import _ from "lodash";
import TwoColumnsViewer from "src/modules/generic/components/TwoColumsViewer";
import useTranslate from "src/modules/generic/hooks/useTranslate";

function NatureOfBusinessTab({ data }) {

  const { translate } = useTranslate();

  return (
    <TwoColumnsViewer
      itemsCol1={[
        {
          label: translate("VIEW_COMPANY_TAB_LABEL_NATURE_OF_BUSINESS") + ":",
          value: _.get(data,'businessActivity.activity'),
        },
        {
          label: translate("VIEW_COMPANY_TAB_LABEL_OPERATION_COMPANY") + ":",
          value: _.get(data,'businessActivity.operationCompany'),
        },
      ]}
    ></TwoColumnsViewer>
  );
}

NatureOfBusinessTab.propTypes = {
  data: PropTypes.any,
}

export default NatureOfBusinessTab;