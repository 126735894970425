import _ from "lodash"
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from "react-router-dom"
import useIndividualStakeholderData from "src/hooks/services/modules/startups/individual/useIndividualStakeholderData"
import useSession from 'src/modules/session/hooks/useSession'
import ModalAssistant, { ModalAssistantProps } from '../../../generic/components/Modal/ModalAssistant'
import Wizard from '../../../generic/components/Wizard'
import useModal from '../../../generic/hooks/useModal'
import MachineCompanyDetail from './machine'
interface ModalProfileStakeholderProps extends ModalAssistantProps {
  paramsMutation: object,
  onClose(): void;
  id: string;
}

const ModalProfileStakeholder: FC<ModalProfileStakeholderProps> = ({ children, open: openParam = false, paramsMutation = {}, id, ...props }: ModalProfileStakeholderProps) => {

  const [open, setOpen] = useState(openParam);
  const { closeModal: onClose } = useModal()
  const { startupId } = useSession();
  const params = useParams();
  
  const { data: userData } = useIndividualStakeholderData({
    startupId,
    stakeholderId: id || _.get(params, "id"),
  });

  useEffect(() => {
    setOpen(openParam);
  }, [openParam])

  const handleClose = (values) => {
    onClose()
    setOpen(false);
  }
  const initialValues = useMemo(() => {
    if (_.get(userData, "getStakeholder")) {
      return {
        id: _.get(userData, "getStakeholder.id"),
        personal_information: {
          nationality: _.get(userData, "getStakeholder.nationality", undefined) === null ? undefined : _.get(userData, "getStakeholder.nationality"),
          address: {
            country: _.get(userData, "getStakeholder.address.country.code", undefined) === "" ? undefined : _.get(userData, "getStakeholder.address.country"),
            street: _.get(userData, "getStakeholder.address.street"),
            city: _.get(userData, "getStakeholder.address.city"),
            state: _.get(userData, "getStakeholder.address.state"),
            zipCode: _.get(userData, "getStakeholder.address.zipCode"),
          }
        },
        more_details: {
          email: _.get(userData, "getStakeholder.email"),
          phoneNumber: _.get(userData, "getStakeholder.phoneNumber"),
          dateOfBirth: _.get(userData, "getStakeholder.dateOfBirth"),
          startDate: _.get(userData, "getStakeholder.startDate"),
          passportNumber: _.get(userData, "getStakeholder.passportNumber"),
          profession: _.get(userData, "getStakeholder.profession")
        }
      }
    }
    return null;
  }, [userData])

  return (
    <ModalAssistant open={open}>
      {initialValues && <Wizard machine={MachineCompanyDetail} initialValues={initialValues} onComplete={handleClose} />}
    </ModalAssistant>
  )
}

export default ModalProfileStakeholder;




