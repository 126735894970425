import React from 'react';
import classesModule from './classes.module.scss';
import _ from "lodash";

export interface TemplateFormButtonsProps  {
  submit?: React.ReactNode,
}
export interface TemplateFormComponentProps<PropsType = any, ButtonsType = any> {
  children: React.ReactNode,
  className?: string,
  classNameBotonera?: string,
  buttons: ButtonsType,
  props?: PropsType,
  loading?: boolean,
  loadingComponent?: React.ReactNode,
}

export type TemplateComponent<PropsType = any, ButtonsType=any> = React.FC<TemplateFormComponentProps<PropsType, ButtonsType>>

interface TemplateFormDefaultProps {
  className?: string
}
const Template: TemplateComponent<TemplateFormDefaultProps> = ({
  children,
  buttons = {},
  classNameBotonera,
  className,
}: TemplateFormComponentProps<TemplateFormDefaultProps,TemplateFormButtonsProps>)  => {

  return (
    <React.Fragment>
      <div className={`${classesModule.Template} ${className}`}>
        <div className={`${classesModule.Form}`}>
          {children}
        </div>
        <div className={`${classesModule.Botonera} ${classNameBotonera}`}>
          {_.map(buttons, button => {
            return <React.Fragment>{button}</React.Fragment>
          })}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Template
