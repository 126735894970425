import { useMemo } from "react";
import { AddPassport, ConfigurePassport, MoreToAdd } from "../Steps";
import TemplateWizardSkeletonMachine from "../../../../generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";

const useSteps = () => {
  return {
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    uploadDocument: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: "",
        subTitle: ""
      }
    },
    ConfirmNationalitiesDone: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: "",
        subTitle: ""
      }
    },
    addPassport: {
      render: AddPassport,
    },
    configurePassport: {
      render: ConfigurePassport,
    },
    moreToAdd: {
      render: MoreToAdd,
    },
  };
}

export default useSteps;