import useGetStakeholderDetails from "./graphql/useGetStakeholderDetails";
import useUpsertRenewalData, { Tasks } from "./graphql/useUpsertRenewalData";
import useMapperStakeholderDetails from "./useMapperStakeholderDetails";

const useServices = ({ initialValues }) => {
  const updateStakeholderTasks = useUpsertRenewalData();
  const { query } = useGetStakeholderDetails({});
  const { getInitialData, getSubmitData } = useMapperStakeholderDetails();

  return {

    initialData: async () => {
      const data = await query(initialValues.stakeholderId);
      return getInitialData(data?.stakeholder);
    },

    SaveStep: async (context) => {
      const { ReviewPersonalDetailNationalityStepForm } = context;
      const tasks = ["isStakeholderDetailsConfirmed" as Tasks];
      const data = getSubmitData(context);
      if (ReviewPersonalDetailNationalityStepForm.accept)
        tasks.push("areNationalitiesConfirmed" as Tasks);
      return await updateStakeholderTasks(
        initialValues.groupCompanyId,
        tasks,
        initialValues.stakeholderId,
        data
      );
    },
  };
};

export default useServices;
