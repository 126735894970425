import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import SelectGroupCompaniesForm from '../../../core/forms/SelectCompaniesForRoles';
import React, { useEffect, useMemo } from 'react';
import Button from "../../../../../generic/components/Buttons/Button";
import useTranslate from '../../../../../generic/hooks/useTranslate';
import { useDispatch } from "react-redux";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import { addAssistantText, clearAssistatText } from "../../../../../generic/store/action";
import useModal from "../../../../../generic/hooks/useModal";
import { Constants } from 'src/v1/utils'
import useGetPriceByRole from '../../hooks/useGetPriceByRole'

/**
 * Step para editar los datos de SOF OTHER
 * @param {*} param0
 * @returns
 */
function SelectGroupCompanies({ openParam }) {

  const { next, context, prev } = useMachine();
  const role = context.roles[context.iterations || 0]
  const { translate } = useTranslate()
  const dispatch = useDispatch();
  const { closeModal: onClose } = useModal();
  const getPriceByRole = useGetPriceByRole(context.getStakeholdersGroupCompanies.requestItems)

  const handleCancel = () => {
    onClose();
    dispatch(clearAssistatText());
  }

  const handlePrevious = () => {
    prev();
  }

  const handleInitModal = () => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate('MODULES.EQUITY.ADD_EQUITY.STEP_ADD_CONVERTIBLE.TOOLTIP')));
  }

  useEffect(() => {
    handleInitModal();
  }, []);

  const handleCompleteSubmit = async (values, ref) => {
    next(values);
    ref?.current?.reset();
    ref.current?.setValue('groupCompanies', []);
  }

  const getGroupCompanies = useMemo(() => {
    const isEntity = context?.isEntity;
    return context.getStakeholdersGroupCompanies.groupCompanies.map(gc => {
      if (role !== Constants.ROLES_FROM_BACKEND.SHAREHOLDER.id && role !== Constants.ROLES_FROM_BACKEND.DIRECTOR.id && role !== Constants.ROLES_FROM_BACKEND.THIRDPARTY.id) {
        return { value: gc, label: gc.name, disabled: gc.kind === 'FORMATION' || gc.isLocked };
      }
      return { value: gc, label: gc.name, disabled: (gc?.jurisdictionType === 'DELAWARE' && isEntity) || gc.isLocked };
    });
  }, [role]);

  const formatTitle = (role) => {
    let result = "";
    switch (role) {
      case ("Advisor"):
        result = `Select all the companies in which they are an ${role.toLowerCase()}`;
        break;
      case ("Investor"):
        result = `Select all the companies in which they are an ${role.toLowerCase()}`;
        break;
      case ("Employee"):
        result = `Select all the companies in which they are an ${role.toLowerCase()}`;
        break;
      case ("Third Party"):
        result = `Select all the companies in which they are a ${role.toLowerCase()}`;
        break;
      default:
        result = `Select all the companies in which they are a ${role.toLowerCase()}`;
        break;
    }
    return result;
  }

  return (
    <>
      <SelectGroupCompaniesForm
        companies={getGroupCompanies}
        role={role}
        priceList={getPriceByRole([role], 'CAYMAN')}
        buttons={{
          previous: (
            <Button onClick={handlePrevious}>Previous</Button>
          ),
          cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
        }}
        onCompleteSubmit={handleCompleteSubmit}
        propsTemplate={{
          title: formatTitle(Constants.ROLES_FROM_BACKEND[role].label),
          subTitle: context.stakeholder.fullName
        }}
        Template={TemplateWizard}
      />
    </>
  );
}

export default SelectGroupCompanies;