import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import ErrorInput from "../../../modules/generic/components/Inputs/ErrorInput";
import classes from './RadioInput.module.scss';

const defaultOptions = [
  {
    id: false,
    label: "No",
  },
  {
    id: true,
    label: "Yes",
  }
];

const RadioInput = ({ 
  name, 
  options = defaultOptions, 
  type = "radio", 
  classNameOption, 
  className, 
  orientation = "horizontal", 
  fieldId = "id", 
  fieldLabel = "label", 
  onChange, 
  errorMessage, 
  error, 
  value, 
  disabled = false,
  ...props 
}) => {

  const [stateValue, setStateValue] = useState(value);
  const handleSelect = (option) => {
    setStateValue(option[fieldId])
    onChange(option[fieldId]);
  };

  useEffect(() => {
    if (stateValue !== value) {
      setStateValue(value)
    }
  }, [value])

  const getOrientationClass = () => {
    if (orientation === "horizontal") {
      return classes.Horizontal
    }
    return classes.Vertical
  }

  return (
    <div className={`${classes.RadioInput}`} >
      <div className={`${classes.Options} ${getOrientationClass()} ${className}`} >
        {options.map((option, i) => (
          <div key={i} className={`${classes.Option}${classNameOption}`} >
            <button
              disabled={disabled}
              type={"button"}
              className={classnames(option.classes, {
                [classes.selected]: stateValue === option[fieldId],
                [option.classeSelected]: stateValue === option[fieldId]
              })}
              onClick={(e) => handleSelect(option)}
            >
            </button>
            <label>
              {option.label}
            </label>
          </div>
        ))}
      </div>
      <ErrorInput message={errorMessage} component={error} />
    </div >
  )
}


export default RadioInput