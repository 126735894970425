import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TextInput from 'src/components/inputs/Text/TextInput';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import CurrencySelector from "src/components/inputs/Select/CurrencySelector/CurrencySelector";
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import CountryJurisdiction from "src/modules/generic/components/CountryJurisdictionSelector";
import classesModule from "./classes.module.scss";
import _ from 'lodash';

function AddIncorporateCompany() {

  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.initialData ?? {}
  }, [context]);

  const disablePrevious = useMemo(() => {
    return initialValues?.noPrevious?? false;
  }, [initialValues]);

  const handleCancel = (values) => {
    cancel();
  }

  const handlePrev = (values) => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values)
  }

  const handleTooltips = (value) => {
    dispatch(clearAssistatText());
    dispatch(
      addAssistantText(
        translate('MODULES.ONBOARDINGCOMPANY.FORM_INC_CREATION_COMPANY_TOOLTIP1')
      )
    );
    dispatch(
      addAssistantText(
        translate('MODULES.ONBOARDINGCOMPANY.FORM_INC_CREATION_COMPANY_TOOLTIP2')
      )
    );
    if (value === 'OTHER') {
      dispatch(
        addAssistantText(
          translate('MODULES.ONBOARDINGCOMPANY.FORM_INC_CREATION_COMPANY_TOOLTIP3')
        )
      );
    }
  }

  useEffect(() => {
    handleTooltips('');
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch])

  const schema = Yup.object().shape({
    isHoldingCompany: Yup.boolean().required("Required"),
    name: Yup.string().nullable().required("Add the company name"),
    country: Yup.string().nullable().required("Required"),
    jurisdiction: Yup.string().nullable().required("Required"),
    otherJurisdiction: Yup.string().when(["jurisdiction"], {
      is: "OTHER",
      then: Yup.string().nullable().required("Add the jurisdiction name"),
      otherwise: Yup.string().nullable().optional(),
    }),
    transactionCurrency: Yup.string().nullable().required("Required"),
  });

  const labelLines = [
    translate("MODULES.ONBOARDINGCOMPANY.LABEL_TOP_COMPANY_LINE3"),
  ];

  const options = [
    {
      value: false,
      label: translate(`MODULES.ONBOARDINGCOMPANY.LABEL_ANOTHER_NO`),
    },
    {
      value: true,
      label: translate(`MODULES.ONBOARDINGCOMPANY.LABEL_ANOTHER_YES`),
    },
  ];

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: "Company details",
          subTitle: "Add a company",
          skeleton: null,
          variant: 'simple'
        }}
        buttons={{
          previous: !disablePrevious && <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('GENERIC_BUTTON_ADD_GROUP')}</SubmitButton>,
        }}
      >
        <TemplateLabel
          isRequired={true}
          variant={"top"}
          multilineTexts={labelLines}
          label={''}>
          <ControllerInput
            render={CheckboxButtonSimple}
            className={classesModule.CheckboxButton}
            classNameButton={classesModule.buttonCheckBox2}
            name="isHoldingCompany"
            options={options}
            onChange={(value) => { }}
          />
        </TemplateLabel>
        <TemplateLabel
          label={translate("MODULES.ONBOARDINGCOMPANY.LABEL_NAME")}
          isRequired={true}
        >
          <ControllerInput
            render={TextInput}
            name="name"
            placeholder={"As shown on the Certificate of Incorporation"}
            defaultlabel={""}
          />
        </TemplateLabel>
        <CountryJurisdiction
          disabled={false}
          hideLoading={true}
          onChange={(value) => { handleTooltips(value) }}
        />
        <TemplateLabel
          label={translate('FORM_ADD_COMPANY_LABEL_COMPANY_CURRENCY')}
          isRequired
        >
          <ControllerInput
            as={CurrencySelector}
            name="transactionCurrency"
            defaultValue={""}
            placeholder={translate('MODULES.CLARA.SELECT_CURRENCY')}
            onChange={(e) => {}}
          />
        </TemplateLabel>
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}

export default AddIncorporateCompany;