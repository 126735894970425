import useSession from "src/modules/session/hooks/useSession";
import useGetGroupCompanyEconomicSubstance from "./graphql/useGetGroupCompany";
import useGetRelevantActivities from "./graphql/useGetRelevantActivities";
import useUpdateEconomicSubstanceInformation from "./graphql/useUpdateEconomicSubstanceInformation";
import useGetMapEconomicSubstance from './useGetMapEconomicSubstance';
import omit from 'lodash/omit';
import useGetPINCAPrices from 'src/views/modules/startups/renewal/pincasPrice/queries/useGetPINCAPrices';

const useServices = ({ initialValues }) => {
  const { groupCompanyId } = initialValues;
  const { startupId } = useSession();
  const { manualQuery: getGroupCompany } = useGetGroupCompanyEconomicSubstance({ groupCompanyId, startupId });
  const { manualQuery: getRelevantActivitiesQuery } = useGetRelevantActivities({},{});
  const mutation = useUpdateEconomicSubstanceInformation();
  const economicSubstanceMapper = useGetMapEconomicSubstance();
  const { manualQuery: getPINCAPrices } = useGetPINCAPrices(
    {
      variables: {
        filters: {
          jurisdictionTypes: ['CAYMAN']
        }
      }
    }
  );

  const initialData = async () => {
    const { data: groupCompanyData } = await getGroupCompany();
    return { initialValues, groupCompany: groupCompanyData?.getGroupCompany };
  }

  const pincasData = async () => {
    const pincasData = await getPINCAPrices();
    return { pincasData };
  }  

  const getRelevantActivities = async () => {
    const { data: relevantActivitiesData } = await getRelevantActivitiesQuery();
    return { relevantActivitiesData };
  }

  const SaveStep = async (context) => {    
    const data = economicSubstanceMapper(context)
    const groupCompanyId = context?.initialData?.groupCompany?.id
    const variables ={
      groupCompanyId,
      startupId,
      groupCompanyUpsert:groupCompanyId,
      upsertStartupId:startupId,
      renewalData:data,
      groupCompany:{...omit(data.companyDetails,'authorizedSignatory'),endOfFinancialYear:data.companyDetails.nextEndOfFinancialYear,taxInformation:data.taxInformation,businessActivities:data.businessActivities}
    };
    return await mutation({variables});
  }

  return { initialData, SaveStep, getRelevantActivities, pincasData };
}

export default useServices;
