import React, { useState, useEffect } from "react";
import _, { isEmpty } from "lodash";
import SelectInput from "./../SelectInput/SelectInput";
import classes from "./CountrySelector.module.scss";
import useCountryList from "src/hooks/services/generic/useCountryList";
import CountryTemplate from "src/components/templates/Country/Country";

const CountrySelector = ({
  children,
  filterCountries,
  removeCountries,
  defaultList = [],
  expandDouble = false,
  ...props
}) => {
  const { data } = useCountryList({
    code: "",
    name: "",
    language: "en",
    orderBy: "FORMATION",
  });

  const [countries, setCountries] = useState(defaultList);

  useEffect(() => {
    if (!defaultList.length) {
      const newCountries = _.get(data, "countryList", []);
      let passCountries = [...newCountries];
      if (!!filterCountries) {
        passCountries = _.filter(passCountries, (country) =>
          filterCountries.includes(country.alpha3)
        );
      }
      if (!!removeCountries) {
        passCountries = _.filter(
          passCountries,
          (country) => !removeCountries.includes(country.name)
        );
      }
      passCountries.sort((a, b) => a.name.localeCompare(b.name));
      setCountries(
        passCountries.map((country) => {
          return {
            id: country.code,
            label: country.name,
            alpha3: country.alpha3,
          };
        })
      );
    }
  }, [JSON.stringify(data)]);

  useEffect(() => {
    if (typeof props.value === "object" && props.value !== null) {
      setTimeout(() => props.onChange(_.get(props, "value.code")), 220);
    }
  }, []);

  return (
    <div className={classes.ClaraSelectContainer}>
      {!isEmpty(countries) && (
        <SelectInput
          {...props}
          list={countries}
          expandDouble={expandDouble}
          autoClean={false}
          component={<CountryTemplate />}
        >
          {children}
        </SelectInput>
      )}
    </div>
  );
};

export default CountrySelector;
