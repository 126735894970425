import PropTypes from "prop-types"
import React from 'react';
import _ from "lodash";
import Divider from '@mui/material/Divider';
import DetailsTabTitle from "src/components/templates/DetailsTabTitle";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import TwoColumnsViewer from "src/modules/generic/components/TwoColumsViewer";
import {RoleAccess} from "src/v1/components";
import {Constants} from "src/v1/utils";
import Button from "src/modules/generic/components/Buttons/Button";
import EditCompanyAddressesModal from "src/components/modules/clara/startups/groupCompany/Modals/EditAddresses/EditCompanyAddresses";
import useModal from "src/modules/generic/hooks/useModal";
import classes from '../styles.module.scss';

function AddressesPanel({ data, managedByClara, isLock = false }) {
  const { translate } = useTranslate();
    const { openModal, closeModal } = useModal();

    const generalDetailsButtons = !managedByClara && <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
        <Button
            variant='card'
            children={translate('VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT')}
            disabled={isLock}
            onClick={() => {
                openModal(EditCompanyAddressesModal, { handleCloseModal: closeModal, data })
            }}
        />
    </RoleAccess>;

  return (
    <>
      <Divider className={classes.divider} />
      <DetailsTabTitle title="Addresses" buttons={generalDetailsButtons} />
      <TwoColumnsViewer
        itemsCol1={[
          {
            label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_REGISTERED_ADDRESS"),
            value: <>
              <div>{_.get(data, "registeredAddress.street")}</div>
              <div>{_.get(data, "registeredAddress.city")}</div>
              <div>{_.get(data, "registeredAddress.state")}</div>
              <div>{_.get(data, "registeredAddress.zipCode")}</div>
              <div>{_.get(data, "registeredAddress.country.name")}</div>
            </>
          },
        ]}
      />
    </>
  );
}

AddressesPanel.propTypes = {
  data: PropTypes.any,
  managedByClara: PropTypes.bool,
}

export default AddressesPanel;