import _ from "lodash";

const useGuards = () => {
  return {
    renewed: (context) => {
      return _.get(context, "HasBeenRenewedStep.renewed");
    },
    notRenewed: (context) => {
      return _.get(context, "HasBeenRenewedStep.renewed") === false;
    },
  };
};

export default useGuards;
