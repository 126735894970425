import React, { useEffect, useState } from 'react';


const SVGLLine = ({ fromX, fromY, toX, toY, curve = 12, displacementX = 0, id,dotter, className }) => {
    const [d, setD] = useState()


    const getPath = (fromX, fromY, toX, toY, curve, displacementX) => {
        const c = {
            c1: fromX + curve,
            c2: fromY,
            c3: toX - curve,
            c4: toY
        }
        return `M ${fromX} ${fromY} L ${fromX + displacementX} ${fromY} ${fromX + displacementX} ${toY - curve} Q ${fromX + displacementX} ${toY}  ${fromX + displacementX + curve} ${toY} L ${toX} ${toY}`;


    }

    useEffect(() => {
        if (fromX && fromY && toX && toY) {
            const newPath = getPath(fromX, fromY, toX, toY, curve, displacementX);
            setD(newPath);
        }
    }, [fromX, fromY, toX, toY, curve, displacementX])
    return (
        <React.Fragment>
            {d && (
                <path id={id} d={d} stroke-dasharray={dotter?"10,10":null} className={className}></path>
            )}
        </React.Fragment>
    )
};

export default SVGLLine;
