import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import _ from "lodash";
import { Constants } from "src/v1/utils";
import Form from "src/components/forms/Form/Form";
import TextInput from "src/components/inputs/Text/TextInput";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import Button from "src/modules/generic/components/Buttons/Button";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  addAssistantText,
  clearAssistatText,
} from "src/modules/generic/store/action";
import PhoneInput from "src/modules/generic/components/Inputs/PhoneInput";
import AutocompleteClara from "src/modules/generic/components/Inputs/Autocomplete";

function CorporateSignatoryForm() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.initialData ?? {};
  }, [context]);

  const { formValues, stakeholderName, stakeholders } = initialValues;

  const stakeholdersList = useMemo(() => {
    return stakeholders.map((st) => ({ value: st, label: st.fullName }));
  }, [JSON.stringify(stakeholders)]);

  const isEdit = !_.isEmpty(formValues);

  const handleCancel = () => {
    cancel();
  };

  const handleSubmit = async (values) => {
    next(values);
  };

  const createStakeholder = (value: string) => {
    return { fullName: value };
  };

  useEffect(() => {
    dispatch(
      addAssistantText(
        translate(
          "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.CORPORATE_SIGNATORY.TOOLTIP"
        )
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    stakeholder: Yup.object()
      .nullable()
      .required(
        translate("MODULES.ONBOARDINGROLES.DIRECTOR_V2.FIELD_REQUIRED")
      ),
    email: Yup.string()
      .lowercase()
      .matches(Constants.REGEXP.EMAIL, {
        message: translate(
          "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.CORPORATE_SIGNATORY.EMAIL_VALIDATION"
        ),
        excludeEmptyString: true,
      })
      .nullable()
      .required(
        translate(
          "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.CORPORATE_SIGNATORY.REQUIRED"
        )
      ),
    phoneNumber: Yup.string()
      .required(
        translate(
          "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.CORPORATE_SIGNATORY.REQUIRED"
        )
      )
      .typeError(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")),
    signingAuthority: Yup.string().required(
      translate(
        "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.CORPORATE_SIGNATORY.REQUIRED"
      )
    ),
    position: Yup.string().required(
      translate(
        "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.CORPORATE_SIGNATORY.REQUIRED"
      )
    ),
  });

  const selectedStakeholderId = formValues?.stakeholder;

  const selectedStakeholder = _.find(
    stakeholdersList,
    (stakeholder) => stakeholder.value.id === selectedStakeholderId
  );

  const myDefaultValues = {
    ...formValues,
    stakeholder: selectedStakeholder?.value || {
      fullName: formValues?.fullName,
    },
  };

  return (
    <Form
      defaultValues={myDefaultValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: translate(
            "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.CORPORATE_SIGNATORY.TITLE",
            { stakeholderName }
          ),
          skeleton: null,
          subTitle: translate(
            "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.CORPORATE_SIGNATORY.SUBTITLE"
          ),
          variant: "simple",
        }}
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          submit: (
            <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>
              {isEdit
                ? translate("BUTTON_SAVE_CHANGES")
                : translate("GENERIC_BUTTON_ADD_GROUP")}
            </SubmitButton>
          ),
        }}
      >
        <>
          <TemplateLabel
            isRequired
            label={translate(
              "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.CORPORATE_SIGNATORY.FULL_NAME_LABEL"
            )}
          >
            <ControllerInput
              render={AutocompleteClara}
              getCreateValue={createStakeholder}
              name="stakeholder"
              options={stakeholdersList}
              placeholder={translate(
                "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.CORPORATE_SIGNATORY.FULL_NAME_PLACEHOLDER"
              )}
            />
          </TemplateLabel>
          <TemplateLabel
            isRequired
            label={translate(
              "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.CORPORATE_SIGNATORY.EMAIL_LABEL"
            )}
          >
            <ControllerInput
              render={TextInput}
              name="email"
              placeholder={translate(
                "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.CORPORATE_SIGNATORY.EMAIL_PLACEHOLDER"
              )}
            />
          </TemplateLabel>
          <TemplateLabel
            isRequired
            label={translate(
              "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.CORPORATE_SIGNATORY.TELEPHONE_NUMBER_LABEL"
            )}
          >
            <ControllerInput
              render={PhoneInput}
              disableDropdown={false}
              name="phoneNumber"
              placeholder={translate(
                "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.CORPORATE_SIGNATORY.TELEPHONE_NUMBER_PLACEHOLDER"
              )}
            />
          </TemplateLabel>
          <TemplateLabel
            isRequired
            label={translate(
              "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.CORPORATE_SIGNATORY.SIGNING_AUTHORITY_LABEL"
            )}
          >
            <ControllerInput
              render={TextInput}
              name="signingAuthority"
              placeholder={translate(
                "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.CORPORATE_SIGNATORY.SIGNING_AUTHORITY_PLACEHOLDER"
              )}
            />
          </TemplateLabel>
          <TemplateLabel
            isRequired
            label={translate(
              "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.CORPORATE_SIGNATORY.ROLE_LABEL"
            )}
          >
            <ControllerInput
              render={TextInput}
              name="position"
              placeholder={translate(
                "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.CORPORATE_SIGNATORY.ROLE_PLACEHOLDER"
              )}
            />
          </TemplateLabel>
        </>
      </TemplateWizard>
    </Form>
  );
}

export default CorporateSignatoryForm;
