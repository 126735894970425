import React from 'react'
import * as Yup from 'yup'
import Form from '../../../../components/forms/Form/Form'
import useTranslate from '../../../generic/hooks/useTranslate'
import TextInput from '../../../../components/inputs/Text/TextInput'
import ControllerInput from '../../../generic/components/Inputs/ReactHookForm/ControllerInput'
import TemplateLabel from "../../../generic/components/Inputs/LabelInput"
import SubmitButton from '../../../generic/components/Buttons/SubmitButton'
import FormProps from '../../../generic/forms/interfaces/FormProps';
import TemplateFormDefault from "../../../generic/templates/Modal";
import CountrySelector from '../../../../components/inputs/Select/CountrySelector/CountrySelector';
import cls from './Form.module.scss';

const schema = Yup.object().shape({
  nationality: Yup.string().required('This field is required').nullable(),
  address: Yup.object().shape({
    country: Yup.string().required('This field is required').nullable(),
    street: Yup.string().required('This field is required').nullable(),
    city: Yup.string().required('This field is required').nullable(),
  }),
})

const FormProfileStakeholder: React.FC<FormProps> = ({
  initialValues: defaultValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate
}) => {

  const { translate } = useTranslate()

  const handleSubmit = async values => {
    onCompleteSubmit && await onCompleteSubmit(values);
  }

  return (
    <Form
      defaultValues={defaultValues}
      schema={schema}
      onSubmit={handleSubmit}
      onlySendDirty={false}
      optionsForm={{ mode: "onChange" }}
    >
      <Template buttons={{ ...buttons, submit: <SubmitButton  {...buttonSubmitProps}>Next</SubmitButton> }} props={propsTemplate}>
        <TemplateLabel
          label={'Nationality'}
          className={cls.TemplateLabel}
          isRequired
        >
          <ControllerInput
            render={CountrySelector}
            name='nationality'
            placeholder='Select country'
          />
        </TemplateLabel>
        <TemplateLabel
          label={'Residential address'}
          className={cls.TemplateLabel}
          isRequired
        >
          <ControllerInput
            render={CountrySelector}
            name='address.country'
            placeholder='Country'
          />
        </TemplateLabel>
        <TemplateLabel
          label={'Street'}
          isRequired
          className={cls.TemplateLabel}
        >
          <ControllerInput
            render={TextInput}
            name='address.street'
            defaultlabel={''}
            placeholder='Street'
          />
        </TemplateLabel>
        <TemplateLabel
          label={'City'}
          isRequired
          className={cls.TemplateLabel}
        >
          <ControllerInput
            render={TextInput}
            name='address.city'
            defaultlabel={''}
            placeholder='City'
          />
        </TemplateLabel>
        <TemplateLabel
          label={'State / Region'}
          className={cls.TemplateLabel}
        >
          <ControllerInput
            render={TextInput}
            name='address.state'
            defaultlabel={''}
            placeholder='State / Region'
          />
        </TemplateLabel>
        <TemplateLabel
          label={'Zip Code / Post Code'}
          className={cls.TemplateLabel}
        >
          <ControllerInput
            render={TextInput}
            name='address.zipCode'
            defaultlabel={''}
            placeholder='Zip Code / Post Code'
          />
        </TemplateLabel>
        <div>&nbsp;</div>
      </Template>
    </Form>
  )

}

export default FormProfileStakeholder
