import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from 'react';
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import DeleteGrantModal from "src/components/modules/clara/startups/Document/Modals/Delete/DeleteGrantModal";
import DeleteSIPContent from "src/components/modules/clara/startups/Document/Modals/Delete/DeleteSIPContent";
import DeleteUploadDocumentModal from "src/components/modules/clara/startups/Document/Modals/Delete/DeleteUploadDocumentModal";
import CancelGetSignaturesModal from "src/components/modules/clara/startups/Document/Modals/EditDocumentGenerate/CancelGetSignaturesModal";
import EditDocumentGenerate from "src/components/modules/clara/startups/Document/Modals/EditDocumentGenerate/EditDocumentGenerate";
import GetSignatures from "src/components/modules/clara/startups/Document/Modals/EditDocumentGenerate/GetSignatures";
import TerminateGrant from "src/components/modules/clara/startups/Document/Modals/Terminate/TerminateGrant";
import ConnectedGrantsModalContent from "src/components/modules/clara/startups/equity/Modals/ConnectedGrantsModal/ConnectedGrantsModalContent";
import VestingModalContent from "src/components/modules/clara/startups/equity/Modals/VestingModal/VestingModalContent";
import useBusinessRules from 'src/modules/clara/hooks/useBusinessRules';
import useDocumentBusinessRules from "src/modules/clara/hooks/useDocumentBussinessRules";
import Rename from 'src/modules/documents/modals/Rename';
import Button from 'src/modules/generic/components/Buttons/Button';
import Typography from 'src/modules/generic/components/Texts/Typography';
import useModal from "src/modules/generic/hooks/useModal";
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import useRoleAccess from "src/modules/security/hooks/useRoleAccess";
import { goToPage } from "src/store/actions/page";
import { RoleAccess } from 'src/v1/components';
import { ModalDocumentUploadNewVersion } from "src/v1/components/Form/ClaraForm/DocumentUploadNewVersionForm";
import { helper, ServerConnect } from "src/v1/utils";
import { Constants } from "src/v1/utils/constants";
import classesModule from './classes.module.scss';
import Stack from '@mui/material/Stack';
import Truncate from "src/modules/generic/components/Texts/Truncate";
import MicroFrontend from "../../../../../../../mf";
import useGetPartiesType from "src/components/forms/modules/startups/Document/Generate/Hooks/useGetPartiesByType";

function TitleDocumentGenerated({ document, title, refetch, template, ...props }) {

  const { translate } = useTranslate();
  const { openModal, closeModal } = useModal();
  const dispatch = useDispatch();
  const params = useParams();
  const { canRenameDocument } = useDocumentBusinessRules(document);
  const { isStartSubscription, isUploadDocumentNewVersion, isOwner, isManager, isEditor } = useBusinessRules();
  const accessDownloadDraft = useRoleAccess(Constants.ACTIONS.DOWNLOAD_DOCUMENT_TO_EDIT);
  const isSubscriptionDraft = isStartSubscription();
  const accessDownloadNoDraft = useRoleAccess(Constants.ACTIONS.DOWNLOAD_DOCUMENT);
  const accessUploadNewVersionDraft = useRoleAccess(Constants.ACTIONS.UPLOAD_DOCUMENT_NEW_VERSION);
  const isUploadDocumentNewVersionDraft = isUploadDocumentNewVersion();
  const isSubscriptionNoDraft = true;

  const isLock = useMemo(() => {
    return _.get(document, "isLocked", false);
  }, [document]);

  const handleLabelGetSignature = () => {
    if (_.get(document, 'status', '') === 'Pending') {
      return 'MODULES.ASSISTANCEV4.ASSISTANCE_BUTTON_RESEND_DOCUMENT_NOTIFICATION'
    } else {
      return 'MODULES.ASSISTANCEV4.ASSISTANCE_BUTTON_GENERATE_DOCUMENT_GET_SIGNATURE'
    }
  };

  const getVestingValues = (data) => {
    let vested = 0;
    let unvested = 0;
    let exercised = 0;
    let issued = 0;
    _.forEach(data.vestingDates, vest => {
      if (vest.status === 'VESTED') {
        vested += vest.numberOfOptions
      } else if (vest.status === 'UNVESTED') {
        unvested += vest.numberOfOptions
      } else if (vest.status === 'EXERCISED') {
        exercised += vest.numberOfOptions
      } else if (vest.status === 'ISSUED') {
        issued += vest.numberOfOptions
      }
    })
    return { vested, unvested, exercised, issued, total: vested + unvested + issued + exercised }
  };

  const getVestingSchedule = () => {
    let relatedStakeholder = _.find(_.get(document, 'stakeholders'), stakeholder => _.find(stakeholder.vestingSchedules, vest => _.get(vest, 'relatedGrant.id') === _.get(document, 'id')));
    let relatedSchedule = _.find(_.get(relatedStakeholder, 'vestingSchedules', []), vest => _.get(vest, 'relatedGrant.id') === _.get(document, 'id'));
    if (relatedSchedule) {
      return { stakeholder: { id: _.get(relatedStakeholder, 'id'), name: _.get(relatedStakeholder, 'fullName') }, name: _.get(relatedSchedule, 'relatedGrant.name'), ...getVestingValues(relatedSchedule), vestingSchedule: relatedSchedule }
    } else {
      return null
    }
  };

  const getConnectedGrants = () => {
    let sip = { ...document };
    sip['vestingSchedules'] = [];
    _.forEach(_.get(sip, 'relatedDocuments'), doc => {
      let allocatedShares = 0;
      if (doc.type.code === 'GRANT_AGREEMENT') {
        if (_.get(doc, 'stakeholders')) {
          _.forEach(doc.stakeholders, party => {
            if (party.vestingSchedules) {
              let relatedSchedule = _.find(party.vestingSchedules, vest => _.get(vest, 'relatedGrant.id') === _.get(doc, 'id'));
              if (relatedSchedule) {
                allocatedShares = allocatedShares + getVestingValues(relatedSchedule).total;
                sip.vestingSchedules.push({ name: relatedSchedule.relatedGrant.name, ...relatedSchedule, ...getVestingValues(relatedSchedule), stakeholder: { ...party } });
              }
            }
          })
        }
      }
    });
    return sip.vestingSchedules.length !== 0 ? sip : null;
  };

  const handleEditDocument = () => {
    if (document?.draftTemplate?.id || document?.template?.id) {
      goToPage(dispatch, Constants.PAGES[`viewDocumentDraft`], { documentDraftId: document?.id, docType: document?.type?.code });
      return
    }
    const dataParams = _.cloneDeep(document);
    if (!dataParams?.params?.agreementName) {
      dataParams.params.agreementName = dataParams?.name;
    }
    if (document?.params?.terms?.investorRights && !_.isArray(document?.params?.terms?.investorRights)) {
      dataParams.params.terms.investorRights = [];
      if (document?.params?.terms?.investorRights?.hasInformationRights) {
        dataParams.params.terms.investorRights.push("informationRights")
      }
      if (document?.params?.terms?.investorRights?.hasMFNRight) {
        dataParams.params.terms.investorRights.push("MFNRight")
      }
      if (document?.params?.terms?.investorRights?.hasParticipationRight) {
        dataParams.params.terms.investorRights.push("participationRight")
      }
    }
    openModal(EditDocumentGenerate, { data: dataParams, getDocumentsSIP: _.get(document, 'type.code') === 'GRANT_AGREEMENT', getDocumentsEIP: _.get(document, 'type.code') === 'USA_BOARD_APPROVING_OPTION_GRANT', getDocumentsBAOG: _.get(document, 'type.code') === 'USA_OPTION_GRANT_NOTICE', handleClose: closeModal, refetch })
  };

  const handleUploadNewVersionDocument = () => {
    openModal(ModalDocumentUploadNewVersion, { data: { document, template }, handleCloseModal: closeModal, refetch })
  }
  const handleViewVestingSchedule = () => {
    openModal(VestingModalContent, { initialValues: getVestingSchedule(), handleCloseForm: closeModal })
  };

  const handleViewConnectedGrants = () => {
    openModal(ConnectedGrantsModalContent, { initialValues: getConnectedGrants(), handleCloseForm: closeModal })
  };

  const onCompleteDeleteCLoseModal = () => {
    goToPage(dispatch, Constants.PAGES.documents)
  }

  const handleDisabledDeleteDocument = () => {
    return !helper.getValueFromKey(_.get(document, "type.allowedActions"), 'deleteDocument', true);
  };

  const handleDeleteGrant = (doc) => {
    openModal(DeleteGrantModal, {
      data: { ...doc },
      handleCloseModal: closeModal,
      onComplete: onCompleteDeleteCLoseModal,
      goTo: Constants.PAGES.documents,
      redirect: true
    })
  };

  const handleDeleteSIP = (doc) => {
    openModal(DeleteSIPContent, {
      data: doc,
      handleCloseModal: closeModal,
      onComplete: onCompleteDeleteCLoseModal,
      goTo: Constants.PAGES.documents,
      redirect: true
    })
  };

  const handleDisabledVoidDocument = () => {
    const allowedActions = _.get(document, 'type.allowedActions', []);
    if (!getAllowedActions(allowedActions, 'getSignature')) {
      return true;
    }
    const documentStatus = _.get(document, 'status', '');
    if (documentStatus === 'Pending') {
      const parties = _.get(document, 'parties', []);
      return parties.some(party => _.isEmpty(party.recipientStatus));
    }
    return documentStatus !== 'Pending';
  };

  const handleDeleteDocument = () => {
    openModal(DeleteUploadDocumentModal, {
      data: document,
      handleCloseModal: closeModal,
      goTo: Constants.PAGES.documents,
      redirect: true
    })
  };

  const handleDownloadDocument = async () => {
    let url = await ServerConnect.getUrlFile(document.file.id);
    await ServerConnect.downloadFile(url, document.file.name);
  };

  const handleDisabledEditDocument = () => {
    return _.get(document, 'status', '') !== 'Draft' && _.get(document, 'status', '') !== 'DRAFT_COMPLETED'
      ? true
      : !helper.getValueFromKey(_.get(document, 'type.allowedActions', []), 'editDetails', true);
  };

  const handleTerminateGrant = () => {
    openModal(TerminateGrant, { data: document, handleCloseModal: closeModal })
  };

  const handleDisabledDownloadDocument = () => {
    let accessDownload;
    let isSubscription;
    const enableDownload = _.find(_.get(document, "type.allowedActions"), allowedAction => {
      return allowedAction.key === 'enableDownload'
    })
    if (enableDownload && (_.get(document, 'status', '') === 'Draft' || _.get(document, 'status', '') === 'DRAFT_COMPLETED')) {
      return !enableDownload.value
    } else {
      if (_.get(document, 'status', '') === 'Draft' || _.get(document, 'status', '') === 'DRAFT_COMPLETED') {
        accessDownload = accessDownloadDraft;
        isSubscription = isSubscriptionDraft;
      } else {
        accessDownload = accessDownloadNoDraft;
        isSubscription = !isSubscriptionNoDraft;
      }
      return isSubscription || !accessDownload;
    }
  };
  const canUploadNewVersionDocument = () => {

    if (_.get(document, 'status', '') === 'Draft' || _.get(document, 'status', '') === 'DRAFT_COMPLETED') {
      return isUploadDocumentNewVersionDraft && accessUploadNewVersionDraft
    }
    return false
  };

  const handleDisabledRenameDocument = () => {
    return !((isOwner() || isManager() || isEditor()) && canRenameDocument())
  }

  const handleRenameDocument = () => {
    openModal(Rename, {
      document: {
        documentId: _.get(document, 'file.id'),
        agreementId: _.get(document, 'id'),
        documentName: _.get(document, 'name'),
      },
      onComplete: refetch
    })
  };

  const getAllowedActions = (actions, actionKey, defaultValue?) => {
    if (_.isEmpty(actions)) {
      return defaultValue !== undefined ? defaultValue : true;
    }
    return helper.getValueFromKey(actions, actionKey, defaultValue);
  };

  const handleGetSignaturesDocument = () => {
    openModal(GetSignatures, {
      data: document,
      saveAndClose: document.type === Constants.FORM_TYPES.DOCUMENT_GENERATED.GET_SIGNATURES,
      configMutation: document.type === Constants.FORM_TYPES.DOCUMENT_GENERATED.GET_SIGNATURES_EXERCISE_NOTICE ? {
        successMessage: "NOTIFICATION_SUCCESS_EXERCISE_NOTICE",
        paramsSuccessMessage: document
      } : {
        showSuccessNotification: true
      }
      , handleClose: closeModal
    })
  };

  console.log(document?.template?.id)

  const handleVoidDocument = () => {
    const allowedActions = _.get(document, 'type.allowedActions', []);
    const redirect = getAllowedActions(allowedActions, 'redirectAfterVoidDocument', false);
    openModal(CancelGetSignaturesModal, {
      initialValues: document,
      handleCloseForm: closeModal,
      goTo: Constants.PAGES.documents,
      redirect: redirect,
      onComplete: () => {
        if (document?.template?.id) window.location.reload(true)
      }
    })
  };

  const getPartiesType = useGetPartiesType()

  const handleDisabledGetSignature = () => {
    const allowedActions = _.get(document, 'type.allowedActions', []);
    const partiesType = getPartiesType(document?.type?.code);
    if (partiesType) {
      const companyField = partiesType?.partyGroupCompany?.party;
      const companyDocumentParam = document?.params?.[companyField]
      // If company kind is formation, then we should not allow to get signature
      if (companyDocumentParam && companyDocumentParam?.kind === 'FORMATION') {
        return true
      }
    }
    if (!getAllowedActions(allowedActions, 'getSignature')) return true;
    const documentStatus = _.get(document, 'status', '');
    if (documentStatus === 'Pending') {
      const parties = _.get(document, 'parties', []);
      return parties.some(party => _.isEmpty(party.recipientStatus));
    }
    if (!['Draft', 'DRAFT_COMPLETED', 'Pending'].includes(documentStatus)) {
      return true
    } else {
      if (_.get(document, 'type.code', '') === 'GRANT_AGREEMENT' || _.get(document, 'type.code', '') === 'USA_BOARD_APPROVING_OPTION_GRANT' || _.get(document, 'type.code', '') === 'USA_OPTION_GRANT_NOTICE') {
        return !(helper.getValueFromKey(
          _.get(document, 'type.allowedActions', []), 'getSignature', true) &&
          _.get(document, 'parentDocument.status') === 'Signed');
      } else {
        return !helper.getValueFromKey(_.get(document, 'type.allowedActions', []), 'getSignature', true);
      }
    }
  };



  const getDeleteButtons = () => {
    if (_.get(document, 'status', '') === 'Pending') {
      return null;
    }
    if (_.get(document, 'id') === params.id && _.get(document, 'kind') === 'GENERATED' && _.get(document, 'type.code') === 'GRANT_AGREEMENT') {
      return (
        <RoleAccess action={Constants.ACTIONS.DELETE_GRANT}>
          <Button
            disabled={handleDisabledDeleteDocument() || isLock}
            variant="cardWarning"
            onClick={() => { handleDeleteGrant(document) }}
          >
            {translate("DETAILS_DOCUMENT_GENERATED_BUTTON_DELETE_DOCUMENT")}
          </Button>
        </RoleAccess>
      )
    } else if (_.get(document, 'id') === params.id && _.get(document, 'kind') === 'GENERATED' && (_.get(document, 'type.code') === 'SHARE_INCENTIVE_PLAN' || _.get(document, 'type.code') === 'BOARD_RESOLUTION' || _.get(document, 'type.code') === 'SHARE_INCENTIVE_PLAN_FAQ')) {
      return (
        <RoleAccess action={Constants.ACTIONS.DELETE_SIP}>
          <Button
            disabled={handleDisabledDeleteDocument() || isLock}
            variant="cardWarning"
            onClick={() => { handleDeleteSIP(document) }}
          >
            {translate("DETAILS_DOCUMENT_GENERATED_BUTTON_DELETE_DOCUMENT")}
          </Button>
        </RoleAccess>)
    } else {
      return (
        <RoleAccess action={Constants.ACTIONS.DOWNLOAD_DOCUMENT_TO_EDIT}>
          <Button
            disabled={handleDisabledDeleteDocument() || isLock}
            variant="cardWarning"
            onClick={handleDeleteDocument}
          >
            {translate("DETAILS_DOCUMENT_GENERATED_BUTTON_DELETE_DOCUMENT")}
          </Button>
        </RoleAccess>
      )
    }
  };

  const isEdit = useRoleAccess(Constants.ACTIONS.EDIT_PROFILE);

  console.log(_.get(document, 'status', ''))

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      className={` ${classesModule.TitleDocumentGenerated} `}
    >
      <Typography variant='h2' color='blue'>
        <Truncate line={1} text={title} />
      </Typography>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        gap={"12px"}
      >
        {isEdit ? (
          <>
            {(accessUploadNewVersionDraft && _.get(document, 'type.code') !== 'EXERCISE_NOTICE') && (!handleDisabledEditDocument()) && (
              <RoleAccess action={Constants.ACTIONS.DOWNLOAD_DOCUMENT_TO_EDIT}>
                <Button
                  disabled={isLock}
                  variant="card"
                  onClick={handleEditDocument}
                >
                  {translate("DETAILS_DOCUMENT_BUTTON_EDIT_DOCUMENT")}
                </Button>
              </RoleAccess>
            )}
            {!handleDisabledRenameDocument() && (
              <Button
                disabled={isLock}
                variant="card"
                onClick={handleRenameDocument}
              >
                {translate("DETAILS_DOCUMENT_GENERATED_RENAME_DOCUMENT")}
              </Button>
            )}
            <RoleAccess action={Constants.ACTIONS.DOWNLOAD_DOCUMENT_TO_EDIT}>
              {!handleDisabledGetSignature() && (
                <>
                  {(((document?.template?.id && _.get(document, 'status', '') === 'DRAFT_COMPLETED' && !document.isReplaced)) || (document?.template?.id && _.get(document, 'status', '') === 'Draft' && document.isReplaced)) && (
                    <div style={{
                      width: 'auto',
                    }}>
                      <MicroFrontend id="MF_Sign" component={"./Sign"} mf="documents" params={{ documentDraftDocumentId: document?.id }} />
                    </div>
                  )}
                  {document?.template?.id && _.get(document, 'status', '') === 'Pending' && (
                    <div style={{
                      width: 'auto',
                    }}>
                      <MicroFrontend id="MF_Resend" component={"./Resend"} mf="documents" params={{ documentDraftDocumentId: document?.id }} />
                    </div>
                  )}

                  {((!(document?.template?.id ?? false))) && (
                    <Button
                      variant="card"
                      disabled={isLock}
                      onClick={handleGetSignaturesDocument}
                    >
                      {translate(handleLabelGetSignature())}
                    </Button>
                  )}
                </>
              )}
              {!handleDisabledVoidDocument() && (
                <Button
                  variant="card"
                  disabled={isLock}
                  onClick={handleVoidDocument}
                >
                  {translate("DETAILS_DOCUMENT_GENERATED_VOID_DOCUMENT")}
                </Button>
              )}
            </RoleAccess>
            {!handleDisabledDownloadDocument() && (
              <>
                {document?.template?.id && (
                  <div style={{
                    width: 'auto',
                  }}>
                    <MicroFrontend id="MF_Download" component={"./Download"} mf="documents" params={{ documentDraftDocumentId: document?.id, status: document?.status, file: document?.file }} />
                  </div>
                )}
                {!(document?.template?.id ?? false) && (
                  <Button
                    variant="card"
                    onClick={handleDownloadDocument}
                  >
                    {translate("DETAILS_DOCUMENT_GENERATED_DOWNLOAD_DOCUMENT")}
                  </Button>
                )}
              </>
            )}
            {(canUploadNewVersionDocument()) && (
              <RoleAccess action={Constants.ACTIONS.DOWNLOAD_DOCUMENT_TO_EDIT}>
                <Button
                  disabled={isLock}
                  variant="card"
                  onClick={handleUploadNewVersionDocument}
                >
                  {translate("DETAILS_DOCUMENT_BUTTON_UPLOAD_NEW_VERSION_DOCUMENT")}
                </Button>
              </RoleAccess>
            )}
            {(_.get(document, 'kind') === 'GENERATED' && _.get(document, 'type.code') === 'GRANT_AGREEMENT' && _.get(document, 'status', '') === 'Signed') &&
              <Button
                variant="card"
                disabled={isLock}
                onClick={handleTerminateGrant}
              >
                {translate("DETAILS_DOCUMENT_BUTTON_TERMINATE_GRANT")}
              </Button>
            }
            {getDeleteButtons()}
          </>
        ) : (
          <>
            {!handleDisabledDownloadDocument() && (
              <Button
                variant="card"
                disabled={isLock}
                onClick={handleDownloadDocument}
              >
                {translate("DETAILS_DOCUMENT_GENERATED_DOWNLOAD_DOCUMENT")}
              </Button>
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
}

TitleDocumentGenerated.propTypes = {
  children: PropTypes.any,
  document: PropTypes.any,
  title: PropTypes.any
}

export default TitleDocumentGenerated;
