import * as Yup from "yup";

import React, { useEffect, useMemo } from "react";
import {
  addAssistantText,
  clearAssistatText,
} from "src/modules/generic/store/action";

import { AnalizerInputWithBanner } from "src/components/inputs/AnalizerInputWithBanner";
import Button from "src/modules/generic/components/Buttons/Button";
import CalendarInputDate from "src/components/inputs/Calendars/CalendarInputDate";
import { Constants } from "src/v1/utils/constants";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import CountrySelector from "src/components/inputs/Select/CountrySelector/CountrySelector";
import FileUploaderInput from "src/components/inputs/FileUploader/FileUploaderInput";
import Form from "src/components/forms/Form/Form";
import { InputLabelWithBanner } from "../../../../inputs/InputWithBanner";
import Line from "src/components/generic/Line/Line";
import PhoneInput from "src/modules/generic/components/Inputs/PhoneInput";
import SelectInput from "src/components/inputs/Select/SelectInput/SelectInput";
import SubmitButton from "src/components/generic/Buttons/SubmitButton/SubmitButton";
import TemplateFormDefault from "src/modules/generic/templates/Modal";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import TextInput from "src/components/inputs/Text/TextInput";
import _ from "lodash";
import classes from "./IndividualStakeholderForm.module.scss";
import { useDispatch } from "react-redux";
import useStakeholderFieldsAnalizer from "../hooks/useStakeholderFieldsAnalizer";
import useTranslate from "src/modules/generic/hooks/useTranslate";

const CaymanRenewalIndividualStakeholderForm = ({
  initialValues = {},
  handleSubmit,
  handleCloseModal,
  propsTemplate,
  Template = TemplateFormDefault,
  banners = false,
}) => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const { config } = useStakeholderFieldsAnalizer(initialValues);
  const residenceCountry = _.get(initialValues, "address.country.code")
    ? _.get(initialValues, "address.country.code").toLowerCase()
    : "";
  const listTitle = [
    { label: "Mr", value: "Mr" },
    { label: "Mrs", value: "Mrs" },
    { label: "Ms", value: "Ms" },
    { label: "Dr", value: "Dr" },
    { label: "Hon", value: "Hon" },
    { label: "Reverend", value: "Reverend" },
    { label: "Very Reverend", value: "Very Reverend" },
    { label: "Senator", value: "Senator" },
  ];

  useEffect(() => {
    banners &&
      dispatch(
        addAssistantText(
          "<strong>Additional fees</strong> might apply if you need to update some of the details with the Cayman registrar.</br><div style='margin-top:1rem;'>To make a change, just edit the content and proceed. Once a change is made, a request will be sent to Clara and a service agent will get back to you with the related invoice to pay and next steps to legally file the changes for your company.</div>",
          "alert"
        )
      );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const defaultValues = useMemo(() => {
    return {
      ...initialValues,
      nationality: _.get(
        initialValues,
        "nationality.code",
        initialValues.nationality
      ),
    };
  }, [JSON.stringify(initialValues)]);

  const schema = Yup.object().shape({
    title: Yup.string()
      .required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED"))
      .nullable(),
    fullName: Yup.string().required(
      translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")
    ),
    previousName: Yup.string().nullable(),
    email: Yup.string()
      .lowercase()
      .matches(Constants.REGEXP.EMAIL, {
        message: translate("MODULES.CLARA.VALIDATION_ENTER_EMAIL"),
        excludeEmptyString: true,
      })
      .required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED"))
      .typeError(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")),
    linkedinURL: Yup.string()
      .matches(Constants.REGEXP.URL_LINKEDIN, {
        message: translate("MODULES.CLARA.VALIDATION_ENTER_URL"),
        excludeEmptyString: true,
      })
      .notRequired()
      .nullable(),
    nationality: Yup.string()
      .required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED"))
      .nullable(),
    passportNumber: Yup.string(
      translate("MODULES.CLARA.VALIDATION_ENTER_PASSPORT")
    )
      .required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED"))
      .nullable(),
    profession: Yup.string(
      translate("MODULES.CLARA.VALIDATION_ENTER_PROFESSION")
    )
      .required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED"))
      .typeError(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")),
    phoneNumber: Yup.string(
      translate("MODULES.CLARA.VALIDATION_ENTER_PASSPORT")
    )
      .required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED"))
      .typeError(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")),
    dateOfBirth: Yup.string()
      .matches(Constants.REGEXP.DATE, {
        message: translate("MODULES.CLARA.VALIDATION_ENTER_DATE"),
        excludeEmptyString: true,
      })
      .required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED"))
      .nullable(),
  });

  return (
    <Form defaultValues={defaultValues} schema={schema} onSubmit={handleSubmit}>
      <Template
        buttons={{
          submit: (
            <SubmitButton>
              {translate("MODULES.CLARA.BTN_SAVE_CHANGES")}
            </SubmitButton>
          ),
          cancel: (
            <Button onClick={() => handleCloseModal()} variant="secondary">
              {translate("MODULES.CLARA.BTN_CANCEL")}
            </Button>
          ),
        }}
        props={propsTemplate}
      >
        <TemplateLabel label={translate("MODULES.CLARA.FORM_TITLE")} isRequired>
          <ControllerInput
            render={SelectInput}
            name="title"
            list={listTitle}
            placeholder={translate("MODULES.CLARA.TYPE_HERE_HIT")}
          />
        </TemplateLabel>

        {banners ? (
          <InputLabelWithBanner
            bannerLogicType="touched"
            component={TextInput}
            controllerInputProps={{
              name: "fullName",
              placeholder: translate("MODULES.CLARA.TYPE_HERE_HIT"),
            }}
            templateLabelProps={{
              bannerTitle:
                'Select "Save changes" to add this change request to your cart. Then go to the Dashboard and click "View cart" to pay and submit.',
              label: translate("MODULES.CLARA.FORM_LEGAL_NAME"),
              isRequired: true,
            }}
          />
        ) : (
          <TemplateLabel
            label={translate("MODULES.CLARA.FORM_LEGAL_NAME")}
            isRequired
          >
            <ControllerInput
              render={TextInput}
              name="fullName"
              placeholder={translate("MODULES.CLARA.TYPE_HERE_HIT")}
            />
          </TemplateLabel>
        )}

        <TemplateLabel label={translate("MODULES.CLARA.FORM_FORMER_NAMES")}>
          <ControllerInput
            render={TextInput}
            name="previousName"
            placeholder={translate("MODULES.CLARA.TYPE_HERE_HIT")}
          />
        </TemplateLabel>

        <span className={classes.separator}>
          <Line />
        </span>

        {banners ? (
          <InputLabelWithBanner
            bannerLogicType="touched"
            component={CountrySelector}
            controllerInputProps={{
              name: "nationality",
              placeholder: translate("MODULES.CLARA.SELECT_COUNTRY"),
              showStrong: true,
              clear: true,
            }}
            templateLabelProps={{
              bannerTitle:
                'Select "Save changes" to add this change request to your cart. Then go to the Dashboard and click "View cart" to pay and submit.',
              label: translate("MODULES.CLARA.FORM_NATIONALITY"),
              isRequired: true,
            }}
          />
        ) : (
          <TemplateLabel
            label={translate("MODULES.CLARA.FORM_NATIONALITY")}
            isRequired
          >
            <ControllerInput
              render={CountrySelector}
              name="nationality"
              placeholder={translate("MODULES.CLARA.SELECT_COUNTRY")}
              showStrong
              clear
            />
          </TemplateLabel>
        )}

        <TemplateLabel
          label={translate("MODULES.CLARA.FORM_DATE_OF_BIRTH")}
          isRequired
        >
          <ControllerInput
            render={CalendarInputDate}
            placeholder={translate("MODULES.CLARA.ROLE_DATE_INPUT_HIT")}
            name="dateOfBirth"
          />
        </TemplateLabel>

        <TemplateLabel
          label={translate("MODULES.CLARA.FORM_PASSPORT_NUMBER")}
          isRequired
        >
          <ControllerInput
            render={TextInput}
            name="passportNumber"
            placeholder={translate("MODULES.CLARA.TYPE_HERE_HIT")}
          />
        </TemplateLabel>

        <span className={classes.separator}>
          <Line />
        </span>

        <TemplateLabel label={translate("MODULES.CLARA.FORM_EMAIL")} isRequired>
          <ControllerInput
            render={TextInput}
            name="email"
            placeholder={translate("MODULES.CLARA.TYPE_HERE_HIT")}
          />
        </TemplateLabel>

        <TemplateLabel
          label={translate("MODULES.CLARA.FORM_TELEPHONE_NUMBER")}
          isRequired
        >
          <ControllerInput
            render={PhoneInput}
            name="phoneNumber"
            defaultCountry={residenceCountry}
            disableDropdown={false}
            placeholder={translate("MODULES.CLARA.TYPE_HERE_HIT")}
          />
        </TemplateLabel>

        <span className={classes.separator}>
          <Line />
        </span>

        <TemplateLabel
          label={translate("MODULES.CLARA.FORM_PROFESSION")}
          isRequired
        >
          <ControllerInput
            render={TextInput}
            name="profession"
            placeholder={translate("MODULES.CLARA.EXAMPLE_PROFESSION")}
          />
        </TemplateLabel>

        <TemplateLabel label={translate("MODULES.CLARA.FORM_LINKEDIN_URL")}>
          <ControllerInput
            render={TextInput}
            name="linkedinURL"
            placeholder={translate("MODULES.CLARA.TYPE_HERE_HIT")}
          />
        </TemplateLabel>

        <TemplateLabel label={translate("MODULES.CLARA.FORM_CURRICULUM_VITAE")}>
          <ControllerInput
            render={FileUploaderInput}
            name="curriculumVitae"
            initVal={_.get(initialValues, "curriculumVitae")}
            placeholder={translate("MODULES.CLARA.TYPE_HERE_HIT")}
          />
        </TemplateLabel>

        <div>&nbsp;</div>
      </Template>
    </Form>
  );
};

export default CaymanRenewalIndividualStakeholderForm;
