import React, { useEffect, useMemo} from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Form from 'src/components/forms/Form/Form';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import Button from 'src/modules/generic/components/Buttons/Button';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import RadioInput from "src/components/inputs/Radio/RadioInput";
import TextInput from "src/components/inputs/Text/TextInput";
import { HiddenFieldForm } from "src/components/forms/contents";
import classes from './classes.module.scss';

const schemaWithDescription = Yup.object({
  question:Yup.boolean().required('Required'),
  description: Yup.string().when(['question'],{
    is:true,
    then:Yup.string().nullable(true).required('Required'),
    otherwise:Yup.string().optional().nullable(true)
  })
});

const schemaQuestion = Yup.object({
  question:Yup.boolean().required('Required'),
});

const schema = Yup.object().shape({
  isTaxResidentsAreFromCayman: schemaQuestion,
  isEntityInvestmentFund: schemaQuestion,
  isRegisteredInCIMA: schemaQuestion,
  doesHaveTradeBusinessLicense: schemaQuestion,
  isLCCLHolder: schemaQuestion,
  doesHaveFINumber: schemaWithDescription,
  doesHaveIRSGIN: schemaWithDescription,
});

function ReviewCompanyTaxInformation() {
  const { next, prev, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  
  const initialValues = useMemo(() => {
    return context?.ReviewCompanyTaxInformation ?? {}
  }, [context]);

  const handleCancel = () => {
    cancel();
  }

  const handlePrev = () => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  useEffect(() => {
    dispatch(addAssistantText(translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.TAX_INFORMATION.TOOLTIP')));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);


  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
          props={{
            title: translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.TAX_INFORMATION.TITLE'),
            skeleton: null,
            subTitle: translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.GENERIC.SUBTITLE'),
            variant: 'simple'
          }}
          buttons={{
            previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
            cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
            submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>Confirm</SubmitButton>,
          }}
      >
        <ol className={classes.list} type="a">
          <li>
            <TemplateLabel
                label={translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.TAX_INFORMATION.OPTION_1')}
                isRequired
                isColumn={true}
                className={classes.radioInput}
            >
              <ControllerInput
                  render={RadioInput}
                  name='isTaxResidentsAreFromCayman.question'
                  defaultValue={false}
              />
            </TemplateLabel>
          </li>
          <li>
            <TemplateLabel
                label={translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.TAX_INFORMATION.OPTION_2')}
                isRequired
                isColumn={true}
                className={classes.radioInput}
            >
              <ControllerInput
                  render={RadioInput}
                  name='isEntityInvestmentFund.question'
                  defaultValue={false}
              />
            </TemplateLabel>
          </li>
          <li>
            <TemplateLabel
                label={translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.TAX_INFORMATION.OPTION_3')}
                isRequired
                isColumn={true}
                className={classes.radioInput}
            >
              <ControllerInput
                  render={RadioInput}
                  name='isRegisteredInCIMA.question'
                  defaultValue={false}
              />
            </TemplateLabel>
          </li>
          <li>
            <TemplateLabel
                label={translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.TAX_INFORMATION.OPTION_4')}
                isRequired
                isColumn={true}
                className={classes.radioInput}
            >
              <ControllerInput
                  render={RadioInput}
                  name='doesHaveTradeBusinessLicense.question'
                  defaultValue={false}
              />
            </TemplateLabel>
          </li>
          <li>
            <TemplateLabel
                label={translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.TAX_INFORMATION.OPTION_5')}
                isRequired
                isColumn={true}
                className={classes.radioInput}
            >
              <ControllerInput
                  render={RadioInput}
                  name='isLCCLHolder.question'
                  defaultValue={false}
              />
            </TemplateLabel>
          </li>
          <li>
            <TemplateLabel
                label={translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.TAX_INFORMATION.OPTION_6')}
                isRequired
                isColumn={true}
                className={classes.radioInput}
            >
              <ControllerInput
                  render={RadioInput}
                  name='doesHaveFINumber.question'
                  defaultValue={false}
              />
            </TemplateLabel>
            <HiddenFieldForm
                fieldsWatch="doesHaveFINumber.question"
                conditionHideCallback={(value) => value}
            >
              <TemplateLabel label="Please provide number" isRequired>
                <ControllerInput
                    render={TextInput}
                    name='doesHaveFINumber.description'
                />
              </TemplateLabel>
            </HiddenFieldForm>
          </li>
          <li>
            <TemplateLabel
                label={translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.TAX_INFORMATION.OPTION_7')}
                isRequired
                isColumn={true}
                className={classes.radioInput}
            >
              <ControllerInput
                  render={RadioInput}
                  name='doesHaveIRSGIN.question'
                  defaultValue={false}
              />
            </TemplateLabel>
            <HiddenFieldForm
                fieldsWatch="doesHaveIRSGIN.question"
                conditionHideCallback={(value) => value}
            >
              <TemplateLabel label="Please provide number" isRequired>
                <ControllerInput
                    render={TextInput}
                    name='doesHaveIRSGIN.description'
                />
              </TemplateLabel>
            </HiddenFieldForm>
          </li>
        </ol>
      </TemplateWizard>
    </Form>
  );
}

export default ReviewCompanyTaxInformation;