import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import MoreToAddForm from "./MoreToAddForm";
import { clearAssistatText, addAssistantText } from "../../../../../generic/store/action";
import useMachine from '../../../../../generic/context/MachineContext/useMachine';

function MoreToAdd({ openParam }) {

  const dispatch = useDispatch();
  const [open, setOpen] = useState(openParam);
  const { context, next } = useMachine();

  useEffect(() => {
    setOpen(openParam);
  }, [openParam]);

  useEffect(() => {
    dispatch(clearAssistatText());
    dispatch(
      addAssistantText(
        `If this stakeholder holds more than one current passport, choose 'Yes, add another'. If not, choose ‘No, don't add’.​`
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, []);

  const handleCompleteSubmit = (values) => {
    next(values);
  }

  return (
    <MoreToAddForm
      initialValues={context.initialData}
      onCompleteSubmit={handleCompleteSubmit}
      propsTemplate={{
        subTitle: "Upload passport",
        title: "Do you currently hold, or have you previously held any other passports?",
      }}
      Template={TemplateWizard}
    />
  );
}

export default MoreToAdd;
