import React, { memo, useEffect, useMemo } from 'react';
import _ from "lodash";
import classnames from "classnames";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Constants } from "src/v1/utils/constants";
import PopoverOption from "src/modules/generic/components/Popovers/PopoverOption";
import Icon from "src/modules/generic/components/Icon";
import useModal from "src/modules/generic/hooks/useModal";
import ModalAddStakeholder from "src/modules/startup/onBoarding/stakeholder";
import AddFormationCompanyModal from "src/modules/startup/onBoarding/CreateCompanyModal/wizard/submachines/AddFormationCompanyModal";
import AddIncorporateCompanyModal from "src/modules/startup/onBoarding/CreateCompanyModal/wizard/submachines/AddIncorporateCompanyModal";
import useBusinessRules from "src/modules/clara/hooks/useBusinessRules";
import classes from './LinkMenu.module.scss';
import useSession from 'src/modules/session/hooks/useSession';
import useGetCountByTask from './services/useGetCountByTask';
import event from "src/v1/utils/event";



const LinkMenu = memo(({ value, setValue, goTo, linkMenu }) => {
  const { openModal } = useModal();
  const { startup } = useSession();
  const { isViewer, canUpgradeStartup } = useBusinessRules();

  const companyTopCoAndIncorporated = _.find(startup?.groupCompanies ?? [], (groupCompany) => {
    return groupCompany.isHoldingCompany === true && groupCompany.kind === Constants.MODULES.STARTUP.GROUP_COMPANY.KIND.INCORPORATED;
  });

  const companyIncorporated = _.find(startup?.groupCompanies ?? [], (groupCompany) => {
    return groupCompany.kind === Constants.MODULES.STARTUP.GROUP_COMPANY.KIND.INCORPORATED;
  });

  const handleOrder = (e) => {
    switch (e.target.value) {
      case 1:
        openModal(AddIncorporateCompanyModal, { initialValues: { noPrevious: true } }, 'MODAL_ADD_INCORPORATE_COMPANY');
        break;
      case 2:
        openModal(AddFormationCompanyModal, {}, 'MODAL_ADD_FORMATION_COMPANY');
        break;
      case 3:
        openModal(ModalAddStakeholder, "MODAL_MAP_STAKEHOLDER_ADD");
        break;
      case 4:
        goTo(Constants.PAGES.chooseDocumentType);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const links = [
      { key: "LinkDashboard" },
      { key: "LinkMyTasks" },
      { key: "LinkStakeHolderMap" },
      { key: "LinkDocuments" },
      { key: "LinkCapTable" },
      { key: "LinkDueDiligence" },
    ];
    let index = _.findIndex(links, (link) => link.key === linkMenu);
    if (index < 0) {
      index = false;
    }
    setValue(index);
  }, [linkMenu, setValue]);
  const { data: dataCountByTask, refetch } = useGetCountByTask({ variables: { startupId: startup.id }});


  useEffect(() => {
    event.on(Constants.EVENTS.REFRESH_MY_TASKS, async () => {
      
      refetch()
    });
    return () => {
      event.remove(Constants.EVENTS.REFRESH_MY_TASKS, () => {});
    };
  }, [refetch]);
  const pendingTasks = useMemo(() => {
    if (dataCountByTask) {
      return dataCountByTask?.getCountByTask?.reduce((acc, item) => {
        return acc + item.count;
      }, 0);
    }

    return 0;
  }, [dataCountByTask]);
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      {!isViewer() && (
        <Box
          className={classnames(`${classes.menuItem}`, {
            [classes.selectedTab]: value === 0,
          })}
          onClick={() => {
            goTo(Constants.PAGES.dashboard);
          }}
        >
          Dashboard
        </Box>
      )}
      <Box
        className={classnames(`${classes.menuItem}`, {
          [classes.selectedTab]: value === 1,
        })}
        onClick={() => {
          goTo(Constants.PAGES.myTasks);
        }}
      >
        My Tasks
        {pendingTasks > 0 && (<span className={classes.TaksPending}>
        </span>)}
      </Box>
      <Box
        className={classnames(`${classes.menuItem}`, {
          [classes.selectedTab]: value === 2,
        })}
        onClick={() => {
          goTo(Constants.PAGES.MODULES.MAP.maps);
        }}
      >
        Map
      </Box>
      <Box
        className={classnames(`${classes.menuItem}`, {
          [classes.selectedTab]: value === 3,
        })}
        onClick={() => {
          goTo(Constants.PAGES.documents);
        }}
      >
        Data Room
      </Box>
      <Box
        className={classnames(`${classes.menuItem}`, {
          [classes.selectedTab]: value === 4,
        })}
        onClick={() => {
          const gc = companyTopCoAndIncorporated || companyIncorporated;
          if (!gc) {
            goTo(Constants.PAGES["capTable.mf.empty"])
          } else {
            goTo(Constants.PAGES["capTable.mf.captable"], { groupCompanyId: gc?.id });
          }
        }}
      >
        Equity
      </Box>
      {/* <Box
        className={classnames(`${classes.menuItem}`, {
          [classes.selectedTab]: value === 4
        })}
        onClick={() => {
          goTo(Constants.PAGES.healthCheck);
        }}
      >
        Health Check
      </Box> */}
      {
        !isViewer()
        &&
        <PopoverOption
          className={classnames(`${classes.PopoverOption}`, { [classes.PopoverMargin]: canUpgradeStartup() })}
          onClickOption={handleOrder}
          innerDivClass={classes.plusButton}
          selectedClass={classes.selectedPlusButton}
          options={[
            {
              component: (
                <div className={classes.actionPanelOption}>
                  <div>
                    <Icon icon="Company" size="24px" isClara={true} />
                  </div>
                  <span>Add company</span>
                </div>
              ),
              key: 1,
            },
            {
              component: (
                <div className={classes.actionPanelOption}>
                  <div>
                    <Icon icon="Add-company" size="24px" isClara={true} />
                  </div>
                  <span>Form company</span>
                </div>
              ),
              key: 2,
            },
            {
              component: (
                <div className={classes.actionPanelOption}>
                  <div>
                    <Icon icon="Person" size="24px" isClara={true} />
                  </div>
                  <span>Add stakeholder</span>
                </div>
              ),
              key: 3,
            },
            {
              component: (
                <div className={classes.actionPanelOption}>
                  <div>
                    <Icon icon="document-stack" size="24px" isClara={true} />
                  </div>
                  <span>Generate documents</span>
                </div>
              ),
              key: 4,
            },
          ]}
        >
          <div className={classes.headerActionPanelButton}>
            <span>
              +
            </span>
          </div>
        </PopoverOption>
      }
    </Stack >
  );
});

export default LinkMenu;
