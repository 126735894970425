import classNames from "classnames";
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import ErrorInput from "../../../modules/generic/components/Inputs/ErrorInput";
import { TooltipInput } from "../../../v1/components";
import Text from '../../text/Text/Text';
import Parser from "html-react-parser";
import classes from './Checkbox.module.scss';

const Checkbox = forwardRef(({
  values,
  value,
  initialValues,
  options,
  onChange,
  showErrorMessage = true,
  isMultiple = true,
  label,
  innerRef,
  className = {},
  compare = '',
  keyId = "",
  errorMessage,
  classNameError,
  showErrorIcon,
  classCheckboxLine,
  translate = true,
  ...props
}, ref) => {

  const [valueSelected, setValueSelected] = useState([]);
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    if (values || value) {
      setValueSelected(values || value)
    }
  }, [values, value]);

  useImperativeHandle(innerRef, () => ({
    addValueSelected: (value) => {
      setValueSelected(pre => {
        onChange([...pre, value]);
        return ([...pre, value])
      })
    },
    setValue: (value) => {
      onChange(value);
      setValueSelected(value)
      setLocalValue(value)
    }
  }), []);

  const handleCompare = (valueA, valueB) => {
    const aux = compare ? _.isEqual(_.get(valueA, compare), _.get(valueB, compare)) : _.isEqual(valueA, valueB);
    return aux
  };

  const getValue = (option) => {
    const found = _.find(valueSelected, (value) => {
      return handleCompare(value, option.value);
    });
    return !!found;
  };

  const handleSelected = (check, option) => {
    if (isMultiple) {
      setValueSelected(pre => {
        const newValues = check ? [...pre, option.value] : _.filter(pre, val => !handleCompare(val, option.value));
        onChange(newValues);
        return newValues
      });
    } else {
      setValueSelected(check ? option.value : null);
      onChange(check ? option.value : null);
    }
  };

  const isSelected = (option) => {
    return valueSelected.some(op => handleCompare(op, option.value));
  };

  if (options) {
    return (
      <div className={`${classes.containerCheckbox}  ${className.root}`}>
        <TooltipInput link={props.link} label={props.tooltip}>
          {isMultiple ? (
            <>
              {options.map((option, index) => {
                return (
                  <React.Fragment key={`${index}${keyId}`}>
                    <TooltipInput link={option.link} label={option.tooltip}>
                      <div className={`${classes.Checkbox} ${className.checkbox} ${classCheckboxLine}`}>
                        <label htmlFor={`${index}${keyId}`}>
                          <button className={classNames(classes.buttonCheckedEmule, {
                            [classes.buttonChecked]: getValue(option),
                            [classes.buttonToCheck]: !getValue(option),
                          })}
                            type={"button"} onClick={(e) => handleSelected(!getValue(option), option)} hasError={errorMessage ? "true" : "false"} disabled={option.disabled} />
                          <span>
                            {translate? <Text uuid={option.label} />:option.label}
                          </span>
                        </label>
                      </div>
                    </TooltipInput>
                    {option.renderElement && isSelected(option) && option.renderElement({ value: option })}
                    {option.paragraph &&  <p className={classes.paragraph}>{Parser(option.paragraph)} </p>}
                  </React.Fragment>
                )
              })}
            </>
          ) : (
            <>
              {options.map((option, index) => {
                return (<>
                  <TooltipInput link={option.link} label={option.tooltip}>
                    <div className={`${classes.Checkbox} ${className.checkbox} ${classCheckboxLine}`}>
                      <label htmlFor={`${JSON.stringify(option)}`}>
                        <button className={classNames(classes.buttonCheckedEmule, {
                          [classes.buttonChecked]: option.value === valueSelected,
                          [classes.buttonToCheck]: option.value !== valueSelected,
                        })}
                          type={"button"} disabled={option.disabled} onClick={(e) => handleSelected(!(option.value === valueSelected), option)} hasError={errorMessage ? "true" : "false"} />
                        <span>
                          <Text uuid={option.label} />
                        </span>
                      </label>
                    </div></TooltipInput>
                  {option.value === valueSelected && option.renderElement && option.renderElement()}
                </>
                )
              })
              }
            </>
          )}
          {(errorMessage && showErrorMessage) && <ErrorInput message={errorMessage} classNameError={classNameError} showErrorIcon={showErrorIcon} />}
        </TooltipInput>
      </div>
    )
  } else {
    return (
      <div className={classes.containerCheckbox}>
        <div className={`${classes.Checkbox} ${className.checkbox} ${classCheckboxLine}`}>
          <label htmlFor={label}>
            <button className={classNames(classes.buttonCheckedEmule, {
              [classes.buttonChecked]: localValue,
              [classes.buttonToCheck]: !localValue,
            })}
              type={"button"} onClick={(e) => { setLocalValue(!localValue); onChange(!localValue) }} hasError={errorMessage ? "true" : "false"} />
            <i />
            <span className={`${className.label}`}>
              <Text uuid={label} />
            </span>
          </label>
        </div>
        {(errorMessage && showErrorMessage) &&
          <ErrorInput message={errorMessage} classNameError={classNameError} showErrorIcon={showErrorIcon} />
        }
      </div>
    )
  }
});

Checkbox.propTypes = {
  options: PropTypes.array,
  isMultiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  classCheckboxLine: PropTypes.any,
};

export default Checkbox
