import useGetStakeholderDetails from "./graphql/useGetStakeholderDetails";
import useUpsertRenewalData, { Tasks } from "./graphql/useUpsertRenewalData";
import useUpsertStakeholderDetails from "./graphql/useUpsertStakeholderDetails";
import useMapperStakeholderDetails from "./useMapperStakeholderDetails";

const useServices = ({ initialValues }) => {
  const updateStakeholderTasks = useUpsertRenewalData();
  const { query } = useGetStakeholderDetails({});
  const { getInitialData, getSubmitData } = useMapperStakeholderDetails();
  const updateStakeholder = useUpsertStakeholderDetails({}, {});

  return {
    initialData: async () => {
      const data = await query(initialValues.stakeholderId);
      return getInitialData(data?.stakeholder);
    },

    SaveStep: async (context) => {
      const { ReviewPersonalDetailShareholderNationalityStepForm } = context;
      const tasks = ["isStakeholderDetailsConfirmed" as Tasks];
      const data = getSubmitData(context);
      const result = await updateStakeholder(data, initialValues.stakeholderId);

      if (ReviewPersonalDetailShareholderNationalityStepForm.accept)
        tasks.push("areNationalitiesConfirmed" as Tasks);

      await updateStakeholderTasks(
        initialValues.groupCompanyId,
        tasks,
        initialValues.stakeholderId
      );

      return result;

    },
  };
};

export default useServices;
