import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Button from "../../../../../generic/components/Buttons/Button";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import AddPassportForm from "../../../../../../modules/startup/Stakeholder/ADGM/components/Forms/FormVisa";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import useModal from "../../../../../generic/hooks/useModal";
import { clearAssistatText, addAssistantText } from "../../../../../generic/store/action";
import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import Passport from "../../../../../../modules/startup/Stakeholder/ADGM/public/Passport.svg";

function AddPassport({ openParam }) {

  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const { closeModal: onClose } = useModal();
  const [open, setOpen] = useState(openParam);
  const { context, next } = useMachine();

  const handleCancel = (values) => {
    onClose();
    setOpen(false);
    clearAssistatText();
  }

  useEffect(() => {
    setOpen(openParam);
  }, [openParam]);

  const handleInitModal = () => {
    dispatch(clearAssistatText());
    dispatch(
      addAssistantText(
        `Upload a double page scan of the passport clearly showing their photo and passport number.`
      )
    );
  }

  useEffect(() => {
    handleInitModal();
    return () => {
      dispatch(clearAssistatText());
    };
  }, []);

  const handleCompleteSubmit = (values) => {
    next(values);
  }

  const handleTitle = () => {
    let result ="Upload passport";
    if (context?.initialData?.name) {
      result = result + " for " + context?.initialData?.name;
    }
    return result;
  }

  return (
    <AddPassportForm
      initialValues={context.initialData}
      onCompleteSubmit={handleCompleteSubmit}
      Template={TemplateWizard}
      propsTemplate={{
        title: "Upload passport",
        subTitle: handleTitle(),
      }}
      image={<img alt="" src={Passport} />}
      accept={"image_pdf"}
      buttons={{
        cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
      }}
    />
  );
}

export default AddPassport;
