import React, {useState, useEffect} from "react";
import ControllerInput from "../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import Form from "../../../Form/TestForm";
import { editProfile } from "../../../../../forms/schemas/modules/startups/groupCompany/schema";
import Text from "../../../../text/Text/Text";
import SubmitButton from "../../../../generic/Buttons/SubmitButton/SubmitButton";
import Line from "../../../../generic/Line/Line";
import TextInput from "../../../../inputs/Text/TextInput";
import CalendarInputDate from "../../../../inputs/Calendars/CalendarInputDate";
import Checkbox from "../../../../inputs/Checkbox/Checkbox";
import TemplateFormDefault from "../../../../../modules/generic/templates/Modal";
import useTranslate from "../../../../../modules/generic/hooks/useTranslate";
import Button from "../../../../../modules/generic/components/Buttons/Button";
import TemplateLabel from '../../../../../modules/generic/components/Inputs/LabelInput';
import AddressInput from "../../../../../components/forms/modules/startups/individualStakeholder/AddressInput";
import CountryJurisdiction from '../../../../../modules/generic/components/CountryJurisdictionSelector';
import TextareaInput from "../../../../inputs/Textarea/TextareaInput";
import classes from './GroupCompanyForm.module.scss'
import CurrencySelector from "../../../../inputs/Select/CurrencySelector/CurrencySelector";

const GroupCompanyForm = ({
  initialValues = {},
  handleSubmit,
  handleCloseModal,
  loading,
  handleDeleteModal,
  propsTemplate,
  Template = TemplateFormDefault,
  isCompletedCompanySetupTask,
  ...props
}) => {

  const { translate } = useTranslate();
  const [isFormation, setIsFormation] = useState(false);

  useEffect(() => {
    if(initialValues.kind === 'FORMATION') {
      setIsFormation(true)
    }
  },[initialValues]);

  return (<>
    <Form
      defaultValues={initialValues}
      schema={editProfile(isFormation)}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      registerValues={['id']}
      optionsForm={{
        shouldUnregister: true
      }}
    >
      <Template
        buttons={
          {
            submit: (
              <SubmitButton>
                {translate("MODULES.CLARA.BTN_SAVE_CHANGES")}
              </SubmitButton>
            ),
            cancel: (
              <Button onClick={() => handleCloseModal()} variant="secondary">
                {translate("MODULES.CLARA.BTN_CANCEL")}
              </Button>
            ),
          }
        }
        props={propsTemplate}
      >
        <TemplateLabel
          label={translate('MODULES.CLARA.COMPANY_NAME_LABEL')}
          isRequired={true}
        >
          <ControllerInput
            render={TextInput}
            name="name"
            defaultValue={""}
            placeholder={translate('MODULES.CLARA.TYPE_HERE_HIT')}
            onChange={(e) => {
            }}
          />
        </TemplateLabel>
        <CountryJurisdiction disabled={isFormation}/>
        <TemplateLabel
          label={translate('MODULES.CLARA.COMPANY_TYPE_LABEL')}
        >
          <ControllerInput
            render={TextInput}
            name="type"
            placeholder={translate('MODULES.CLARA.TYPE_HERE_HIT')}
            defaultValue={""}
            placehoCountrySelector
          />
        </TemplateLabel>
        <TemplateLabel
          label={translate('MODULES.CLARA.COMPANY_NUMBER_LABEL')}
        >
          <ControllerInput
            render={TextInput}
            name="registeredNumber"
            placeholder={translate('MODULES.CLARA.TYPE_HERE_HIT')}
            defaultValue={""}
            placehoCountrySelector
          />
        </TemplateLabel>
        {!isFormation && <TemplateLabel
          label={translate('MODULES.CLARA.COMPANY_CURRENCY')}
          isRequired
        >
          <ControllerInput
            render={CurrencySelector}
            name="transactionCurrency"
            defaultValue={""}
            placeholder={translate('MODULES.CLARA.SELECT_CURRENCY')}
          />
        </TemplateLabel>}
        <Line />
        <div>
          <ControllerInput
            render={Checkbox}
            name={"isHoldingCompany"}
            onChange={(e) => {
            }}
          />
          <label>
            <Text uuid={"FORM_COMAPANY_LABEL_COMPANY_HOLDING"} />{" "}
          </label>
        </div>
        <Line />
        <AddressInput
          name="registeredAddress"
          defaultValue={""}
          label={{ country: translate('MODULES.CLARA.ADDRESS_REGISTERED_LABEL') }}
        />
        <Line />
        <TemplateLabel
          label={translate('MODULES.CLARA.FORM_COMPANY_INCORPORATION_DATE_LABEL')}
        >
          <ControllerInput
            render={CalendarInputDate}
            name="incorporation_date"
            defaultValue={""}
            placeholder={translate("MODULES.CLARA.ROLE_DATE_INPUT_HIT")}
            onChange={(e) => {
            }}
          />
        </TemplateLabel>
        <TemplateLabel
          label={translate('MODULES.CLARA.FORM_COMPANY_DISSOLUTION_DATE_LABEL')}
        >
          <ControllerInput
            render={CalendarInputDate}
            name="dissolution_date"
            defaultValue={""}
            placeholder={translate("MODULES.CLARA.ROLE_DATE_INPUT_HIT")}
            onChange={(e) => {
            }}
          />
        </TemplateLabel>
        <TemplateLabel
          label={translate('MODULES.CLARA.FORM_LINKEDIN_URL')}
        >
          <ControllerInput
            render={TextInput}
            name="linkedinURL"
            defaultValue={""}
            placeholder={translate('MODULES.CLARA.TYPE_HERE_HIT')}
            onChange={(e) => {
            }}
          />
        </TemplateLabel>
        {(initialValues.kind === 'FORMATION' && isCompletedCompanySetupTask) && (
          <>
            <Line />
            <TemplateLabel
              props={{ variant: 'top' }}
              label={translate("MODULES.CLARA.SPECIAL_MEANING")}
            >
              <ControllerInput
                render={TextareaInput}
                defaultValue={""}
                className={classes.textArea}
                name="formationData.specialMeaning"
                placeholder={translate('MODULES.CLARA.TYPE_HERE_HIT')}
                onChange={(e) => {
                }}
              />
            </TemplateLabel>
            <TemplateLabel
              label={translate("MODULES.CLARA.ARABIC_TRANSLATION")}
              props={{ variant: 'top' }}
            >
              <ControllerInput
                render={TextareaInput}
                className={classes.textArea}
                defaultValue={""}
                name="formationData.arabicTranslation"
                placeholder={translate('MODULES.CLARA.TYPE_HERE_HIT')}
                onChange={(e) => {
                }}
              />
            </TemplateLabel>
            <TemplateLabel
              props={{ variant: 'top' }}
              label={translate("MODULES.CLARA.AFFILIATED")}
            >
              <ControllerInput
                render={TextareaInput}
                defaultValue={""}
                className={classes.textArea}
                name="formationData.affiliated"
                placeholder={translate('MODULES.CLARA.TYPE_HERE_HIT')}
                onChange={(e) => {
                }}
              />
            </TemplateLabel>
          </>
        )}
        <div>&nbsp;</div>
      </Template>
    </Form>
  </>);
};

export default GroupCompanyForm;
