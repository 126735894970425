import _ from 'lodash'
import React from 'react'
import Scrollbar from '../../../../../components/generic/Scrollbar/Scrollbar'
import classesModule from './classes.module.scss'
import { TemplateFormComponentProps, TemplateComponent } from './../../Modal'
import Typography from '../../../components/Texts/Typography'
import TemplateWizardSkeleton from './TemplateWizardSkeleton'
import useLoading from '../../../../generic/hooks/useLoading'
import { TemplateWizardProps, TemplateWizardButtonsProps } from '.'
import FileUpload from '../../../components/Inputs/FileUpload'
import ControllerInput from '../../../components/Inputs/ReactHookForm/ControllerInput'
export interface TemplateWizardPreviewProps extends TemplateWizardProps {
  title: string
  subTitle: string
  skeleton: React.ReactNode
  fileUploadProps?: {
    name: string
    limit: number
    defaultValue: object
  }
}

const TemplateWizardPreview: TemplateComponent<
  TemplateWizardPreviewProps,
  TemplateWizardButtonsProps
> = ({
  children,
  buttons = {},
  classNameBotonera,
  className,
  loading,
  props: {
    title = '',
    subTitle = '',
    skeleton = null,
    fileUploadProps = {
      name: 'files',
      limit: null,
      defaultValue: [],
    }
  }
}: TemplateFormComponentProps<
  TemplateWizardPreviewProps,
  TemplateWizardButtonsProps
>) => {

    const { isLoading } = useLoading();

    return (
      <React.Fragment>
        <div className={`${classesModule.TemplateWizard} ${className}`}>
          {isLoading && (
            <TemplateWizardSkeleton
              skeleton={skeleton}
              subTitle={subTitle}
              title={title}
            />
          )}
          <div>
            <Scrollbar className={`${classesModule.Scrollbar}`}>
              <div className={`${classesModule.Content}`}>
                {(subTitle || title) && (
                  <div className={`${classesModule.Header}`}>
                    {subTitle && (
                      <Typography
                        component={'label'}
                        variant={'body'}
                        color={'blue'}
                      >
                        {subTitle}
                      </Typography>
                    )}
                    {title && (
                      <Typography
                        component={'h1'}
                        color={'black'}
                        className={`${classesModule.Spacer}`}
                      >
                        {title}
                      </Typography>
                    )}
                  </div>
                )}
                <div className={`${classesModule.GridTemplateWizardPreview}`}>
                  <div className={`${classesModule.Form}`}>{children}</div>
                  <div className={`${classesModule.Preview}`}>
                    <ControllerInput
                      render={FileUpload}
                      {...fileUploadProps}
                      className={classesModule.ClassFileUpload}
                    />
                  </div>
                </div>
              </div>
            </Scrollbar>
            <div className={`${classesModule.Botonera} ${classNameBotonera}`}>
              <div className={classesModule.LeftButtons}>
                {buttons.previous}
                {_.map(buttons.anotherLeft, button => {
                  return <React.Fragment>{button}</React.Fragment>
                })}
              </div>
              <div className={classesModule.RightButtons}>
                {_.map(buttons.anotherRight, button => {
                  return <React.Fragment>{button}</React.Fragment>
                })}
                {buttons.cancel}
                {buttons.submit}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

export default TemplateWizardPreview
