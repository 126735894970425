import React, { useEffect, useMemo, useState } from "react";

import AddressesPanel from "src/views/modules/startups/company/companyView/Tabs/DetailsTab/panels/AddressesPanel";
import AddressesPanelADGM from "src/views/modules/startups/company/companyView/Tabs/DetailsTab/panels/ADGM/AddressesPanel";
import AssetsAndRevenueADGM from "src/views/modules/startups/company/companyView/Tabs/DetailsTab/panels/ADGM/AssetsAndRevenuePanel";
import Box from "@mui/material/Box";
import BusinessActivityPanelADGM from "src/views/modules/startups/company/companyView/Tabs/DetailsTab/panels/ADGM/BusinessActivityPanel";
import BusinessActivityPanelCay from "src/views/modules/startups/company/companyView/Tabs/DetailsTab/panels/Cayman/BusinessActivityPanel";
import DataProtectionADGM from "src/views/modules/startups/company/companyView/Tabs/DetailsTab/panels/ADGM/DataProtectionPanel";
import GeneralDetailsPanelADGM from "src/views/modules/startups/company/companyView/Tabs/DetailsTab/panels/ADGM/GeneralDetailsPanel";
import GeneralDetailsPanelCay from "src/views/modules/startups/company/companyView/Tabs/DetailsTab/panels/Cayman/GeneralDetailsPanel";
import GeneralDetailsPanelDelaware from "src/views/modules/startups/company/companyView/Tabs/DetailsTab/panels/Delaware/GeneralDetailsPanel";
import GroupCompany from "src/components/modules/clara/startups/entities/classes/GroupCompany";
import PlaceOfBusiness from "src/views/modules/startups/company/companyView/Tabs/DetailsTab/panels/Delaware/PlaceOfBusiness";
import PlaceOfBusinessPanelCay from "src/views/modules/startups/company/companyView/Tabs/DetailsTab/panels/Cayman/PlaceOfBusinessPanel";
import PropTypes from "prop-types";
import _ from "lodash";

function DetailsTab({ data, isLock, jurisdictionType }) {
  const [showPlaceOfBusiness, setShowPlaceOfBusiness] = useState(false);
  const [showBusinessActivity, setShowBusinessActivity] = useState(false);

  const groupCompanyClass = useMemo(() => {
    if (!data) return null;
    return new GroupCompany(data);
  }, [data]);

  const isFormation = groupCompanyClass.isFormation();
  const managedByClara = groupCompanyClass.isManagedByClara();
  const isCayman = groupCompanyClass.isCayman();
  const isADGM = groupCompanyClass.isADGM();

  useEffect(() => {
    const placeOfBusinessData = _.get(
      data,
      "formationData.placeOfBusiness",
      null
    );
    const businessActivityData = _.get(data, "businessActivities", null);
    setShowPlaceOfBusiness(
      placeOfBusinessData &&
        (placeOfBusinessData.state !== null ||
          placeOfBusinessData.city !== null)
    );
    setShowBusinessActivity(
      businessActivityData && businessActivityData.activity !== null
    );
  }, [data]);

  return (
    <Box sx={{ paddingRight: "0.4615rem" }}>
      {isCayman && (
        <>
          <GeneralDetailsPanelCay
            data={data}
            isLock={isLock}
            isFormation={isFormation}
            managedByClara={managedByClara}
          />
          {showPlaceOfBusiness && (
            <PlaceOfBusinessPanelCay data={data} isLock={isLock} />
          )}
          {!isFormation && (
            <BusinessActivityPanelCay data={data} isLock={isLock} />
          )}
          <AddressesPanel
            data={data}
            managedByClara={managedByClara}
            isLock={isLock}
          />
        </>
      )}
      {isADGM && (
        <>
          <GeneralDetailsPanelADGM
            data={data}
            isLock={isLock}
            isFormation={isFormation}
          />
          {showBusinessActivity && (
            <BusinessActivityPanelADGM data={data} isLock={isLock} />
          )}
          <AddressesPanelADGM data={data} isLock={isLock} />
          {managedByClara && <DataProtectionADGM data={data} isLock={isLock} />}
          {managedByClara && <AssetsAndRevenueADGM data={data} isLock={isLock} />}
        </>
      )}
      {(jurisdictionType === "DELAWARE" || _.isNil(jurisdictionType)) && (
        <>
          <GeneralDetailsPanelDelaware
            data={data}
            isLock={isLock}
            isFormation={isFormation}
          />
          <AddressesPanel
            data={data}
            managedByClara={managedByClara}
            isLock={isLock}
          />
          {showPlaceOfBusiness && (
            <PlaceOfBusiness data={data} isLock={isLock} />
          )}
        </>
      )}
    </Box>
  );
}

DetailsTab.propTypes = {
  data: PropTypes.any,
  isLock: PropTypes.bool,
  isEdit: PropTypes.bool,
  jurisdictionType: PropTypes.string,
};

export default DetailsTab;
