import useGetStakeholderDetails from "./graphql/useGetStakeholderDetails";
import useUpsertRenewalData, { Tasks } from "./graphql/useUpsertRenewalData";
import useUpsertStakeholderDetails from "./graphql/useUpsertStakeholderDetails";
import useMapperStakeholderDetails from "./useMapperStakeholderDetails";

const useServices = ({ initialValues }) => {
  const updateStakeholderTasks = useUpsertRenewalData();
  const { query } = useGetStakeholderDetails({});
  const { getInitialData } = useMapperStakeholderDetails();
  const updateStakeholder = useUpsertStakeholderDetails({}, {});

  return {

    initialData: async () => {
      const data = await query(initialValues.stakeholderId);
      return getInitialData(data?.stakeholder);
    },

    SaveStep: async (context) => {
      const {
        ReviewDetailsUBO,
        ReviewNationalitiesUBO,
        ReviewUboAddress,
        ReviewUboPEP,
        ReviewUboSof,
        initialData,
      } = context;
      const {
        title,
        fullName,
        previousName,
        arabicName,
        nationality,
        dateOfBirth,
        profession,
        email,
        phoneNumber,
      } = ReviewDetailsUBO;
      const { address } = ReviewUboAddress;
      const { isPEP, descriptionOfPEP } = ReviewUboPEP;
      const {
        sourceOfFunds,
        sourceOfWealth,
        otherSourcesOfWealth,
      } = ReviewUboSof;
      const tasks = ["isStakeholderDetailsConfirmed" as Tasks];
      let newSourceOfWealth = sourceOfWealth;
      if (ReviewUboSof?.sourceOfWealth === "others") {
        newSourceOfWealth = otherSourcesOfWealth;
      }
      const variables = {
        stakeholder: {
          title,
          fullName,
          previousName,
          arabicName,
          nationality,
          dateOfBirth,
          profession,
          email,
          phoneNumber,
          address,
          pep: {
            isPEP,
            descriptionOfPEP,
          },
          sourcesOfFunds: [
            {
              sourceOfFunds,
              sourceOfWealth: newSourceOfWealth,
            },
          ],
        },
      };
      const result = await updateStakeholder(
        variables,
        initialValues?.stakeholderId
      );
      if (ReviewNationalitiesUBO.accept)
        tasks.push("areNationalitiesConfirmed" as Tasks);
      await updateStakeholderTasks(
        initialData.groupCompany.id,
        tasks,
        initialValues.stakeholderId
      );
      return result;
    },
  };
};

export default useServices;
