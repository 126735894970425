import ReviewPersonalDetailShareholderAddressesStepForm from '../steps/ReviewPersonalDetailShareholderAddressesStepForm';
import ReviewPersonalDetailShareholderConfirmDetailForm from '../steps/ReviewPersonalDetailShareholderConfirmDetailForm';
import ReviewPersonalDetailShareholderNationalityStepForm from '../steps/ReviewPersonalDetailShareholderNationalityStepForm';
import ReviewPersonalDetailShareholderPEPStepForm from '../steps/ReviewPersonalDetailShareholderPEPStepForm';
import ReviewPersonalDetailShareholderSofFormStep from '../steps/ReviewPersonalDetailShareholderSofFormStep';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import { useMemo } from 'react';

const useSteps = () => {

  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    ReviewPersonalDetailShareholderConfirmDetailForm: {
      render: ReviewPersonalDetailShareholderConfirmDetailForm,
    },
    ReviewPersonalDetailShareholderNationalityStepForm: {
      render: ReviewPersonalDetailShareholderNationalityStepForm,
    },
    ReviewPersonalDetailShareholderAddressesStepForm: {
      render: ReviewPersonalDetailShareholderAddressesStepForm,
    },
    ReviewPersonalDetailShareholderPEPStepForm: {
      render: ReviewPersonalDetailShareholderPEPStepForm,
    },
    ReviewPersonalDetailShareholderSofFormStep: {
      render: ReviewPersonalDetailShareholderSofFormStep,
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },
  }), []);
}

export default useSteps;
