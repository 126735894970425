import _ from 'lodash';
import React from 'react';
import Scrollbar from '../../../../../components/generic/Scrollbar/Scrollbar';
import classesModule from './classes.module.scss';
import { TemplateFormComponentProps, TemplateComponent } from './../../Modal';
import Typography from '../../../components/Texts/Typography';
import TemplateWizardSkeleton from "./TemplateWizardSkeleton";
import useLoading from "../../../../generic/hooks/useLoading";

export interface TemplateWizardProps {
  title: string
  subTitle: string
  skeleton?: React.ReactNode
  variant: 'simple' | 'twoColumns',
  styleVariant?: any
}

export interface TemplateWizardButtonsProps {
  previous?: React.ReactNode
  cancel?: React.ReactNode
  submit?: React.ReactNode
  anotherLeft?: Array<React.ReactNode>
  anotherRight?: Array<React.ReactNode>
}

const TemplateWizard: TemplateComponent<TemplateWizardProps, TemplateWizardButtonsProps> = ({
  children,
  buttons = {},
  classNameBotonera,
  className,
  loading,
  props: { title = '', subTitle = '', skeleton = null, variant = 'simple', styleVariant = {} },
}: TemplateFormComponentProps<TemplateWizardProps, TemplateWizardButtonsProps>) => {
  const { isLoading } = useLoading();

  return (
    <React.Fragment>
      <div className={`${classesModule.TemplateWizard} ${className}`}>
        {isLoading && (
          <TemplateWizardSkeleton
            skeleton={skeleton}
            subTitle={subTitle}
            title={title}
          />
        )}
        <div>
          <Scrollbar className={`${classesModule.Scrollbar}`}>
            <div className={`${classesModule.Content}`}>
              {(subTitle || title) && (
                <div className={`${classesModule.Header}`}>
                  {subTitle && (
                    <Typography component={'label'} variant={'body'} color={'blue'}>
                      {subTitle}
                    </Typography>
                  )}
                  {title && (
                    <Typography
                      component={'h1'}
                      color={'black'}
                      className={`${classesModule.Spacer}`}
                    >
                      {title}
                    </Typography>
                  )}
                </div>
              )}
              <div className={`${classesModule.Form}`}>
                {variant === 'simple' && (<>{children}</>)}
                {variant === 'twoColumns' && (
                  <div style={{
                    display: 'grid',
                    gap: '2rem',
                    gridTemplateColumns: '1fr 1fr',
                    height: '100%',
                    ...styleVariant
                  }}>{children}</div>
                )}
              </div>
            </div>
          </Scrollbar>
          <div className={`${classesModule.Botonera} ${classNameBotonera}`}>
            <div className={classesModule.LeftButtons}>
              {buttons.previous}
              {_.map(buttons.anotherLeft, button => {
                return <React.Fragment>{button}</React.Fragment>
              })}
            </div>
            <div className={classesModule.RightButtons}>
              {_.map(buttons.anotherRight, button => {
                return <React.Fragment>{button}</React.Fragment>
              })}
              {buttons.cancel}
              {buttons.submit}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TemplateWizard
