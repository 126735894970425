import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Button from "../../../../../generic/components/Buttons/Button";
import TemplateWizardPreview from "../../../../../../modules/generic/templates/Modal/TemplateWizard/TemplateWizardPreview";
import ConfigurePassportForm from "../../../../../../modules/startup/Stakeholder/ADGM/components/Forms/FormPassportData";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import useModal from "../../../../../generic/hooks/useModal";
import { clearAssistatText, addAssistantText } from "../../../../../generic/store/action";
import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import _ from "lodash";
import { ICONS } from "../../../../../../modules/generic/components/Inputs/FileUpload/NoFilesUploaded";

function ConfigurePassport({ openParam }) {

  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const { closeModal: onClose } = useModal();
  const [open, setOpen] = useState(openParam);
  const { next, state, context } = useMachine();

  const handleCancel = (values) => {
    onClose();
    setOpen(false);
    clearAssistatText();
  }

  useEffect(() => {
    setOpen(openParam);
  }, [openParam]);

  const handleInitModal = () => {
    dispatch(clearAssistatText());
    dispatch(
      addAssistantText(
        `Upload a double page scan of the passport clearly showing their photo and passport number.`
      )
    );
  }

  useEffect(() => {
    handleInitModal();
    return () => {
      dispatch(clearAssistatText());
    };
  }, []);

  const handleCompleteSubmit = (values) => {
    next(values);
  }

  return (
    <ConfigurePassportForm
      initialValues={_.get(state, "context.addPassport", {})}
      onCompleteSubmit={handleCompleteSubmit}
      isOldPassport={context.initialData.isOldPassport}
      buttons={{
        cancel: (
          <Button onClick={handleCancel} variant="secondary">
            {translate('BUTTON_CANCEL')}
          </Button>
        ),
      }}
      propsTemplate={{
        title: "Add passport details",
        subTitle: "Upload passport",
        fileUploadProps: {
          accept: "image_pdf",
          icons: [ICONS.JPG, ICONS.PNG],
          name: 'files',
          limit: null,
          defaultValue: [],
        }
      }}
      Template={TemplateWizardPreview}
    />
  );
}

export default ConfigurePassport;
