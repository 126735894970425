import React, { FC, ReactNode } from "react";
import { FieldConfig } from "src/components/forms/modules/startups/hooks/useStakeholderFieldsAnalizer";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import ControllerInput, { ControllerInputProps } from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import { InputLabelWithBanner } from "../InputWithBanner";

interface AnalizerInputWithBannerProps {
  component: ReactNode,
  field: FieldConfig
  controllerInputProps: Omit<ControllerInputProps, 'render'> & { name: string }
  variant?: 'banner' | 'bannerLabel',

}
export const AnalizerInputWithBanner: FC<AnalizerInputWithBannerProps> = ({ field, component, controllerInputProps, variant = 'banner' }: AnalizerInputWithBannerProps) => {


  return <>
    {field?.show && field.hasPincas && (
      <InputLabelWithBanner
        bannerLogicType={field.bannerLogicType}
        component={component}
        controllerInputProps={{ ...controllerInputProps, disabled: field.disabled }}
        templateLabelProps={{
          bannerTitle: field.bannerTitle,
          label: field.label,
          isRequired: field.isRequired,
        }}
      />
    )}
    {field?.show && !field.hasPincas && (
      <TemplateLabel
        label={field.label}
        isRequired={field.isRequired}
      >
        <ControllerInput
          render={component}
          {...controllerInputProps}
          disabled={field.disabled}
        />
      </TemplateLabel>
    )}
  </>;
}
