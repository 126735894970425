import { useMemo } from 'react';
import _ from 'lodash';
import useSession from "src/modules/session/hooks/useSession";
import useUpdateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useUpdateStakeholder";
import useGetStakeholdersLazy from "src/modules/startup/onBoarding/core/graphql/queries/useGetStakeholderList";

const useServices = ({ initialValues }) => {
  const { startupId } = useSession();
  const { stakeholderId } = initialValues;
  const { manualQuery: manualQueryStakeholders } = useGetStakeholdersLazy();

  const { updateStakeholder } = useUpdateStakeholder({
    showSuccessNotification: true,
    successMessage: 'STAKEHOLDER_EDIT_SUCCESS'
  }, {});
  
  return useMemo(() => ({
    initialData: async () => {
      const result = await manualQueryStakeholders();
      const stakeholders = _.get(result?.data, 'stakeholderList', []);
      const individualStakeholders = _.filter(stakeholders, { isAnEntity: false });
      return { ...initialValues, stakeholders: individualStakeholders };
    },
    SaveStep: async (context) => {
      const values = context?.CorporateSignatoryForm;

      const variables = {
        startupId,
        stakeholderId,
        stakeholderData: {
          stakeholder: {
            authorizedSignatory: {
              fullName: values?.stakeholder?.fullName,
              stakeholder: values?.stakeholder?.id ?? null,
              email: values?.email,
              phoneNumber: values?.phoneNumber,
              signingAuthority: values?.signingAuthority,
              position: values?.position
            }
          }
        }
      };
      return await updateStakeholder({ variables });
    },
  }), [updateStakeholder, manualQueryStakeholders, initialValues, stakeholderId, startupId]);
}

export default useServices;
