import React, {useEffect, useMemo} from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Form from 'src/components/forms/Form/Form';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import Button from 'src/modules/generic/components/Buttons/Button';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import TextInput from "src/components/inputs/Text/TextInput";
import TextareaInput from "src/components/inputs/Textarea/TextareaInput";
import CountrySelector from "src/components/inputs/Select/CountrySelector/CountrySelector";
import classModule from "./classes.module.scss";

const schema = Yup.object().shape({
  country: Yup.string().required('Required'),
  street: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  state: Yup.string(),
  zipCode: Yup.string().required('Required'),
});

function ReviewCompanyAddressStep() {
  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.ReviewCompanyAddressStep ?? context?.initialData?.groupCompany?.registeredAddress ?? {}
  }, [context]);

  const handleCancel = () => {
    cancel();
  }

  const handlePrev = () => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  useEffect(() => {
    dispatch(addAssistantText(translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.COMPANY_ADDRESS.TOOLTIP')));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);
  console.log(initialValues)
  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.COMPANY_ADDRESS.TITLE'),
          skeleton: null,
          subTitle: translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.GENERIC.SUBTITLE'),
          variant: 'simple'
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>Next</SubmitButton>,
        }}
      >
        <>
          <TemplateLabel
            label={translate('MODULES.STAKEHOLDER.ADGM.REGISTERED_ADDRESS')}
          >
            <ControllerInput
              render={CountrySelector}
              name='country'
              defaultlabel={''}
              placeholder={translate('MODULES.STAKEHOLDER.ADGM.COUNTRY')}
              className={classModule.disabled}
              disabled
            />
          </TemplateLabel>
          <TemplateLabel label="">
            <ControllerInput
              render={TextareaInput}
              rows={6}
              name='street'
              defaultlabel={''}
              placeholder={translate('MODULES.STAKEHOLDER.ADGM.STREET')}
              className={classModule.disabled}
              disabled
            />
          </TemplateLabel>
          <TemplateLabel label="">
            <ControllerInput
              render={TextInput}
              name='city'
              defaultlabel={''}
              placeholder={translate('MODULES.STAKEHOLDER.ADGM.CITY')}
              className={classModule.disabled}
              disabled
            />
          </TemplateLabel>
          <TemplateLabel label="">
            <ControllerInput
              render={TextInput}
              name='state'
              defaultlabel={''}
              placeholder={translate('MODULES.STAKEHOLDER.ADGM.STATE_REGION')}
              className={classModule.disabled}
              disabled
            />
          </TemplateLabel>
          <TemplateLabel label="">
            <ControllerInput
              render={TextInput}
              name='zipCode'
              defaultlabel={''}
              placeholder={translate('MODULES.STAKEHOLDER.ADGM.ZIP_POST')}
              className={classModule.disabled}
              disabled
            />
          </TemplateLabel>
        </>
      </TemplateWizard>
    </Form>
  );
}

export default ReviewCompanyAddressStep;