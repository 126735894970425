import { createMachine } from "xstate";

const machine = createMachine({
  id: "ReviewDetailsUbo",
  initial: "initialData",
  states: {
    initialData: {
      invoke: {
        src: "initialData",
        id: "initialData",
        onDone: [
          {
            target: "ReviewDetailsUBO",
            actions: ["setContextAfterInvoke"],
          },
        ],
      },
    },
    ReviewDetailsUBO: {
      on: {
        NEXT: {
          target: "ReviewNationalitiesUBO",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    ReviewNationalitiesUBO: {
      on: {
        NEXT: {
          target: "ReviewUboAddress",
          actions: ["setContextAfterStep"],
        },
        PREV: {
          target: "ReviewDetailsUBO",
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    ReviewUboAddress: {
      on: {
        PREV: {
          target: "ReviewNationalitiesUBO",
          actions: ["setContextAfterStep"],
        },
        NEXT: {
          target: "ReviewUboPEP",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    ReviewUboPEP: {
      on: {
        PREV: {
          target: "ReviewUboAddress",
          actions: ["setContextAfterStep"],
        },
        NEXT: {
          target: "ReviewUboSof",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    ReviewUboSof: {
      on: {
        PREV: {
          target: "ReviewUboPEP",
          actions: ["setContextAfterStep"],
        },
        NEXT: {
          target: "SaveStep",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    SaveStep: {
      invoke: {
        src: "SaveStep",
        id: "SaveStep",
        onDone: [
          {
            target: "finish",
          },
        ],
        onError: [
          {
            target: "cancel",
          },
        ],
      },
    },
    cancel: {
      entry: "cancelMachine",
      type: "final",
    },
    finish: {
      entry: "stopMachine",
      type: "final",
    },
  },
});

export default machine;
