import React, { useRef } from 'react';
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import * as Yup from 'yup';
import Form from "../../../../components/forms/Form/FormL";
import TextInput from "../../../../components/inputs/Text/TextInput";
import SubmitButton from "../../../generic/components/Buttons/SubmitButton";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import Typography from "../../../generic/components/Texts/Typography";
import useTranslate from "../../../generic/hooks/useTranslate";
import TemplateFormDefault from "../../../generic/templates/Modal";

const schema = Yup.object().shape({
  businessActivity:Yup.object().shape({
    activity:Yup.string().nullable().required("This field is required"),
    operationCompany:Yup.string().nullable(),
  }),
});

const FormBusinessActivity = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}) => {  
  
  const { translate } = useTranslate();
  const refForm = useRef();


  return (
    <>
      <Form
        initialValues={initialValues || {}}
        schema={schema}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm}
        optionsForm={{ mode: "onChange" }}
      >
        <Template
          buttons={{
            ...buttons,
            submit: (
              <SubmitButton {...buttonSubmitProps}>{initialValues ? translate("MODULES.CLARA.BTN_SAVE_CHANGES") : translate('GENERIC_BUTTON_ADD_GROUP')}</SubmitButton>
            ),
          }}
          props={propsTemplate}
        >
          <Typography component={'p'} variant={'h4'}>
            {translate('MODULES.GROUP_COMPANY.FORM.NATURE_OF_BUSINESS.PARAGRAPH')}
          </Typography>
          <TemplateLabel isRequired={true} label={translate('MODULES.GROUP_COMPANY.FORM.NATURE_OF_BUSINESS.ACTIVITY_INPUT')}>
          <ControllerInput
            name='businessActivity.activity'
            render={TextInput}
            placeholder={translate('MODULES.GROUP_COMPANY.FORM.NATURE_OF_BUSINESS.ACTIVITY_PLACEHOLDER')}
          /></TemplateLabel>
          <TemplateLabel label={translate('MODULES.GROUP_COMPANY.FORM.NATURE_OF_BUSINESS.OPERATING_COMPANY_INPUT')}>
          <ControllerInput
            name='businessActivity.operationCompany'
            render={TextInput}
            placeholder={translate('MODULES.GROUP_COMPANY.FORM.NATURE_OF_BUSINESS.OPERATING_COMPANY_PLACEHOLDER')}
          /></TemplateLabel>
        </Template>
      </Form>
    </>
  )
}

export default FormBusinessActivity;
