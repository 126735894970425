import * as Yup from "yup";

import { Constants } from "../../../../../v1/utils/constants";

export const editProfile = (isFormation) => {
  return Yup.object().shape({
    name: Yup.string().required("This field is required."),
    description: Yup.string().nullable().optional(),
    country: Yup.string()
      .nullable()
      .required('This field is required'),
    jurisdiction: Yup.string()
      .nullable()
      .required('This field is required'),
    otherJurisdiction: Yup.string().when(['jurisdiction'], {
      is: 'OTHER',
      then: Yup.string()
        .nullable()
        .required('This field is required'),
      otherwise: Yup.string()
        .nullable()
        .optional()
    }),
    website: Yup.string()
      .matches(Constants.REGEXP.URL_PROTOCOL, {
        message: "Please enter a valid URL. eg:'https://...' or 'http://... '",
        excludeEmptyString: true,
      })
      .notRequired()
      .nullable(),
    linkedinURL: Yup.string()
      .matches(Constants.REGEXP.URL_LINKEDIN, {
        message: "Please enter a valid LinkedIn URL",
        excludeEmptyString: true,
      })
      .nullable(),
    transactionCurrency: Yup.string().when([], {
      is: () => isFormation === false,
      then: Yup.string().nullable().required('This field is required.'),
      otherwise: Yup.string().nullable().optional(),
    })
  });
}

export const editStartup = Yup.object().shape({
  name: Yup.string().required("This field is required."),
  description: Yup.string().nullable().optional(),
  website: Yup.string()
    .matches(Constants.REGEXP.URL_PROTOCOL, {
      message: "Please enter a valid URL. eg:'https://...' or 'http://... '",
      excludeEmptyString: true,
    })
    .notRequired()
    .nullable(),
  linkedInURL: Yup.string()
    .matches(Constants.REGEXP.URL_LINKEDIN, {
      message: "Please enter a valid LinkedIn URL",
      excludeEmptyString: true,
    })
    .nullable(),
});

export const addressAndCurrency = Yup.object().shape({
  transactionCurrency: Yup.string()
    .nullable()
    .required("This field is required."),
});

export const editNotice = Yup.object().shape({
  recipientNotice: Yup.object().shape({
    fullName: Yup.string()
      .nullable()
      .required("This field is required."),
    email: Yup.string().lowercase()
      .matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
      .nullable()
      .optional()
      .required("This field is required."),
    useRegisteredAddress: Yup.boolean().nullable(),
    address: Yup.object().when('useRegisteredAddress', {
      is: (value) => value === false || value === null,
      then: Yup.object().shape({
        country: Yup.string().required('This field is required').nullable(),
        street: Yup.string().required('This field is required').nullable(),
        city: Yup.string().required('This field is required').nullable(),
        state: Yup.string().nullable(),
        zipCode: Yup.string().nullable(),
      }),
      otherwise: Yup.object()
    })
  })
});

export const editDirectors = Yup.object().shape({
  directors: Yup.array().min(1).required(),
});
