import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import _ from "lodash";
import {
  addAssistantText,
  clearAssistatText,
} from "src/modules/generic/store/action";
import Button from "src/modules/generic/components/Buttons/Button";
import Form from "src/components/forms/Form/Form";
import SubmitButton from "src/components/generic/Buttons/SubmitButton/SubmitButton";
import TemplateFormDefault from "src/modules/generic/templates/Modal";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import AddressInput from "./AddressInput";
import GroupCompany from "src/components/modules/clara/startups/entities/classes/GroupCompany";
import Stakeholder from "src/components/modules/clara/startups/entities/classes/Stakeholder";

const AddressesFormADGM = ({
  initialValues = {},
  handleSubmit,
  handleCloseModal,
  propsTemplate,
  Template = TemplateFormDefault,
  isManagedByClara = false,
  stakeholder
}) => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const StakeholderClass = new Stakeholder(stakeholder);

  useEffect(() => {
    isManagedByClara &&
      dispatch(
        addAssistantText(
          "<strong>Additional fees</strong> might apply if you need to update some of the details with the ADGM registrar.<br /><br />Click on the field to update the details and view the corresponding fees (if any). All changes made will be added to your cart on the Dashboard, where you can pay and submit the updates.",
          "alert"
        )
      );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [isManagedByClara, dispatch]);

  const schema = Yup.object().shape({
    address: Yup.object().shape({
      country: Yup.string().required("This field is required").nullable(),
      street: Yup.string().required("This field is required").nullable(),
      city: Yup.string().required("This field is required").nullable(),
      state: Yup.string().nullable(),
      zipCode: Yup.string().nullable(),
    })
  });

  const { bannerCondition, fields } = useMemo(() => {
    const roles = StakeholderClass?.roles ?? [];
  
    if (!isManagedByClara) {
      return {
        bannerCondition: false,
        fields: []
      };
    }

    const relevantRole = _.find(roles, (role) => {
      const { groupCompany, name } = role;
      const groupCompanyClass = new GroupCompany(groupCompany);

      return (
        groupCompanyClass.isManagedByClara() &&
        groupCompanyClass.isADGM() &&
        ["DIRECTOR", "UBO", "SHAREHOLDER", "DATA_PROTECTION_CONTACT", "AUTHORIZEDSIGNATORY"].includes(name)
      );
    });
  
    if (relevantRole) {
      switch (relevantRole.name) {
        case "DIRECTOR":
        case "UBO":
          return {
            bannerCondition: true,
            fields: ["country", "street", "city"]
          };
  
        case "SHAREHOLDER":
        case "DATA_PROTECTION_CONTACT":
        case "AUTHORIZEDSIGNATORY":
          return {
            bannerCondition: true,
            fields: ["country"]
          };
  
        default:
          return {
            bannerCondition: false,
            fields: []
          };
      }
    }
  
    return {
      bannerCondition: false,
      fields: []
    };
  }, [JSON.stringify(StakeholderClass), isManagedByClara]);  

  return (
    <Form defaultValues={initialValues} schema={schema} onSubmit={handleSubmit}>
      <Template
        buttons={{
          submit: (
            <SubmitButton>
              {translate("MODULES.CLARA.BTN_SAVE_CHANGES")}
            </SubmitButton>
          ),
          cancel: (
            <Button onClick={() => handleCloseModal()} variant="secondary">
              {translate("MODULES.CLARA.BTN_CANCEL")}
            </Button>
          ),
        }}
        props={propsTemplate}
      >
        <AddressInput
          name="address"
          label={{
            country: translate("MODULES.CLARA.ADDRESS_LABEL_RESIDENTIAL"),
          }}
          banner={bannerCondition}
          fiedsToWatchBanner={fields}
          zipCodeOptional
          isRequired
        />
        <div />
      </Template>
    </Form>
  );
};

export default AddressesFormADGM;
