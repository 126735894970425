import useCustomMachine from '../../../../generic/hooks/useCustomMachine'
import React, { FC } from 'react'
import Button from '../../../../generic/components/Buttons/Button'
import { StepProps } from "../../../../generic/components/Wizard/Step"
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard'
import useTranslate from '../../../../generic/hooks/useTranslate'
import Form from "../../Forms/FormProfileStakeholder";
import { get } from 'lodash';
import { useDispatch } from 'react-redux'
import { addAssistantText, clearAssistatText } from '../../../../generic/store/action'

const Step1: FC<StepProps> = () => {

  const { translate } = useTranslate();
  const dispatch = useDispatch()
  const { next, send, state } = useCustomMachine();

  const handleCompleteSubmit = async values => {
    next(values);
  }
  const handleCancel = async values => {
    send("CANCEL");
  }

  React.useEffect(() => {
    dispatch(addAssistantText(`Keep an eye on the Clara star for hints and tips.`));
    return () => {
      dispatch(clearAssistatText())
    }
  }, [])

  return (
    <React.Fragment>
      <Form
        initialValues={get(state, 'context.data.personal_information', {})}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: 'Add their basic info',
          subTitle: "Personal information"
        }}
        buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}>
      </Form>
    </React.Fragment>
  )
}

export default Step1;
