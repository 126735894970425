import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import {
  addAssistantText,
  clearAssistatText,
} from "src/modules/generic/store/action";
import Button from "src/modules/generic/components/Buttons/Button";
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import ClaraList from "src/modules/generic/components/Lists/ClaraList";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import Form from "src/components/forms/Form/Form";
import { HiddenFieldForm } from "src/components/forms/contents";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import Typography from "src/modules/generic/components/Texts/Typography";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import classes from "../classes.module.scss";

export default function ReviewNationalitiesUBOStepForm() {
  const { next, context, cancel, prev } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const { initialData } = context;
  const initialValues = context?.ReviewNationalitiesUBO ?? {};

  const handleCancel = () => cancel();

  const handleSubmit = async (values) => next(values);

  const handlePrev = () => prev();

  useEffect(() => {
    // TODO ADD LINK URL
    dispatch(
      addAssistantText(
        translate("MODULES.RENEWALS_ADGM.REVIEW_ADDRESS.SPV_TOOLTIP_1"),
        "alert"
      )
    );
    dispatch(
      addAssistantText(
        translate("MODULES.RENEWALS_ADGM.REVIEW_ADDRESS.SPV_TOOLTIP_2")
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    accept: Yup.boolean().required("This field is required").nullable(),
  });

  const nationalities =
    (context?.initialData?.stakeholder?.possibleNationalities || []).length > 0
      ? context?.initialData?.stakeholder?.possibleNationalities.map((n) => ({
          ...n,
          code: n.alpha3,
        }))
      : [
          {
            ...context.ReviewDetailsUBO.countryNationality,
            code: context.ReviewDetailsUBO.countryNationality.alpha3,
            name: context.ReviewDetailsUBO.countryNationality.label,
          },
        ];

  const options = [
    {
      value: false,
      label: "No",
    },
    {
      value: true,
      label: "Yes",
    },
  ];

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          //TODO TRANSLATION
          title: "Your nationalities",
          //TODO get the name of the director form the api
          subTitle: `Review details for ${initialData.stakeholder.fullName}`,
        }}
        buttons={{
          submit: (
            <SubmitButton>{translate("MODULES.CLARA.BTN_NEXT")}</SubmitButton>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("MODULES.CLARA.BTN_CANCEL")}
            </Button>
          ),
          previous: (
            <Button onClick={handlePrev} variant="secondary">
              Previous
            </Button>
          ),
        }}
      >
        <Typography component="p" weight="regular" variant="bodyModal">
          Your current nationalities are listed below:
        </Typography>
        <ClaraList items={nationalities} variant="flags" />
        <TemplateLabel
          label="Do you have additional nationalities (past or present)?"
          variant="top"
          isRequired
        >
          <ControllerInput
            render={CheckboxButtonSimple}
            name="accept"
            options={options}
            isMultiple={false}
            classNameButton={classes.buttonCheckBox}
          />
        </TemplateLabel>
        <TemplateLabel label="">
          <HiddenFieldForm
            fieldsWatch="accept"
            conditionHideCallback={(value) => {
              if (typeof value === "boolean") {
                return value;
              }
            }}
          >
            <Typography component="p" variant="h4" fontWeight="semibold">
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    "You will be asked to upload the passports for these nationalities in a separate task.",
                }}
              />
            </Typography>
          </HiddenFieldForm>
        </TemplateLabel>
      </TemplateWizard>
    </Form>
  );
}
