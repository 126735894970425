import { createMachine } from "xstate";

const machine = createMachine({
  id: "StakeholderExpiredDocumentModal",
  initial: "initialData",
  states: {
    initialData: {
      invoke: {
        src: "initialData",
        id: "initialData",
        onDone: [
          {
            target: "HasBeenRenewedStep",
            actions: ["setContextAfterInvoke"],
          },
        ],
      },
    },
    HasBeenRenewedStep: {
      on: {
        NEXT: {
          target: "EvaluateDocumentRenewal",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    EvaluateDocumentRenewal: {
      always: [
        {
          cond: "renewed",
          target: "UploadDocumentStep",
        },
        {
          cond: "notRenewed",
          target: "SaveStep",
        },
      ],
    },
    UploadDocumentStep: {
      on: {
        NEXT: {
          target: "DocumentDetailsStep",
          actions: ["setContextAfterStep"],
        },
        PREV: {
          target: "HasBeenRenewedStep",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    DocumentDetailsStep: {
      on: {
        NEXT: {
          target: "SaveStep",
          actions: ["setContextAfterStep"],
        },
        PREV: {
          target: "UploadDocumentStep",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    SaveStep: {
      invoke: {
        src: "SaveStep",
        id: "SaveStep",
        onDone: [
          {
            target: "finish",
          },
        ],
        onError: [
          {
            target: "cancel",
          },
        ],
      },
    },
    cancel: {
      entry: "cancelMachine",
      type: "final",
    },
    finish: {
      entry: "stopMachine",
      type: "final",
    },
  },
});

export default machine;
