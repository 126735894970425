import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Form from 'src/components/forms/Form/Form';
import TextInput from 'src/components/inputs/Text/TextInput';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import CountrySelector from "src/components/inputs/Select/CountrySelector/CountrySelector.js";
import _ from "lodash";

function RegisteredAddress() {
  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    if(!_.isEmpty(context?.RegisteredAddress)) {
      return context?.RegisteredAddress;
    } else {
      return context?.initialData?.stakeholder?.address;
    }
  }, [context]);

  const { stakeholderName } = context?.initialData;

  const handleCancel = () => {
    cancel();
  }

  const handlePrev = () => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  useEffect(() => {
    dispatch(addAssistantText(translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DETAILS.STEP_2.TOOLTIP")));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);

  const schema = Yup.object().shape({
    country: Yup.string().required(translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DETAILS.REQUIRED")).nullable(),
    street: Yup.string().required(translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DETAILS.REQUIRED")).nullable(),
    city: Yup.string().required(translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DETAILS.REQUIRED")).nullable(),
    state: Yup.string().nullable(),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DETAILS.STEP_2.TITLE", { stakeholderName }),
          skeleton: null,
          subTitle: translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DETAILS.SUBTITLE"),
          variant: 'simple'
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <>
          <TemplateLabel
            label={translate('MODULES.STAKEHOLDER.ADGM.ADDRESS')}
            isRequired
          >
            <ControllerInput
              render={CountrySelector}
              name='country'
              defaultlabel={''}
              placeholder={translate('MODULES.STAKEHOLDER.ADGM.COUNTRY')}
            />
          </TemplateLabel>
          <TemplateLabel label="">
            <ControllerInput
              render={TextInput}
              name='street'
              defaultlabel={''}
              placeholder={translate('MODULES.STAKEHOLDER.ADGM.STREET')}
            />
          </TemplateLabel>
          <TemplateLabel label="">
            <ControllerInput
              render={TextInput}
              name='city'
              defaultlabel={''}
              placeholder={translate('MODULES.STAKEHOLDER.ADGM.CITY')}
            />
          </TemplateLabel>
          <TemplateLabel label="">
            <ControllerInput
              render={TextInput}
              name='state'
              defaultlabel={''}
              placeholder={translate('MODULES.STAKEHOLDER.ADGM.STATE_REGION')}
            />
          </TemplateLabel>
          <TemplateLabel label="">
            <ControllerInput
              render={TextInput}
              name='zipCode'
              defaultlabel={''}
              placeholder={translate('MODULES.STAKEHOLDER.ADGM.ZIP_POST')}
              optional
            />
          </TemplateLabel>
        </>
      </TemplateWizard>
    </Form>
  );
}

export default RegisteredAddress;

