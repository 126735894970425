import _ from "lodash";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import useGetGroupCompanyProfile from "../../graphql/queries/useGetGroupCompanyProfile";
import useUpdateGroupCompany from "../../../../../../../hooks/services/modules/groupCompany/useUpdateGroupCompany";
import useModal from "../../../../../../../modules/generic/hooks/useModal";
import useSession from "../../../../../../../modules/session/hooks/useSession";
import GroupCompanyForm from "../../../../../../forms/modules/startups/groupCompany/GroupCompanyForm";
import ModalAssistant from "../../../../../../../modules/generic/components/Modal/ModalAssistant";
import useTranslate from "../../../../../../../modules/generic/hooks/useTranslate";
import TemplateWizard from "../../../../../../../modules/generic/templates/Modal/TemplateWizard";
import SkeletonInput from "../../../../../../../modules/generic/components/Skeletons/Overlays/SkeletonInput";
import SkeletonArea from "../../../../../../../modules/generic/components/Skeletons/Overlays/SkeletonArea";
import Line from "../../../../../../generic/Line/Line";
import useUpsertFormationCompany from "../../../../../../../modules/startup/GroupCompany/graphql/mutations/useUpsertFormationCompany";
import { stripTypenames } from "../../../../../../../v1/utils/graphql-util";

const EditProfile = ({ showModal, handleCloseModal, open, refresh }) => {

  const { translate } = useTranslate();
  const { closeModal } = useModal();
  const { startupId, startup } = useSession();
  const params = useParams();
  const [newValues, setNewValues] = useState(null);

  const { data, loading } = useGetGroupCompanyProfile({
    variables: { startupId, groupCompanyId: params.id },
  });
  
  const updateStorageData = (values) => {
    return _.map(startup.groupCompanies, (comp) => {
      if (comp.id === params.id) {
        return (comp = { ...comp, ...values });
      } else {
        return comp;
      }
    });
  };

  const handleCloseForm = () => {

  };

  const overlayNameNotification = translate(
    "OVERLAY_NOTIFICATION_ASSISTANCE_BUTTON_GROUP_COMPANY_UPDATE"
  );

  const [upsertGroupCompany] = useUpdateGroupCompany({
    onCompleted: handleCloseForm,
    successMessage: "EDIT_COMPANY_NOTIFICATION_SUCCESS",
    paramsSuccessMessage: { overlayName: overlayNameNotification },
  });

  const [upsertFormationCompany] = useUpsertFormationCompany({
    onCompleted: handleCloseForm,
    successMessage: "NOTIFICATION_OVERLAY_SAVE_SUCCESS",
    paramsSuccessMessage: { overlayName: overlayNameNotification },
  });

  const isEmptyDeep = (values) => {
    return _.compact(_.values(values)).length ? values : null;
  };

  const handleSubmit = async (values) => {
    const { registeredAddress, otherJurisdiction, jurisdiction } = values;
    let groupCompany = {
      ...values,
      id: params.id,
      registeredAddress: isEmptyDeep(registeredAddress),
      jurisdiction: otherJurisdiction || jurisdiction,
    };
    jurisdiction === "OTHER" &&
      !otherJurisdiction &&
      delete groupCompany.jurisdiction;
    delete groupCompany.otherJurisdiction;
    delete groupCompany.kind;
    setNewValues(updateStorageData(groupCompany));
    if(data.kind === "FORMATION") {
      let variables = {
        startupId,
        groupCompanyId: params.id,
        groupCompanyData: stripTypenames(groupCompany),
      };
      await upsertFormationCompany({variables});
    } else {
      let variables = {
        startupId,
        groupCompanyId: params.id,
        groupCompany: stripTypenames(groupCompany),
      };
      await upsertGroupCompany({variables});
    }
    localHandleClose();
  };

  const localHandleClose = () => {
    handleCloseModal && handleCloseModal();
    closeModal();
  };

  const getFormationDataValueProfile = (answer, value) => {
    if (value) { 
      if (value === "None") {
        return "";
      } else {
        return value
      }
    } else if (_.isNil(answer)) {
      return ""
    } else {
      return "None"
    };
  };

  const isCompletedCompanySetupTask = useMemo(() => {
    return !_.isNull(_.get(data, "formationData.specialMeaningYN"));
  }, [JSON.stringify(data)]);

  const initialValues = useMemo(() => {
    return {
      ...data,
      formationData: {
        ..._.get(data, "formationData", {}),
        specialMeaning: getFormationDataValueProfile(
          _.get(data, "formationData.specialMeaningYN", null),
          _.get(data, "formationData.specialMeaning", null)
        ),
        arabicTranslation: getFormationDataValueProfile(
          _.get(data, "formationData.arabicTranslationYN", null),
          _.get(data, "formationData.arabicTranslation", null)
        ),
        affiliated: getFormationDataValueProfile(
          _.get(data, "formationData.affiliatedYN", null),
          _.get(data, "formationData.affiliated", null)
        ),
      },
      registeredAddress: {
        ..._.get(data, "registeredAddress", {}),
        country: _.get(data, "registeredAddress.country.code", {}),
      },
    };
  }, [JSON.stringify(data)]);

  return (
    <>
      <ModalAssistant open={showModal || open}>
        {!loading && (
          <GroupCompanyForm
            initialValues={initialValues}
            handleCloseModal={localHandleClose}
            handleSubmit={handleSubmit}
            Template={TemplateWizard}
            isCompletedCompanySetupTask={isCompletedCompanySetupTask}
            skeleton={
              <>
                <SkeletonInput count={3} />
                <Line />
                <SkeletonInput />
                <SkeletonArea noLabel={true} />
              </>
            }
            propsTemplate={{
              title: translate(`EDIT_DETAILS`),
            }}
          />
        )}
      </ModalAssistant>
    </>
  );
};

export default EditProfile;
