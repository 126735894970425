import React, { FC, useRef, useState } from 'react';
import * as Yup from 'yup';
import Form from "../../../../../../components/forms/Form/FormL";
import SubmitButton from "../../../../../generic/components/Buttons/SubmitButton";
import { CheckboxButtonOptions } from "../../../../../generic/components/Inputs/CheckboxButton";
import CheckboxButtonSimple from "../../../../../generic/components/Inputs/CheckboxButtonSimple";
import ControllerInput from "../../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import FormProps from "../../../../../generic/forms/interfaces/FormProps";
import TemplateFormDefault from "../../../../../generic/templates/Modal";
import classes from "./classes.module.scss";

interface MoreToAddFormProps extends FormProps {
  initialValues: any
}

const MoreToAddForm: FC<MoreToAddFormProps> = ({ 
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate
}: MoreToAddFormProps) => {

  const refForm = useRef();
  const [defaultBtnText, setDefaultBtnText] = useState("Next");

  const schema = Yup.object().shape({
    another:Yup.boolean().required(),
  });

  const options: Array<CheckboxButtonOptions<boolean, string>> = [
    {
      value: false,
      label: "No"
    },
    {
      value: true,
      label: "Yes"
    }
  ]

  return (
    <>
      <Form
        initialValues={initialValues || {}}
        schema={schema}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm} optionsForm={{ mode: "onChange" }}
      >
        <Template
          buttons={{ ...buttons, submit: <SubmitButton {...buttonSubmitProps}>{defaultBtnText}</SubmitButton> }}
          props={propsTemplate}
        >
          <ControllerInput
            render={CheckboxButtonSimple}
            name='another'
            variant='col_1'
            classNameButton={classes.buttonCheckBox}
            options={options}
            onChange={value => {
              if (value) {
                setDefaultBtnText("Next");
              } else {
                setDefaultBtnText("Continue");
              }
            }}
          />
        </Template>
      </Form>
    </>
  );
}

export default MoreToAddForm;
