
const useGetMapEconomicSubstance = () => {
    const getCompanyAddress = (context) => {
        const { ReviewCompanyAddressStep } = context;

        return {
            registeredAddress: {
                ...ReviewCompanyAddressStep
            }
        }
    }

    const getCompanyDetails = (context) => {
        const { ReviewCompanyDetailsStep } = context;
        return {
                companyDetails: {
                ...ReviewCompanyDetailsStep,
                ...getCompanyAddress(context),
            }
        }
    }

    const getEconomicSubstance = (context)=>{
        const {ReviewRelevantActivitiesStep, ReviewCompanyTaxInformation} = context;
        return {
            taxInformation: ReviewCompanyTaxInformation,
            businessActivities: {
                relevantActivities: ReviewRelevantActivitiesStep.activities,
            }
        }
    }

    const economicSubstanceMapper = (context)=>{
        const companyDetails = getCompanyDetails(context)
        const economicSubstanceInformation = getEconomicSubstance(context)
        return {...companyDetails, ...economicSubstanceInformation}
    }

    return economicSubstanceMapper
}

export default useGetMapEconomicSubstance;
