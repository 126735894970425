import React, { useMemo, useState } from "react";
import * as Yup from "yup";
import _ from "lodash";
import PhoneInput from "src/modules/generic/components/Inputs/PhoneInput";
import Button from "src/modules/generic/components/Buttons/Button";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import TemplateFormDefault from "src/modules/generic/templates/Modal";
import { Constants } from "src/v1/utils/constants";
import SubmitButton from "src/components/generic/Buttons/SubmitButton/SubmitButton";
import Line from "src/components/generic/Line/Line";
import CalendarInputDate from "src/components/inputs/Calendars/CalendarInputDate";
import FileUploaderInput from "src/components/inputs/FileUploader/FileUploaderInput";
import CountrySelector from "src/components/inputs/Select/CountrySelector/CountrySelector";
import TextInput from "src/components/inputs/Text/TextInput";
import Form from "src/components/forms/Form/Form";
import AddressInput from "./AddressInput";
import classes from "./IndividualStakeholderForm.module.scss";

const IndividualStakeholderForm = ({
  initialValues = {},
  handleSubmit,
  handleCloseModal,
  propsTemplate,
  Template = TemplateFormDefault,
}) => {
  const { translate } = useTranslate();
  const [residenceCountry, setResidenceCountry] = useState(
    _.get(initialValues, "address.country.code")
      ? _.get(initialValues, "address.country.code").toLowerCase()
      : ""
  );

  const defaultValues = useMemo(() => {
    return {
      ...initialValues,
      nationality: _.get(
        initialValues,
        "nationality.code",
        initialValues.nationality
      ),
      address: {
        ..._.get(initialValues, "address"),
        country: _.get(initialValues, "address.country.code"),
      },
    };
  }, [JSON.stringify(initialValues)]);

  const schemaNormal = Yup.object().shape({
    fullName: Yup.string().required(
      translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")
    ),
    email: Yup.string()
      .lowercase()
      .matches(Constants.REGEXP.EMAIL, {
        message: translate("MODULES.CLARA.VALIDATION_ENTER_EMAIL"),
        excludeEmptyString: true,
      })
      .notRequired()
      .nullable(),
    linkedinURL: Yup.string()
      .matches(Constants.REGEXP.URL_LINKEDIN, {
        message: translate("MODULES.CLARA.VALIDATION_ENTER_URL"),
        excludeEmptyString: true,
      })
      .notRequired()
      .nullable(),
    startDate: Yup.string()
      .matches(Constants.REGEXP.DATE, {
        message: translate("MODULES.CLARA.VALIDATION_ENTER_DATE"),
        excludeEmptyString: true,
      })
      .notRequired()
      .nullable(),
    endDate: Yup.string()
      .matches(Constants.REGEXP.DATE, {
        message: translate("MODULES.CLARA.VALIDATION_ENTER_DATE"),
        excludeEmptyString: true,
      })
      .notRequired()
      .nullable(),
    address: Yup.object()
      .shape({
        country: Yup.string().nullable().notRequired(),
        street: Yup.string().nullable().notRequired(),
        city: Yup.string().nullable().notRequired(),
        state: Yup.string().nullable().notRequired(),
        zipCode: Yup.string().nullable().notRequired(),
      })
      .nullable(),
    passportNumber: Yup.string(
      translate("MODULES.CLARA.VALIDATION_ENTER_PASSPORT")
    ).nullable(),
    profession: Yup.string(
      translate("MODULES.CLARA.VALIDATION_ENTER_PROFESSION")
    ).nullable(),
    phoneNumber: Yup.string(
      translate("MODULES.CLARA.VALIDATION_ENTER_PASSPORT")
    )
      .nullable()
      .typeError(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")),
    dateOfBirth: Yup.string()
      .matches(Constants.REGEXP.DATE, {
        message: translate("MODULES.CLARA.VALIDATION_ENTER_DATE"),
        excludeEmptyString: true,
      })
      .notRequired()
      .nullable(),
  });

  const schemaEGate = Yup.object().shape({
    fullName: Yup.string().required(
      translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")
    ),
    email: Yup.string()
      .lowercase()
      .matches(Constants.REGEXP.EMAIL, {
        message: translate("MODULES.CLARA.VALIDATION_ENTER_EMAIL"),
        excludeEmptyString: true,
      })
      .notRequired()
      .nullable(),
    linkedinURL: Yup.string()
      .matches(Constants.REGEXP.URL_LINKEDIN, {
        message: translate("MODULES.CLARA.VALIDATION_ENTER_URL"),
        excludeEmptyString: true,
      })
      .notRequired()
      .nullable(),
    startDate: Yup.string()
      .matches(Constants.REGEXP.DATE, {
        message: translate("MODULES.CLARA.VALIDATION_ENTER_DATE"),
        excludeEmptyString: true,
      })
      .notRequired()
      .nullable(),
    endDate: Yup.string()
      .matches(Constants.REGEXP.DATE, {
        message: translate("MODULES.CLARA.VALIDATION_ENTER_DATE"),
        excludeEmptyString: true,
      })
      .notRequired()
      .nullable(),
    entryDate: Yup.string()
      .matches(Constants.REGEXP.DATE, {
        message: translate("MODULES.CLARA.VALIDATION_ENTER_DATE"),
        excludeEmptyString: true,
      })
      .required()
      .nullable(),
    address: Yup.object()
      .shape({
        country: Yup.string().nullable().notRequired(),
        street: Yup.string().nullable().notRequired(),
        city: Yup.string().nullable().notRequired(),
        state: Yup.string().nullable().notRequired(),
        zipCode: Yup.string().nullable().notRequired(),
      })
      .nullable(),
    passportNumber: Yup.string(
      translate("MODULES.CLARA.VALIDATION_ENTER_PASSPORT")
    ).nullable(),
    profession: Yup.string(
      translate("MODULES.CLARA.VALIDATION_ENTER_PROFESSION")
    ).nullable(),
    phoneNumber: Yup.string(
      translate("MODULES.CLARA.VALIDATION_ENTER_PASSPORT")
    )
      .nullable()
      .typeError(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")),
    dateOfBirth: Yup.string()
      .matches(Constants.REGEXP.DATE, {
        message: translate("MODULES.CLARA.VALIDATION_ENTER_DATE"),
        excludeEmptyString: true,
      })
      .notRequired()
      .nullable(),
  });

  const handleChangeCountry = (e) => {
    if (!initialValues.phoneNumber || initialValues.phoneNumber.length <= 1) {
      if (e) {
        setResidenceCountry(e.toLowerCase());
      }
    }
  };

  return (
    <Form
      defaultValues={defaultValues}
      schema={
        _.get(defaultValues, "enterType", "") === "E_GATE"
          ? schemaEGate
          : schemaNormal
      }
      onSubmit={handleSubmit}
    >
      <Template
        buttons={{
          submit: (
            <SubmitButton>
              {translate("MODULES.CLARA.BTN_SAVE_CHANGES")}
            </SubmitButton>
          ),
          cancel: (
            <Button onClick={() => handleCloseModal()} variant="secondary">
              {translate("MODULES.CLARA.BTN_CANCEL")}
            </Button>
          ),
        }}
        props={propsTemplate}
      >
        <TemplateLabel
          label={translate("MODULES.CLARA.FORM_LEGAL_NAME")}
          isRequired={true}
        >
          <ControllerInput
            render={TextInput}
            name="fullName"
            placeholder={translate("MODULES.CLARA.TYPE_HERE_HIT")}
          />
        </TemplateLabel>

        <span className={classes.separator}>
          <Line />
        </span>

        <TemplateLabel label={translate("MODULES.CLARA.FORM_NATIONALITY")}>
          <ControllerInput
            render={CountrySelector}
            name="nationality"
            placeholder={translate("MODULES.CLARA.SELECT_COUNTRY")}
            showStrong={true}
            clear
          />
        </TemplateLabel>

        <TemplateLabel label={translate("MODULES.CLARA.FORM_DATE_OF_BIRTH")}>
          <ControllerInput
            render={CalendarInputDate}
            placeholder={translate("MODULES.CLARA.ROLE_DATE_INPUT_HIT")}
            name="dateOfBirth"
          />
        </TemplateLabel>

        <TemplateLabel label={translate("MODULES.CLARA.FORM_PASSPORT_NUMBER")}>
          <ControllerInput
            render={TextInput}
            name="passportNumber"
            placeholder={translate("MODULES.CLARA.TYPE_HERE_HIT")}
          />
        </TemplateLabel>

        <span className={classes.separator}>
          <Line />
        </span>

        <TemplateLabel label={translate("MODULES.CLARA.FORM_EMAIL")}>
          <ControllerInput
            render={TextInput}
            name="email"
            placeholder={translate("MODULES.CLARA.TYPE_HERE_HIT")}
          />
        </TemplateLabel>

        <TemplateLabel label={translate("MODULES.CLARA.FORM_TELEPHONE_NUMBER")}>
          <ControllerInput
            render={PhoneInput}
            name="phoneNumber"
            defaultCountry={residenceCountry}
            disableDropdown={false}
            placeholder={translate("MODULES.CLARA.TYPE_HERE_HIT")}
          />
        </TemplateLabel>

        <AddressInput
          name={"address"}
          label={{
            country: translate("MODULES.CLARA.ADDRESS_LABEL_RESIDENTIAL"),
          }}
          handleChangeCountry={handleChangeCountry}
        />

        <span className={classes.separator}>
          <Line />
        </span>

        <TemplateLabel label={translate("MODULES.CLARA.FORM_PROFESSION")}>
          <ControllerInput
            render={TextInput}
            name="profession"
            placeholder={translate("MODULES.CLARA.EXAMPLE_PROFESSION")}
          />
        </TemplateLabel>

        <TemplateLabel label={translate("MODULES.CLARA.FORM_LINKEDIN_URL")}>
          <ControllerInput
            render={TextInput}
            name="linkedinURL"
            placeholder={translate("MODULES.CLARA.TYPE_HERE_HIT")}
          />
        </TemplateLabel>

        <TemplateLabel label={translate("MODULES.CLARA.FORM_CURRICULUM_VITAE")}>
          <ControllerInput
            render={FileUploaderInput}
            name="curriculumVitae"
            initVal={_.get(initialValues, "curriculumVitae")}
            placeholder={translate("MODULES.CLARA.TYPE_HERE_HIT")}
          />
        </TemplateLabel>

        {_.get(defaultValues, "enterType", "") === "E_GATE" && (
          <>
            <span className={classes.separator}>
              <Line />
            </span>
            <TemplateLabel label={"E-Gate entry date"} isRequired={true}>
              <ControllerInput
                render={CalendarInputDate}
                placeholder={translate("MODULES.CLARA.ROLE_DATE_INPUT_HIT")}
                name="entryDate"
              />
            </TemplateLabel>
          </>
        )}

        <div>&nbsp;</div>
      </Template>
    </Form>
  );
};

export default IndividualStakeholderForm;
