import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Button from "src/modules/generic/components/Buttons/Button";
import { Constants } from "src/v1/utils";
import DetailsTabTitle from "src/components/templates/DetailsTabTitle";
import EditAddressesModal from "src/components/modules/clara/startups/individualStakeholder/Modals/EditAddresses/EditAddresses";
import PincasIcon from "src/modules/generic/components/Icon/PincasIcon";
import { RoleAccess } from "src/v1/components";
import Stakeholder from "src/components/modules/clara/startups/entities/classes/Stakeholder";
import TwoColumnsViewer from "src/modules/generic/components/TwoColumsViewer";
import useModal from "src/modules/generic/hooks/useModal";
import useTranslate from "src/modules/generic/hooks/useTranslate";

function AddressesPanel({ data, lock, isManagedByClara, jurisdictions }) {
  const { translate } = useTranslate();
  const { openModal, closeModal } = useModal();
  const [col1, setCol1] = useState([]);
  const [col2, setCol2] = useState([]);

  const addressesButton = (
    <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
      <Button
        disabled={lock}
        variant="card"
        children={translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT")}
        onClick={() => {
          openModal(EditAddressesModal, {
            handleCloseModal: closeModal,
            data,
            isManagedByClara,
            jurisdictionType: jurisdictionType,
          });
        }}
      />
    </RoleAccess>
  );

  const stakeholderClass = useMemo(() => {
    if (!data) return null;
    return new Stakeholder(data);
  }, [data]);



  const jurisdictionType = useMemo(() => {
    if (_.includes(jurisdictions, "ADGM")) return "ADGM";
    if (_.includes(jurisdictions, "CAYMAN")) return "CAYMAN";
    if (_.includes(jurisdictions, "DELAWARE")) return "DELAWARE";
    return null;
  }, [jurisdictions]);

  useEffect(() => {
    const col1 = [
      {
        label: translate("RESIDENTIAL_ADDRESS"),
        value: (
          <>
            <div>{_.get(data, `address.street`)}</div>
            <div>{_.get(data, `address.city`)}</div>
            <div>{_.get(data, `address.state`)}</div>
            <div>{_.get(data, `address.zipCode`)}</div>
            <div>{_.get(data, `address.country.name`)}</div>
          </>
        ),
        valuePrefix: stakeholderClass?.getFieldInMatter("address") ? (
          <PincasIcon />
        ) : null,
        disabledField: stakeholderClass?.getFieldInMatter("address"),
      },
    ];
    setCol1(col1);
  }, [data]);

  return (
    <>
      <DetailsTabTitle title="Addresses" buttons={addressesButton} />
      <TwoColumnsViewer itemsCol1={col1} itemsCol2={col2} />
    </>
  );
}

AddressesPanel.propTypes = {
  data: PropTypes.any,
  lock: PropTypes.bool,
  isManagedByClara: PropTypes.bool,
  jurisdictions: PropTypes.array,
};

export default AddressesPanel;
