export default {
  ASSISTANCE_TAB_TASKS: "Tasks",
  ASSISTANCE_TAB_ACTIONS: "Actions",
  ASSISTANCE_TAB_HELP: "Help",
  ASSISTANCE_READ_MORE: "Read more",
  ASSISTANCE_TEXT_INPUT_HIT: "Got a question? Type here...",
  ASSISTANCE_YOU_HAVE: "You have",

  ASSISTANCE_MAP_TASK_WELCOME1:
    "We'll start with the Essentials, then focus on tailored tasks to hit your goals.",

  ASSISTANCE_TASKS_MAP: {
    TASK_SHARE_CLASSES_ADD: "Add share classes for  {{{params.company.name}}}",
    TASK_SHARE_ISSUANCES_ADD:
      "Add share positions for {{{params.company.name}}}",
    TASK_OPTION_PLANS_OPTION_GRANTS_ADD:
      "Add option plans and option grants for {{{params.company.name}}}",
    TASK_WARRANTS_ADD: "Add warrants for {{{params.company.name}}}",
    TASK_CONVERTIBLES_ADD: "Add convertibles for  {{{params.company.name}}}",
    TASK_ADD_COMPANIES_GET_READY: "Companies",
    TASK_ADD_COMPANIES_INCORPORATED: "Companies",
    TASK_ADD_COMPANY: "Company",
    TASK_COMPLETE_ALL_DETAILS: "Complete details",
    TASK_ADD_FOUNDERS: "Founders",
    TASK_ADD_FOUNDERS_GET_READY: "Founders",
    TASK_ADD_DIRECTORS: "Directors",
    TASK_ADD_DIRECTORS_FORMATION: "Directors",
    TASK_ADD_DIRECTORS_GET_READY: "Directors",
    TASK_ADD_DIRECTORS_ADGM_BUY_REFER: "Directors",
    TASK_ADD_EMPLOYEES: "Employees",
    TASK_ADD_EMPLOYEES_GET_READY: "Employees",
    TASK_ADD_ADVISORS: "Advisor",
    TASK_ADD_ADVISORS_GET_READY: "Advisors",
    TASK_ADD_CONSULTANTS: "Consultant",
    TASK_ADD_CONSULTANTS_GET_READY: "Consultants",
    TASK_ADD_INVESTORS: "Investor",
    TASK_ADD_INVESTORS_GET_READY: "Investors",
    TASK_ADD_SHAREHOLDERS: "Shareholders",
    TASK_ADD_SHAREHOLDERS_FORMATION: "Shareholders",
    TASK_ADD_SHAREHOLDERS_ADGM_BUY_REFER: "Shareholders",
    TASK_ADD_COMPANY_ADGM_BUY_REFER: "Company",
    TASK_ADD_COMPANY_FORMATION: "Company",
    TASK_ADD_SUBMIT: "Submit formation",
    TASK_ADD_BUY_PACKAGE_BUY_REFER: "Buy package",
    TASK_ADD_ENGAGE_CONCIERGE_REFER: "Engage concierge",
    TASK_ADD_DOWNLOAD_GENERIC_BUILD: "Already formed",
    TASK_ADD_COMPANIES_ACCELERATOR: "Add companies",
    TASK_SELECT_TOPCO_ACCELERATOR: "Select your topco",
    TASK_COMPLETE_QUESTIONNAIRE_ACCELERATOR: "Complete questionnaire",
    TASK_ADD_OR_GENERATE_BOARD_RESOLUTION_ACCELERATOR:
      "Add or generate Board Resolution",
    TASK_CONFIRM_DOCUMENTS_ACCELERATOR: "Confirm documents",
    TASK_COMPLETE_STAKEHOLDERS_ACCELERATOR: "Confirm stakeholders",
    TASK_CONFIRM_DOCUMENTS_ACCELERATOR: "Confirm documents",
    TASK_ADD_SUBMIT_ACCELERATOR: "Submit your application",
    TASK_ADD_OR_GENERATE_1_FOUNDER_AGREEMENT_ACCELERATOR:
      "Add or generate Founder Services Agreement",
    TASK_ADD_OR_GENERATE_N_FOUNDERS_AGREEMENT_ACCELERATOR:
      "Add or generate Founders' Agreement",

    TASK_PENDING_GROUP_COMPANY_FORMATION: "Complete details for {{{user}}}",
    TASK_PENDING_GROUP_COMPANY: "Complete details for {{{user}}}",
    TASK_PENDING_GROUP_COMPANY_INCORPORATED:
      "Complete details for {{{params.company.name}}}",
    TASK_PENDING_GROUP_COMPANY_INCORPORATED_ACCELERATOR:
      "Complete details for {{{params.company.name}}}",

    TASK_PENDING_STAKEHOLDER_FORMATION: "Complete details for {{{user}}}",
    TASK_PENDING_STAKEHOLDER_INCORPORATED:
      "Complete details for {{{params.stakeholder.fullName}}}",
    TASK_PENDING_STAKEHOLDER_INCORPORATED_ACCELERATOR:
      "Complete details for {{{params.stakeholder.fullName}}}",

    TASK_READY_COMPANIES: "Complete {{count}} companies",
    TASK_READY_FOUNDER: "Complete {{count}} founder(s)",
    TASK_READY_DIRECTOR: "Complete {{count}} director(s)",
    TASK_READY_EMPLOYEE: "Complete {{count}} employee(s)",
    TASK_READY_ADVISOR: "Complete {{count}} advisor(s)",
    TASK_READY_CONSULTANT: "Complete {{count}} consultant(s)",
    TASK_READY_INVESTOR: "Complete {{count}} investor(s)",
    TASK_ADD_CERTIFICATE_INCORPORATION: "Certificate of Incorporation",
    TASK_ADD_COMMERCIAL_LICENSE: "Commercial Licence",
    TASK_ADD_OTHER_CONSTITUTIONAL_DOCUMENTS: "Other constitutional documents",
    TASK_ADD_FOUNDERS_AGREEMENT:
      "Founder Services Agreement - {{{groupCompany.name}}}",
    TASK_ADD_EMPLOYEE_AGREEMENT:
      "Employment Agreement - {{{groupCompany.name}}}",
    TASK_ADD_CONSULTANT_AGREEMENT:
      "Consultancy Agreement - {{{groupCompany.name}}}",
    TASK_ADD_ADVISOR_AGREEMENT: "Advisor Agreement - {{{groupCompany.name}}}",
    TASK_ADD_DIRECTOR_AGREEMENT: "Director Agreement - {{{groupCompany.name}}}",
    TASK_ADD_OTHER_DOCUMENTS: "Other documents - {{{groupCompany.name}}}",
    TASK_ADD_IP_ASSIGNMENT_AGREEMENT: "IP Assignment Agreement",
    TASK_ADD_SHARES: "Shares",
    TASK_ADD_SHARES_OPTIONS: "Share options",
    TASK_ADD_CONVERTIBLES: "Convertibles",
    TASK_ADD_WARRANTS: "Warrants",
    TASK_ADD_WARRANT_AGREEMENT:
      "Document for the warrant of {{amount}} in {{{groupCompany.name}}} {{#issuedDate}}issued on {{{issuedDate}}}{{/issuedDate}}",
    TASK_ADD_SHARE_AGREEMENT:
      "Document for {{amount}} shares {{#groupCompany}}in {{{groupCompany.name}}}{{/groupCompany}} {{#issuedDate}}issued on {{{issuedDate}}}{{/issuedDate}}",
    TASK_ADD_CONVERTIBLE_AGREEMENT:
      "Document for the convertible of {{amount}} {{#groupCompany}}in {{{groupCompany.name}}}{{/groupCompany}} {{#issuedDate}}issued on {{{issuedDate}}}{{/issuedDate}}",
    TASK_ADD_SHARE_OPTION_AGREEMENT:
      "Document for {{amount}} share options {{#groupCompany}}in {{{groupCompany.name}}}{{/groupCompany}} {{#issuedDate}}issued on {{{issuedDate}}}{{/issuedDate}}",
    TASK_ADD_WARRANT_AGREEMENT_FOR_IN_PROGRESS: "Document for warrant",
    TASK_ADD_CONVERTIBLE_AGREEMENT_FOR_IN_PROGRESS: "Document for convertible",
    TASK_ADD_SHARE_OPTION_AGREEMENT_FOR_IN_PROGRESS:
      "Document for share options",

    TASK_DOCUMENTS_COMPANIES: "Documents for {{count}} companies",
    TASK_DOCUMENTS_FOUNDER: "Documents for {{count}} founder(s)",
    TASK_DOCUMENTS_EMPLOYEE: "Documents for {{count}} employee(s)",
    TASK_DOCUMENTS_CONSULTANT: "Documents for {{count}} consultant(s)",
    TASK_DOCUMENTS_ADVISOR: "Documents for {{count}} advisor(s)",
    TASK_DOCUMENTS_INVESTOR: "Documents for {{count}} investor(s)",
    TASK_DOCUMENTS_DIRECTOR: "Documents for {{count}} director(s)",

    TASK_CAPTABLE_COMPANIES: "Equity for {{count}} companies",
    TASK_CAPTABLE_FOUNDER: "Equity for {{count}} founder(s)",
    TASK_CAPTABLE_EMPLOYEE: "Equity for {{count}} employee(s)",
    TASK_CAPTABLE_CONSULTANT: "Equity for {{count}} consultant(s)",
    TASK_CAPTABLE_ADVISOR: "Equity for {{count}} advisor(s)",
    TASK_CAPTABLE_INVESTOR: "Equity for {{count}} investor(s)",
    TASK_CAPTABLE_DIRECTOR: "Equity for {{count}} director(s)",

    TASK_HEALTHCHECK_COMPANIES: "Complete {{count}} companies",
    TASK_HEALTHCHECK_FOUNDER: "Complete {{count}} founder(s)",
    TASK_HEALTHCHECK_EMPLOYEE: "Complete {{count}} employee(s)",
    TASK_HEALTHCHECK_CONSULTANT: "Complete {{count}} consultant(s)",
    TASK_HEALTHCHECK_ADVISOR: "Complete {{count}} advisor(s)",
    TASK_HEALTHCHECK_INVESTOR: "Complete {{count}} investor(s)",
    TASK_HEALTHCHECK_DIRECTOR: "Complete {{count}} director(s)",

    TASK_RENEWAL_KY_REVIEW_ECONOMIC_SUBSTANCE_CONFIRMATION:
      "Review Economic Substance Confirmation",
    TASK_RENEWAL_KY_UPLOAD_ANNUAL_ACCOUNTS: "Upload Annual Accounts",

    TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_CHECK_EXP_DATE:
      "Corporate Signatory passport (Expired)",
    TASK_REGISTER_OF_DIRECTORS_CHECK_EXP_DATE:
      "Register of Directors (Expired)",
    TASK_REGISTER_OF_MEMBERS_CHECK_EXP_DATE: "Register of Members (Expired)",
    TASK_CERTIFICATE_OF_INCUMBENCY_CHECK_EXP_DATE:
      "Certificate of Incumbency (Expired)",
    TASK_CERTIFICATE_OF_GOOD_STANDING_CHECK_EXP_DATE:
      "Certificate of Good Standing (Expired)",
    TASK_TRADE_LICENSE_CHECK_EXP_DATE: "Trade License (Expired)",
    TASK_COMMERCIAL_LICENSE_CHECK_EXP_DATE: "Commercial License (Expired)",
    TASK_REGISTRY_EXTRACT_CHECK_EXP_DATE: "Registry Extract (Expired)",
    TASK_REGISTER_OF_PARTNERS_CHECK_EXP_DATE: "Register of Partners (Expired)",
    TASK_LIMITED_PARTNERSHIP_AGREEMENT_CHECK_EXP_DATE:
      "Limited Partnership Agreement (Expired)",

    TASK_PASSPORT_CHECK_EXP_DATE: "Passport (Expired)",
    TASK_EMIRATES_ID_CHECK_EXP_DATE: "Emirates ID (Expired)",
    TASK_UAE_RESIDENCE_VISA_CHECK_EXP_DATE: "UAE Residence VISA (Expired)",
    TASK_REGISTERS_LBS_CORP_CHECK_EXP_DATE: "Registers (Expired)",

    ADGM_RENEWAL_TASKS_STAKEHOLDER_INDIVIDUAL:
      "Complete details for {{{user}}}",
    ADGM_RENEWAL_TASKS_STAKEHOLDER_CORPORATE: "Complete details for {{{user}}}",

    ADGM_RENEWAL_GROUP_COMPANY_TASKS: "Review details for {{{user}}}",
    ADGM_RENEWAL_STAKEHOLDERS_TASKS: "Review details for {{{user}}}",
  },

  ASSISTANCE_GET_READY_TITLE: "Build your cap table ({{totals}})",
  ASSISTANCE_GET_READY_HELP: "",
  ASSISTANCE_GET_READY_COMPLETE: "Complete",

  ASSISTANCE_ACCELERATOR_SANABIL_TITLE: "{{{acceleratorName}}} ({{totals}})",
  ASSISTANCE_RENEWAL_TITLE: "{{{companyName}}} ({{totals}})",
  ASSISTANCE_ACCELERATOR_HELP_TOPCO_TASKS: "Complete the tasks below.",

  ASSISTANCE_MAP_HIT: "Keep an eye on the Clara star for hints and tips.",

  ASSISTANCE_MAP_TASK_CONGRATS1:
    "Phew, that's Essentials done. Your startup is on the map!",
  ASSISTANCE_MAP_TASK_CONGRATS2: "Celebrate, you smashed a goal!",
  ASSISTANCE_MAP_TASK_CONGRATS_SUBMIT_SANABIL500:
    "You’ve submitted your application for {{{acceleratorName}}}.",

  ASSISTANCE_DOCUMENTS_HIT:
    "To complete the goals you set, add these documents. Don't have them yet? It's easy to generate them with Clara.",
  ASSISTANCE_DOCUMENTS_HIT_2:
    "If you have multiple documents to upload, you can drag and drop them here all at once.",

  ASSISTANCE_EXERCISE_GENERATE_HIT:
    "Use this form if there are vested options ready to be exercised.",
  ASSISTANCE_TRANSFER_HIT:
    "Use this form If a shareholder is transferring certain shares that have been previously issued. This only applies to a transfer to a shareholder or third party and not to the company itself.",
  ASSISTANCE_CONVERT_HIT:
    "Use this form to convert exercised options into issued shares. A new share position will be created based on this conversion.",
  ASSISTANCE_CANCEL_SHARE_HIT:
    "Use this form to cancel certain shares that have already been issued. Unlike a repurchase, a cancellation immediately removes the shares from the issued share capital (if the shares are to be held by the company as treasury shares, consider a repurchase instead).",

  ASSISTANCE_ADD_AMEND_TASKS:
    "Use this form if the number of authorised shares was amended since this class was created.",
  ASSISTANCE_ADD_AMEND_TASKS_2:
    "Select the button Cancel and use Edit instead if you are trying to fix an error of the class details.",

  ASSISTANCE_TRANSACTIONS_AMEND_TO_PUBLISH:
    "You have a transaction in draft. Click on the 3 dots-menu the Amendments section to publish It.",

  ASSISTANCE_TRANSACTIONS_CONVERT_CONVERTIBLE:
    "Use this form to convert a convertible into shares to be issued under this convertible (e.g. when an equity funding round occurs).",
  ASSISTANCE_TRANSACTIONS_CANCEL_CONVERTIBLE:
    "Use this form to cancel a convertible. This will remove this convertible from your cap table without any new equity position in its place, such as shares.",

  ASSISTANCE_TRANSACTIONS_EXERCISE_WARRANT:
    "Use this form if a warrant is being exercised and shares issued under it.",
  ASSISTANCE_TRANSACTIONS_CANCEL_WARRANT:
    "Use this form to cancel a warrant. This will remove this warrant from your cap table without any new equity position in its place, such as shares.",

  ASSISTANCE_DOCUMENTS_CERT: "Certificate of Incorporation",
  ASSISTANCE_DOCUMENTS_LICENSE: "Comercial License",
  ASSISTANCE_DOCUMENTS_OTHER: "Other",
  ASSISTANCE_DOCUMENTS_EMPLOYMENT_AGREE: "Employment Agreement",
  ASSISTANCE_DOCUMENTS_FOUNDERS_AGREE: "Founders' Agreement",

  ASSISTANCE_ACCTIONS_BTN_EQUITY: "Add an equity position",
  ASSISTANCE_ACCTIONS_BTN_EDIT_NATURE_OF_BUSINESS: "Edit nature of business",
  ASSISTANCE_ACCTIONS_BTN_DOCUMENTS: "Generate documents",

  ASSISTANCE_ACCTIONS_BTN_ADD_DOCUMENTS: "Add documents",
  ASSISTANCE_ACCTIONS_BTN_ADD_REMARK: "Add remark",
  ASSISTANCE_ACCTIONS_BTN_PEP: "Manage PEP",
  ASSISTANCE_ACCTIONS_BTN_SOF: "Manage source of funds",

  ASSISTANCE_ACCTIONS_QUESTION: "What do you want to do today?",
  ASSISTANCE_CLARA_HELP_TEXT: "Clara Help",
  ASSISTANCE_GROUP_COMPANY_HIT:
    "Let's reach the goals you set. Select tasks from the list below until you've completed them all.",
  ASSISTANCE_GROUP_COMPANY_GOOD_JOB:
    "Good job! Consider this task checked off your to-do list. Go back to the map to complete your tasks and reach your goals.",
  ASSITANNCE_STAKEHOLDER_COMPLETE:
    "Good job! All the tasks for this profile are completed. Go back to the Dashboard to complete the rest of the tasks.",
  ASSITANCE_CAYMAN_START_RENEWAL_1:
    "{{{companyName}}} is due for its annual renewal.",
  ASSITANCE_CAYMAN_START_RENEWAL_2:
    " Click below to get started with your renewal tasks and submit the application before the deadline to avoid penalties.",
  ASSISTANCE_GROUP_COMPANY_PROFILE_NAME: "Profile",
  ASSISTANCE_TASKS_STAKEHOLDER: {
    TASK_ADD_STAKEHOLDER_CORPORATE_INFORMATION: "Company information",
    TASK_ADD_ADDRESS_CURRENCY: "Address & currency",
    TASK_ADD_COMPANY_NOTICES: "Notices",
    TASK_ADD_ROLES_POSITIONS: "Roles and positions",
    TASK_ADD_PERSONAL_INFORMATION: "Personal information",
    TASK_ADD_ADDRESSES: "Addresses",
    TASK_UAE_ENTRY_STAMP_SEC_SIGNER: "UAE entry stamp",
    TASK_ADD_STAKEHOLDER_CORPORATE_NOTICES: "Notices",
    TASK_ADD_AUTHORISED_SIGNATORIES: "Authorised Signatories",
    TASK_ADD_FOUNDERS_AGREEMENT:
      "Founder Services Agreement - {{{groupCompany.name}}}",
    TASK_ADD_EMPLOYEE_AGREEMENT:
      "Employment Agreement - {{{groupCompany.name}}}",
    TASK_ADD_CONSULTANT_AGREEMENT:
      "Consultancy Agreement - {{{groupCompany.name}}}",
    TASK_ADD_ADVISOR_AGREEMENT: "Advisor Agreement - {{{groupCompany.name}}}",
    TASK_ADD_DIRECTOR_AGREEMENT: "Director Agreement - {{{groupCompany.name}}}",
    TASK_ADD_OTHER_DOCUMENTS: "Other documents - {{{groupCompany.name}}}",
    TASK_ADD_IP_ASSIGNMENT_AGREEMENT: "IP Assignment Agreement",
    TASK_ADD_OTHERS: "Others",
    TASK_ADD_SHARES: "Shares",
    TASK_ADD_SHARES_OPTIONS: "Share options",
    TASK_ADD_CONVERTIBLES: "Convertibles",
    TASK_ADD_WARRANTS: "Warrants",
    TASK_ADD_WARRANT_AGREEMENT:
      "Document for the warrant of {{amount}} in {{{groupCompany.name}}} {{#issuedDate}}issued on {{{issuedDate}}}{{/issuedDate}}",
    TASK_ADD_SHARE_AGREEMENT:
      "Document for {{amount}} shares {{#groupCompany}}in {{{groupCompany.name}}}{{/groupCompany}} {{#issuedDate}}issued on {{{issuedDate}}}{{/issuedDate}}",
    TASK_ADD_CONVERTIBLE_AGREEMENT:
      "Document for the convertible of {{amount}} {{#groupCompany}}in {{{groupCompany.name}}}{{/groupCompany}} {{#issuedDate}}issued on {{{issuedDate}}}{{/issuedDate}}",
    TASK_ADD_SHARE_OPTION_AGREEMENT:
      "Document for {{amount}} share options {{#groupCompany}}in {{{groupCompany.name}}}{{/groupCompany}} {{#issuedDate}}issued on {{{issuedDate}}}{{/issuedDate}}",
    TASK_ADD_WARRANT_AGREEMENT_FOR_IN_PROGRESS: "Document for warrant",
    TASK_ADD_CONVERTIBLE_AGREEMENT_FOR_IN_PROGRESS: "Document for convertibles",
    TASK_ADD_SHARE_OPTION_AGREEMENT_FOR_IN_PROGRESS:
      "Document for share options",
    TASK_INVITE_COLLABORATOR: "Invite collaborator",
    TASK_ADD_PERSONAL_INFORMATION_V2: "Personal information",
    TASK_ADD_EQUITY_POSITIONS: "Equity Positions",
    TASK_COMPANY_DETAILS_LBS_CORP: "Company details",
    TASK_COMPANY_DETAILS_LBS_CORP_KYC_INC: "Company details",
    TASK_COMPANY_DIRECTORS_LBS_CORP: "Company directors",
    TASK_COMPANY_DIRECTORS_LBS_CORP_KYC_INC: "Company directors",
    TASK_CORPORATE_SIGNATORY_LBS_CORP: "Corporate signatory",
    TASK_CORPORATE_SIGNATORY_LBS_CORP_KYC_INC: "Corporate signatory",
    TASK_CORPORATE_SIGNATORY_LP_CORP: "Corporate signatory",
    TASK_CORPORATE_SIGNATORY_LP_CORP_KYC_INC: "Corporate signatory",
    TASK_CORPORATE_SIGNATORY_TD_CORP: "Corporate signatory",
    TASK_CORPORATE_SIGNATORY_TD_CORP_KYC_INC: "Corporate signatory",
    TASK_PEP_LBS_CORP: "PEP",
    TASK_PEP_LBS_CORP_KYC_INC: "PEP",
    TASK_SOURCE_OF_FUNDS_LBS_CORP: "Source of funds (ADGM)",
    TASK_SOURCE_OF_FUNDS_LBS_CORP_KYC_INC: "Source of funds (ADGM)",
    TASK_CERTIFICATE_OF_INCORPORATION_LBS_CORP: "Certificate of Incorporation",
    TASK_CERTIFICATE_OF_INCORPORATION_LBS_CORP_KYC_INC:
      "Certificate of Incorporation",
    TASK_REGISTERS_OR_CERTIFICATE_OF_INCUMBENCY_LBS_CORP:
      "Registers or Certificate of Incumbency",
    TASK_REGISTERS_OR_CERTIFICATE_OF_INCUMBENCY_LBS_CORP_KYC_INC:
      "Registers or Certificate of Incumbency",
    TASK_OFFICIAL_DOCUMENT_LBS_CORP: "Official Document",
    TASK_OFFICIAL_DOCUMENT_LBS_CORP_KYC_INC: "Official Document",
    TASK_COMPANY_DETAILS_LP_CORP: "Limited partnership details",
    TASK_COMPANY_DETAILS_LP_CORP_KYC_INC: "Limited partnership details",
    TASK_CERTIFICATE_OF_INCORPORATION_LP_CORP: "Certificate of Incorporation",
    TASK_CERTIFICATE_OF_INCORPORATION_LP_CORP_KYC_INC:
      "Certificate of Incorporation",
    TASK_CERTIFICATE_OF_GOOD_STANDING_LP_CORP: "Certificate of Good Standing",
    TASK_CERTIFICATE_OF_GOOD_STANDING_LP_CORP_KYC_INC:
      "Certificate of Good Standing",
    TASK_REGISTER_OF_PARTNERS_OR_LIMITED_PARTNERSHIP_AGREEMENT_LP_CORP:
      "Register of Partners or Limited Partnership Agreement",
    TASK_REGISTER_OF_PARTNERS_OR_LIMITED_PARTNERSHIP_AGREEMENT_LP_CORP_KYC_INC:
      "Register of Partners or Limited Partnership Agreement",
    TASK_TRUST_DEED_TD_CORP: "Trust Deed",
    TASK_TRUST_DEED_TD_CORP_KYC_INC: "Trust Deed",
    TASK_CERTIFICATE_OF_REGISTRATION_TD_CORP: "Certificate of Registration",
    TASK_CERTIFICATE_OF_REGISTRATION_TD_CORP_KYC_INC:
      "Certificate of Registration",
    TASK_REGISTERS_LBS_CORP: "Registers",
    TASK_REGISTERS_LBS_CORP_KYC_INC: "Registers",
    TASK_ARTICLES_OF_ASSOCIATION_LBS_CORP: "Articles of Association",
    TASK_ARTICLES_OF_ASSOCIATION_LBS_CORP_KYC_INC: "Articles of Association",
    TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_LBS_CORP: "Passport for {{{name}}}",
    TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_LBS_CORP_KYC_INC:
      "Passport for {{{name}}}",
    TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_LP_CORP: "Passport for {{{name}}}",
    TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_LP_CORP_KYC_INC:
      "Passport for {{{name}}}",
    TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_TD_CORP: "Passport for {{{name}}}",
    TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_TD_CORP_KYC_INC:
      "Passport for {{{name}}}",
    TASK_PASSPORT_LBS_KYC_INC: "Passport for {{{name}}}",
    TASK_PASSPORT_LBS: "Passport for {{{name}}}",
    TASK_PROOF_OF_ADDRESS_CHECK_EXP_DATE: "Proof Of Address",
    TASK_PASSPORT_CHECK_EXP_DATE: "Passport (Expired)",
    TASK_EMIRATES_ID_CHECK_EXP_DATE: "Emirates ID (Expired)",
    TASK_UAE_RESIDENCE_VISA_CHECK_EXP_DATE: "UAE Residence VISA (Expired)",
    TASK_REGISTERS_LBS_CORP_CHECK_EXP_DATE: "Registers (Expired)",
    TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_CHECK_EXP_DATE:
      "Corporate Signatory passport (Expired)",
    TASK_REGISTER_OF_DIRECTORS_CHECK_EXP_DATE:
      "Register of Directors (Expired)",
    TASK_REGISTER_OF_MEMBERS_CHECK_EXP_DATE: "Register of Members (Expired)",
    TASK_CERTIFICATE_OF_INCUMBENCY_CHECK_EXP_DATE:
      "Certificate of Incumbency (Expired)",
    TASK_CERTIFICATE_OF_GOOD_STANDING_CHECK_EXP_DATE:
      "Certificate of Good Standing (Expired)",
    TASK_TRADE_LICENSE_CHECK_EXP_DATE: "Trade License (Expired)",
    TASK_COMMERCIAL_LICENSE_CHECK_EXP_DATE: "Commercial License (Expired)",
    TASK_REGISTRY_EXTRACT_CHECK_EXP_DATE: "Registry Extract (Expired)",
    TASK_REGISTER_OF_PARTNERS_CHECK_EXP_DATE: "Register of Partners (Expired)",
    TASK_LIMITED_PARTNERSHIP_AGREEMENT_CHECK_EXP_DATE:
      "Limited Partnership Agreement (Expired)",

    TASK_OFFICIAL_DOCUMENT_LBS_CORP_CHECK_EXP_DATE: "Official Document",
    TASK_CERTIFICATE_OF_GOOD_STANDING_LP_CORP_CHECK_EXP_DATE:
      "Certificate of Good Standing",
    TASK_REGISTER_OF_PARTNERS_OR_LIMITED_PARTNERSHIP_AGREEMENT_LP_CORP_CHECK_EXP_DATE:
      "Register of Partners or Limited Partnership Agreement",

    TASK_RENEWAL_ADGM_REVIEW_DETAILS_DIRECTOR: "Review personal details",
    // TASK_RENEWAL_ADGM_REVIEW_PERSONAL_DETAILS: "Review personal details",
    TASK_IDENTITY_DOCUMENT_CHECK_EXP_DATE:
      "Expired {{{countryName}}} {{{document}}}",
    TASK_RENEWAL_ADGM_OTHER_PASSPORTS: "Other passports",
    TASK_RENEWAL_ADGM_PROOF_OF_ADDRESS: "Proof of address",
    TASK_RENEWAL_ADGM_REVIEW_DETAILS_SHAREHOLDER: "Review personal details",
    TASK_RENEWAL_ADGM_REVIEW_DETAILS_DATA_PROTECTION_CONTACT:
      "Review personal details",
    TASK_RENEWAL_ADGM_REVIEW_DETAILS_AUTHORISED_SIGNATORY:
      "Review personal details",
    TASK_RENEWAL_ADGM_REVIEW_DETAILS_UBO: "Review personal details",
    TASK_RENEWAL_ADGM_REVIEW_CONFIRMATION_DIRECTORS_SHAREHOLDERS:
      "Confirmation of Directors and Shareholders",
    TASK_RENEWAL_ADGM_REVIEW_CORPORATE_COMPANY_DETAILS:
      "Review company details",
  },

  ASSISTANCE_GROUP_COMPANY_DOCUMENTS_NAME: "Documents",

  ASSISTANCE_TASKS_GROUP_COMPANY: {
    TASK_ADD_COMPANY_INFORMATION: "Company information",
    TASK_ADD_COMPANY_INFORMATION_GRFI_ACCELERATOR: "Company information",
    TASK_ADD_ADDRESS_CURRENCY: "Address & currency",
    TASK_ADD_ADDRESS_CURRENCY_GRFI_ACCELERATOR: "Address & currency",
    TASK_ADD_COMPANY_NOTICES: "Notices",
    TASK_ADD_COMPANY_NOTICES_GRFI_ACCELERATOR: "Notices",
    TASK_ADD_CERTIFICATE_INCORPORATION: "Certificate of Incorporation",
    TASK_ADD_CERTIFICATE_INCORPORATION_GRFI_ACCELERATOR:
      "Certificate of Incorporation",
    TASK_ADD_COMMERCIAL_LICENSE: "Commercial Licence",
    TASK_ADD_OTHER_CONSTITUTIONAL_DOCUMENTS: "Other constitutional documents",
    TASK_ADD_COMPANY_EQUITY_POSITIONS: "Company equity",
    TASK_ADD_SHARE_INCENTIVE_PLAN_AGREEMENT:
      "SIP for {{totalShares}} reserved shares amount for {{className}} class",
    TASK_ADD_COMPANY_EQUITY_POSITIONS_GRFI_ACCELERATOR: "Company equity",
    TASK_ADD_SHARE_AGREEMENT:
      "Document for {{amount}} shares {{#groupCompany}}in {{{groupCompany.name}}}{{/groupCompany}} {{#issuedDate}}issued on {{{issuedDate}}}{{/issuedDate}}",
    TASK_SHARE_CAPITAL: "Share capital",
    TASK_PLACE_OF_BUSINESS: "Place of business",
    TASK_CORPORATE_POSITIONS: "Corporate positions",
    RECIPIENT_FOR_NOTICES: "Recipient for Notices",
    TASK_COMPANY_SET_UP: "Company setup",
    TASK_ASSETS_AND_REVENUE: "Assets and revenue",
    TASK_ADGM_AUTHORISED_SIGNATORY: "ADGM authorised signatory",
    TASK_LEASE_DETAILS: "Lease details",
    TASK_ADGM_BUSINESS_PLAN: "Business plan",
    TASK_BANKING_AUTHORITY: "Banking authority",
    TASK_ACCOUNTING_RECORDS: "Accounting records",
    TASK_DATA_PROTECTION_CONTACT: "Data protection contact",
    TASK_ADD_COMPANY_INFORMATION_ACCELERATOR: "Company information",
    TASK_ADD_CONSTITUTIONAL_DOCUMENTS_ACCELERATOR: "Constitutional documents",
    TASK_WARRANTS_ADD: "Add warrants",
    TASK_CONVERTIBLES_ADD: "Add convertibles",
    TASK_OPTION_PLANS_OPTION_GRANTS_ADD: "Add option plans and option grants",
    TASK_SHARE_ISSUANCES_ADD: "Add share positions",
    TASK_SHARE_CLASSES_ADD: "Add share classes",
    TASK_RENEWAL_KY_REVIEW_ECONOMIC_SUBSTANCE_CONFIRMATION:
      "Review Economic Substance Confirmation",
    TASK_RENEWAL_KY_UPLOAD_ANNUAL_ACCOUNTS: "Upload Annual Accounts",

    TASK_RENEWAL_ADGM_COMPLETE_TECH_LICENCE_QUESTIONNAIRE:
      "Complete Tech Licence questionnaire",
    TASK_RENEWAL_ADGM_REVIEW_COMPANY_DETAILS: "Review company details",
    TASK_RENEWAL_ADGM_REVIEW_ADDRESSES: "Review addresses",
    TASK_RENEWAL_ADGM_REVIEW_DIRECTORS: "Review directors",
    TASK_RENEWAL_ADGM_REVIEW_DATA_PROTECTION_CONTACT:
      "Review data protection contact",
    TASK_RENEWAL_ADGM_REVIEW_AUTHORISED_SIGNATORY:
      "Review ADGM Authorised Signatory",
    TASK_RENEWAL_ADGM_REVIEW_CAP_TABLE: "Review cap table",
    TASK_RENEWAL_ADGM_REVIEW_DATA_PROTECTION_DECLARATION:
      "Review data protection declaration",
  },

  ASSISTANCE_GROUP_COMPANY_EQUITY_NAME: "Equity",
  ASSISTANCE_GROUP_COMPANY_EQUITY: {},

  ASSISTANCE_ACCELERATORS_HIT_EMPTY_PROGRAM:
    "You can only join one accelerator program at a time. Select one to get started.",
  ASSISTANCE_ACCELERATORS_HIT_PROGRAM_IN_PROGRESS:
    "In this page you have an overview of all information added so far for {{acceleratorName}} application.",
  ASSISTANCE_ACCELERATORS_HIT_PROGRAM_COMPLETED:
    "Your application has been submitted to the {{acceleratorName}} team for review.",

  ASSISTANCE_CAP_TABLE_HIT:
    "To reach the goals you set and create your cap table, complete these tasks.",

  ASSISTANCE_HEALTH_CHECK_HIT:
    "Let's get your health check ready. First, complete tasks from the list below, then run health check.",

  ASSISTANCE_GENERATE_DOCUMENTS_HIT:
    "Let's generate some documents. Select one to get started.",
  ASSISTANCE_MODELLING_HIT:
    'Please fill in the information in the form and click on "calculate" to generate your scenario.',

  ASSISTANCE_STAKEHOLDER_COMPANY_HIT: "StakeholderCompany",

  ASSISTANCE_GROUP_COMPANY_EDIT_PROFILE: "Edit profile",
  ASSISTANCE_GROUP_COMPANY_MANAGE_DOCUMENTS: "Manage documents",
  ASSISTANCE_GROUP_COMPANY_MANAGE_EQUITY: "Manage equity positions",
  ASSISTANCE_GROUP_COMPANY_MANAGE_ADDRESS_CURRENCY: "Edit address & currency ",
  ASSISTANCE_GROUP_COMPANY_EDIT_NOTICES: "Edit notices",
  ASSISTANCE_GROUP_COMPANY_EDIT_DIRECTORS: "Add directors",

  ASSISTANCE_STAKEHOLDER_INDIVIDUAL_EDIT_ROLES_POSITION:
    "Edit roles & position",

  ASSISTANCE_STAKEHOLDER_STARTUP_DOWNLOAD: "Download all documents",
  ASSISTANCE_STAKEHOLDER_STARTUP_SHARE: "Share profile",
  ASSISTANCE_STAKEHOLDER_STARTUP_EDIT_SUSCRIPTION: "Edit subscription",
  ASSISTANCE_STAKEHOLDER_STARTUP_CANCEL_SUSCRIPTION: "Cancel subscription",
  ASSISTANCE_STAKEHOLDER_STARTUP_EDIT_ADD_CARD: "Edit/add card",

  ASSISTANCE_DOCUMENTS_CREATE: "Generate document",
  ASSISTANCE_DOCUMENTS_ADD_EQUITY_POSITION: "Add equity",
  ASSISTANCE_DOCUMENTS_CONTACT_CLARA_PARTNERS: "Reach out to a legal expert",
  ASSISTANCE_DOCUMENTS_CONTACT_CLARA_SUPPORT: "Contact Clara support",

  ASSISTANCE_BUTTON_MANAGE_FUTURE_LINKED_COMPANIES: "Manage future companies",
  ASSISTANCE_BUTTON_CREATE_DOCUMENT: "Generate document",
  ASSISTANCE_BUTTON_ADD_DOCUMENT: "Add document",
  ASSISTANCE_BUTTON_ADD_EQUITY: "Add equity",
  ASSISTANCE_BUTTON_SHARE_PROFILE: "Share profile",
  ASSISTANCE_BUTTON_EDIT: "Edit profile",
  ASSISTANCE_BUTTON_EDIT_ROLES_POSITIONS: "Edit roles & positions",
  ASSISTANCE_BUTTON_ADD_DOCUMENTS_UPLOAD: "Add document",
  ASSISTANCE_BUTTON_ADD_DOCUMENTS_GENERATE: "Generate document",
  ASSISTANCE_BUTTON_INVITE_COLLABORATOR: "Invite collaborator",
  ASSISTANCE_BUTTON_ADD_EQUITY_CONVERTIBLE: "Add a convertible",
  ASSISTANCE_BUTTON_ADD_EQUITY_SHARE_OPTION: "Add share options",
  ASSISTANCE_BUTTON_ADD_EQUITY_SHARE: "Add a share position",
  ASSISTANCE_BUTTON_ADD_EQUITY_WARRANT: "Add a warrant",
  ASSISTANCE_BUTTON_EDIT_AUTHORISED_SIGNATORIES: "Edit signatories",
  ASSISTANCE_BUTTON_EDIT_NOTICES: "Edit notices",
  ASSISTANCE_BUTTON_GROUP_COMPANY_DIRECTORS: "Add directors",
  ASSISTANCE_BUTTON_ADD_REMARK: "Add remark",
  ASSISTANCE_BUTTON_DOWNLOAD_ALL_DOCUMENT: "Download all documents",
  ASSISTANCE_BUTTON_DOWNLOAD_CAP_TABLE_MODELLING_DOCUMENT: "Download cap table",
  ASSISTANCE_BUTTON_MY_ACCOUNT_UPDATE: "Update password",
  ASSISTANCE_BUTTON_MY_ACCOUNT_EDIT: "Edit account",
  ASSISTANCE_BUTTON_MY_ACCOUNT_DELETE: "Delete account",
  ASSISTANCE_BUTTON_STARTUP_EDIT_SUBSCRIPTION: "Edit subscription",
  ASSISTANCE_BUTTON_STARTUP_CANCEL_SUBSCRIPTION: "Cancel subscription",
  ASSISTANCE_BUTTON_STARTUP_ADD_CARD: "Edit/add card",
  ASSISTANCE_BUTTON_STARTUP_DOWNLOAD: "Download all invoices",
  ASSISTANCE_HELP_HIT:
    "Clara can also define legal terms for you. If you’re unsure about a word used, ask below.",
  ASSISTANCE_BACK: "Previous",
  ASSISTANCE_DOCUMENTS_ACCIONS_HIT:
    "Here are some other things you can do with documents...",
  ASSISTANCE_DOCUMENTS_EQUITY_HIT:
    "Here are some other things you can do with equity...",

  ASSISTANCE_DOCUMENTS_HAVE_UP_ONE:
    "You can upload {{count}} more documents to your profile as part of your free Start subscription before you will need to scale up.",
  ASSISTANCE_DOCUMENTS_HAVE_ONE:
    "You can upload {{count}} more document to your profile as part of your free Start subscription before you will need to scale up.",

  ASSISTANCE_DOCUMENTS_UPGRADE:
    "Generate and store unlimited key legal documents, plus lots more handy feactures.",
  ASSISTANCE_DOCUMENTS_UPGRADE_BUTTON: "Scale up",
  ASSISTANCE_DOCUMENTS_CONGRATS_HIT:
    "All documents added - good job! Access them whenever you need here on Clara.",

  ASSISTANCE_HEALTH_CHECK_HAVE_UP_ONE:
    "You can run the Clara health check {{count}} more times on your profile as part of your free Start subscription before you will need to scale up.",
  ASSISTANCE_HEALTH_CHECK_HAVE_ONE:
    "You can run the Clara health check {{count}} more time on your profile as part of your free Start subscription before you will need to scale up.",

  ASSISTANCE_HEALTH_CHECK_UPGRADE:
    "For unlimited health checks, plus lots more features for ambitious startups, scale up.",
  ASSISTANCE_HEALTH_CHECK_RUN_BUTTON: "Run health check",
  ASSISTANCE_HEALTH_CHECK_CONGRATS_HIT:
    "You did it! You're ready to run a health check.",

  ASSISTANCE_HEALTHCHECK_NOTIFICATION_SUCCESS: "Health check executed",

  ASSISTANCE_GENERATED_DOCUMENTS_CONGRATS_HIT:
    "All documents generated - great job! Access them whenever you need here on Clara.",
  ASSISTANCE_CAP_TABLE_CONGRATS_HIT:
    "You completed those tasks like a true cap table champion!",

  ASSISTANCE_PROFILE_CONGRATS_HIT: "You powered through that profile!",
  ASSISTANCE_MAP_TASK_NAME: "{{{name}}} ({{totals}})",

  ASSISTANCE_DOCUMENTS_GENERATED_DOWNLOAD_DOCUMENT: "Download document",
  ASSISTANCE_DOCUMENTS_GENERATED_RENAME_DOCUMENT: "Rename document",
  ASSISTANCE_DOCUMENTS_GENERATED_DELETE_SIP: "Delete SIP",
  ASSISTANCE_DOCUMENTS_GENERATED_NEW_DOCUMENT: "Generate document",
  ASSISTANCE_DOCUMENTS_GENERATED_UPLOAD_NEW_DOCUMENT: "Upload document",
  ASSISTANCE_DOCUMENTS_GENERATED_VOID_DOCUMENT: "Void document",

  ASSISTANCE_DOCUMENTS_UPLOADED_EDIT_DOCUMENT: "Edit document",
  ASSISTANCE_DOCUMENTS_UPLOADED_DELETE_DOCUMENT: "Delete document",

  ASSISTANCE_BUTTON_VIEW_VESTING_SCHEDULE: "View vesting schedule",
  ASSISTANCE_BUTTON_VIEW_CONNECTED_GRANTS: "View connected grants",
  ASSISTANCE_BUTTON_DELETE_GRANT: "Delete grant",
  ASSISTANCE_BUTTON_DELETE_DOCUMENT_UPLOAD: "Delete document",
  ASSISTANCE_BUTTON_RESEND_DOCUMENT_NOTIFICATION: "Resend",
  ASSISTANCE_BUTTON_GENERATE_DOCUMENT_GET_SIGNATURE: "Get signatures",
  ASSISTANCE_BUTTON_GENERATE_DOCUMENT_UPLOAD: "Generate document",
  ASSISTANCE_BUTTON_TERMINATE_GRANT: "Terminate grant",

  ASSISTANCE_SHARE_PROFILES_LEFT_UP_ONE:
    "You can share your Clara profile with {{count}} more people as part of your free Start subscription before you will need to scale up.",
  ASSISTANCE_SHARE_PROFILES_LEFT_ONE:
    "You can share your Clara profile with {{count}} more person as part of your free Start subscription before you will need to scale up.",

  ASSISTANCE_SHARE_PROFILES_LEFT_UPDATE:
    "You can unlock this and more by buying an upgrade.",
  SHARE_PROFILE: "Share profile",

  ASSISTANCE_GENRATE_DOCUMENTS_LEFT_UP_ONE:
    "You can generate {{count}} more documents as part of your free Start subscription before you will need to scale up.",
  ASSISTANCE_GENRATE_DOCUMENTS_LEFT_ONE:
    "You can generate {{count}} more document as part of your free Start subscription before you will need to scale up.",

  ASSISTANCE_GENRATE_DOCUMENTS_LEFT_UPDATE:
    "Want to keep getting your documents signed?",
  ASSISTANCE_CONTACT_OWNER: "Contact the profile owner to upgrade",

  ASSISTANCE_DOCUMENTS_READY_TITLE:
    "Add documents to your data room ({{completed}}/{{totals}})",
  ASSISTANCE_DOCUMENTS_READY_HELP:
    "Generate or upload stakeholder documents directly from each profile. They'll be stored safely on Clara.",

  ASSISTANCE_CAPTABLE_READY_TITLE:
    "Create and manage cap table ({{completed}}/{{totals}})",
  ASSISTANCE_CAPTABLE_READY_HELP:
    "Create your own cap table by adding your stakeholder's equity details. Go to each stakeholder's profile to get started.",

  ASSISTANCE_DILIGENCE_READY_TITLE:
    "Prepare for due diligence ({{completed}}/{{totals}})",
  ASSISTANCE_DILIGENCE_READY_HELP:
    "Get set for funding rounds by adding your stakeholder's equity details. Go to each stakeholder's profile to get started.",

  ASSISTANCE_MAPS_FORMATION_COMPLETE_DETAILS:
    "Complete Incorporation Details for {{{company}}} ({{completed}}/{{totals}})",
  ASSISTANCE_MAPS_FORMATION_COMPLETE_DETAILS_COMPANY: "Company",
  ASSISTANCE_MAPS_FORMATION_COMPLETE_DETAILS_HELP:
    "Use the expand icon to access the company profile and add info to complete its incorporation.​",

  ASSISTANCE_MAPS_ACCELERATOR_ESSENTIALS: "{{{acceleratorName}}} ({{totals}})",
  ASSISTANCE_MAPS_ACCELERATOR_ESSENTIALS_ADD_TEXT:
    "All tasks complete! You can now submit your application for {{{acceleratorName}}}.",
  ASSISTANCE_MAPS_ACCELERATOR_ESSENTIALS_COMPLETED:
    "Your application has been successfully shared with the accelerator team. Check your emails for any updates.",
  ASSISTANCE_MAPS_ACCELERATOR_ESSENTIALS_SUBMIT_BUTTON: "Submit application",

  ASSISTANCE_MAPS_FORMATION_ESSENTIALS: "Form {{{company}}} ({{totals}})",
  ASSISTANCE_MAPS_RENEWAL_ESSENTIALS_TASKS: "{{{company}}} ({{totals}})",
  ASSISTANCE_MAPS_FORMATION_LBS_ESSENTIALS:
    "{{{company}}} Formation ({{totals}})",
  ASSISTANCE_MAPS_FORMATION_LBS_ESSENTIALS_REOPEN:
    "Stakeholder tasks ({{totals}})",
  ASSISTANCE_MAPS_RENEWAL_ESSENTIALS: "Renewal tasks ({{totals}})",
  ASSISTANCE_MAPS_RENEWAL_ADGM_ESSENTIALS:
    "{{{company}}} renewal tasks ({{totals}})",
  ASSISTANCE_MAPS_FORMATION_ESSENTIALS_COMPANY: "Company",
  ASSISTANCE_MAPS_FORMATION_DETAILS_HELP_CARD:
    "Use the tasks below or the ‘Go to profile’ buttons to complete your company and stakeholders’ details.",
  ASSISTANCE_MAPS_FORMATION_ESSENTIALS_HELP:
    "Use the tasks below or the 'Add Stakeholder' button to add your shareholders and directors.",
  // ASSISTANCE_MAPS_FORMATION_ESSENTIALS_HELPBOTTOM: "Once the tasks above are completed, you can proceed with the payment.",

  ASSISTANCE_MAPS_FORMATION_DETAILS:
    "{{{company}}} Formation Details ({{completed}}/{{totals}})",
  ASSISTANCE_MAPS_FORMATION_LOCK_TITLE:
    "{{{company}}} Formation ({{completed}}/{{totals}})",
  ASSISTANCE_MAPS_FORMATION_DETAILS_HELP:
    "Get everything in place for your formation by adding key info. Use the expand icon on your company and stakeholders to access their profile.",
  ASSISTANCE_MAPS_FORMATION_DETAILS_CONCIERGE_HIT:
    "Now engage the concierge for a review of your formation.",
  ASSISTANCE_MAPS_FORMATION_DETAILS_SUBMIT_FORMATION_HIT:
    "Once all tasks are completed, submit your formation.",
  ASSISTANCE_MAPS_FORMATION_DETAILS_SUBMIT_FORMATION_DONE:
    "All your tasks are completed! You can now submit your formation to Clara.",
  ASSISTANCE_MAPS_FORMATION_DETAILS_CONCIERGE_BUTTON: "Engage concierge",
  ASSISTANCE_MAPS_FORMATION_DETAILS_BUY_PACKAGE_CAYMAN: "Pay and submit",
  ASSISTANCE_MAPS_FORMATION_DETAILS_BUY_PACKAGE_DELAWARE: "Pay and submit",
  ASSISTANCE_MAPS_FORMATION_DETAILS_BUY_PACKAGE: "Pay and submit",
  ASSISTANCE_MAPS_FORMATION_DETAILS_SUBMIT_FORMATION: "Submit formation",
  ASSISTANCE_MAPS_FORMATION_DOWNLOAD_FILES_HIT:
    "Download these details and take them to your nearest formations agent to complete the company setup.",
  ASSISTANCE_MAPS_FORMATION_DOWNLOAD_FILES_BUTTON: "Download files",

  ASSISTANCE_MAPS_FORMATION_DETAILS_DIRECTORS:
    "Complete details for {{count}} directors",
  ASSISTANCE_MAPS_FORMATION_DETAILS_SHAREHOLDERS:
    "Complete details for {{count}} shareholders",
  ASSISTANCE_MAPS_FORMATION_DETAILS_COMPANY: "Complete company details",
  ASSISTANCE_MAPS_FORMATION_DETAILS_CONTACT: "Set primary contact",
  ASSISTANCE_MAPS_FORMATION_DETAILS_COST: "Total cost:",
  ASSISTANCE_MAPS_FORMATION_BLOCK_BUBBLE1:
    "Clara is working on forming this company – hold tight! In the meantime, these profiles can be viewed but not edited:",
  ASSISTANCE_MAPS_FORMATION_BLOCK_BUBBLE2:
    "​Edits can be made once the formation is complete.",
  ASSISTANCE_MAPS_FORMATION_BLOCK_DOCUMENT:
    "While Clara forms this company, this document is available for viewing only. Edits can be made once the formation is complete. ​​​",
  ASSISTANCE_MAPS_FORMATION_BLOCK_COMPANY:
    "While Clara forms this company, this company's profile is available for viewing only. Edits can be made once the formation is complete. ​​​",
  ASSISTANCE_MAPS_FORMATION_BLOCK_STAKEHOLDER:
    "While Clara forms this company, this stakeholder’s profile is available for viewing only. Edits can be made once the formation is complete. ​​​",
  ASSISTANCE_MAPS_FORMATION_BLOCK_BUBBLE3:
    "There are 7 steps left of your formation – hold tight! Currently:",

  ASSISTANCE_MAPS_FORMATION_BLOCK_BUBBLE4:
    "<strong>Good News!</strong> Clara has started the review of your {{{jurisdiction}}} formation.",
  ASSISTANCE_MAPS_FORMATION_BLOCK_BUBBLE5:
    "We will let you know when the funds have been received in our account. Only after this point the submission will be filled with the Regulator.",

  ASSISTANCE_CARD_INDIVIDUAL_DETAILS_TITLE:
    "Details ({{completed}}/{{totals}})",
  ASSISTANCE_CARD_INDIVIDUAL_PRIMARY_CONTACT: "Primary contact",
  ASSISTANCE_CARD_INDIVIDUAL_PEP: "PEP",
  ASSISTANCE_CARD_INDIVIDUAL_SOURCE_FUNDS: "Source of funds (ADGM)",
  ASSISTANCE_CARD_INDIVIDUAL_SOURCE_FUNDS_CAYMAN: "Source of funds (Cayman)",
  ASSISTANCE_CARD_INDIVIDUAL_SOURCE_FUNDS_DIFC: "Source of funds (DIFC)",
  ASSISTANCE_CARD_INDIVIDUAL_SOURCE_FUNDS_GENERIC_BUILD: "Source of funds",
  ASSISTANCE_CARD_INDIVIDUAL_PROOF_ADDRESS: "Proof of address",
  ASSISTANCE_CARD_INDIVIDUAL_PASSPORT: "Passport",
  ASSISTANCE_CARD_INDIVIDUAL_PASSPORT_UPLOAD: "Upload passport {{count}}",
  ASSISTANCE_CARD_INDIVIDUAL_PASSPORT_UPLOAD_COUNTRY: "{{{country}}} passport",
  ASSISTANCE_CARD_INDIVIDUAL_PROFESSIONAL_BACKGROUND: "Professional background",

  ASSISTANCE_CARD_INDIVIDUAL_ADGM_TITLE: "ADGM ({{completed}}/{{totals}})",
  ASSISTANCE_CARD_INDIVIDUAL_ADGM_EMIRATES_ID: "Emirates ID",
  ASSISTANCE_CARD_INDIVIDUAL_ADGM_UAE_VISA: "UAE residence visa",
  ASSISTANCE_CARD_INDIVIDUAL_ADGM_UID_STAMP: "UID stamp",
  ASSISTANCE_CARD_INDIVIDUAL_DELAWARE_ADDRESSES: "Addresses",
  ASSISTANCE_CARD_INDIVIDUAL_ADGM_UAE_ENTRY: "UAE entry stamp",
  ASSISTANCE_CARD_INDIVIDUAL_ADGM_ADDRESS_FAMILY:
    "Address & family contact page",
  ASSISTANCE_CARD_INDIVIDUAL_ADGM_GCC_RESIDENT_ID: "GCC resident ID",
  ASSISTANCE_CARD_INDIVIDUAL_ADGM_NATIONAL_ID: "National ID",

  ASSISTANCE_CARD_COMPANY_ADGM_SETUP: "Company setup",
  ASSISTANCE_CARD_COMPANY_ADGM_ASSETS: "Assets and revenue",
  ASSISTANCE_CARD_COMPANY_ADGM_AUTHORISED: "ADGM authorised signatory",
  ASSISTANCE_CARD_COMPANY_LEESE: "Lease agreement",
  ASSISTANCE_CARD_COMPANY_BUSINESS_PLAN: "Business plan",

  ASSISTANCE_CARD_COMPANY_ADGM_BANKING: "Banking authority",
  ASSISTANCE_CARD_COMPANY_ADGM_RECORDS: "Accounting records",
  ASSISTANCE_CARD_COMPANY_ADGM_PROTECTION: "Data protection contact",

  ASSISTANCE_CARD_PROFILE_CONFIRM: "Confirm directors and shareholders",
  ASSISTANCE_CARD_NATURE_OF_BUSINESS: "Nature of business",
  ASSISTANCE_CARD_COMPANY_CONFIRM_TITLE: "Confirm ({{completed}}/{{totals}})",
  ASSISTANCE_CARD_COMPANY_CONFIRM_CERTIFICATE_INCORPORATION:
    "Upload certificate of incorporation",
  ASSISTANCE_CARD_COMPANY_CONFIRM_COMPANY_DETAILS: "Confirm company details",

  ASSISTANCE_CARD_DOCUMENT_REVIEW_TITLE:
    "Document review ({{completed}}/{{totals}})",
  ASSISTANCE_CARD_DOCUMENT_REVIEW_HELP:
    "Read through these documents and review the contents.",
  ASSISTANCE_CARD_DOCUMENT_REVIEW_HELP_MAP:
    "Go to the relevant profile pages to review these documents.",
  ASSISTANCE_CARD_DOCUMENT_REVIEW_RESOLUTION_OF_INCORPORATING_SHEREHOLDERS:
    "Resolution of Incorporating Shareholder(s)",
  ASSISTANCE_CARD_DOCUMENT_REVIEW_BUSINESS_PLAN: "Business plan",
  ASSISTANCE_CARD_DOCUMENT_REVIEW_DATA_PROTECTION_DECLARATION_QUESTIONNAIRE:
    "Data Protection Declaration Questionnaire",
  ASSISTANCE_CARD_DOCUMENT_REVIEW_MAP: "Review documents for {{{company}}}",
  ASSISTANCE_CARD_DOCUMENT_REVIEW_CORPORATE_SHAREHOLDER_RESOLUTION:
    "Corporate Shareholder Resolution",

  ASSISTANCE_MAP_NEXT_TITLE: "Next step",
  ASSISTANCE_MAP_ESOP: "Incentivise your team with a SIP (ESOP)",
  ASSISTANCE_VIEW_DOCUMENT: "View document",
  ASSISTANCE_ESOP_LEARN_MORE: "Learn more",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_COMPANIES_INCORPORATED:
    "Add your <strong>companies</strong>",
  ASSISTANCE_MAP_NEXT_HELP_TASK_ADD_COMPANIES_INCORPORATED:
    "Your first step is to add your companies.",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_COMPANIES_GET_READY:
    "Add your <strong>companies</strong>",
  ASSISTANCE_MAP_NEXT_HELP_TASK_ADD_COMPANIES_GET_READY:
    "Your first step is to add your companies.",

  ASSISTANCE_MAP_NEXT_HELP_TASK_ADD_COMPANY_ADGM_BUY_REFER:
    "Your first step is to add your company.",
  ASSISTANCE_MAP_NEXT_HELP_TASK_ADD_COMPANY_FORMATION:
    "Your first step is to add your company.",
  ASSISTANCE_MAP_NEXT_COMPANIES: "Companies",
  ASSISTANCE_MAP_NEXT_COMPANY: "Company",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_COMPANY_ADGM_BUY_REFER:
    "Add your <strong>company</strong>",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_COMPANY_FORMATION:
    "Add your <strong>company</strong>",

  ASSISTANCE_MAP_TASK_WELCOME_READY:
    "Great, you want to create your profile! Click the blue button or the step below to start <strong>adding your existing companies</strong>. From there you can also form a new company. Then follow the task list that will appear here.",
  ASSISTANCE_MAP_TASK_WELCOME_FORMATION:
    "Great, you want to form a company! Click the blue button on the map or the step below to start <strong>forming your company</strong>.  From there you can also add your existing companies. Then follow the task list that will appear here.",
  ASSISTANCE_MAP_TASK_READY_ESSENTIALS_TITLE:
    "Get ready for investment ({{totals}})",
  ASSISTANCE_MAP_TASK_READY_ESSENTIALS_HELP:
    "Follow this task list to create your profile on Clara. Use the tasks below or the '+' on the map to add your stakeholders.",

  ASSISTANCE_MAP_TASK_WELCOME_ACCELERATOR:
    "Follow the task below to complete the program onboarding.",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_COMPANIES_ACCELERATOR:
    "To prepare for <strong>{{{acceleratorName}}}</strong>",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_SELECT_TOPCO_ACCELERATOR:
    "To prepare for <strong>{{{acceleratorName}}}</strong>",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_COMPLETE_QUESTIONNAIRE_ACCELERATOR:
    "To prepare for <strong>{{{acceleratorName}}}</strong>",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_OR_GENERATE_BOARD_RESOLUTION_ACCELERATOR:
    "To prepare for <strong>{{{acceleratorName}}}</strong>",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_CONFIRM_DOCUMENTS_ACCELERATOR:
    "To prepare for <strong>{{{acceleratorName}}}</strong>",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_COMPLETE_STAKEHOLDERS_ACCELERATOR:
    "To prepare for <strong>{{{acceleratorName}}}</strong>",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_OR_GENERATE_1_FOUNDER_AGREEMENT_ACCELERATOR:
    "To prepare for <strong>{{{acceleratorName}}}</strong>",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_OR_GENERATE_N_FOUNDER_AGREEMENT_ACCELERATOR:
    "To prepare for <strong>{{{acceleratorName}}}</strong>",
  ASSISTANCE_MAP_TASK_SELECT_TOPCO_ACCELERATOR_COMPLETE_HELP:
    "The rest of your tasks will show here once a topco is selected.",
  ASSISTANCE_MAP_ALL_TASK_ACCELERATOR_COMPLETE_HELP:
    "Complete the tasks below.",

  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_DIRECTORS_FORMATION:
    "Add your <strong>directors</strong>",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_DIRECTORS_GET_READY:
    "Add your <strong>directors</strong>",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_DIRECTORS_ADGM_BUY_REFER:
    "Add your <strong>directors</strong>",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_FOUNDERS:
    "Add your <strong>founders</strong>",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_FOUNDERS_GET_READY:
    "Add your <strong>founders</strong>",

  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_EMPLOYEES:
    "Add your <strong>employees</strong>",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_EMPLOYEES_GET_READY:
    "Add your <strong>employees</strong>",
  ASSISTANCE_MAP_NEXT_HELP_TASK_ADD_EMPLOYEES:
    "Add your employees, if you dont have any use the bin icon to remove this task.",
  ASSISTANCE_MAP_NEXT_HELP_TASK_ADD_EMPLOYEES_GET_READY:
    "Add your employees, if you dont have any use the bin icon to remove this task.",

  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_CONSULTANTS:
    "Add your <strong>consultant</strong>",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_CONSULTANTS_GET_READY:
    "Add your <strong>consultant</strong>",
  ASSISTANCE_MAP_NEXT_HELP_TASK_ADD_CONSULTANTS:
    "Add your consultant, if you dont have any use the bin icon to remove this task.",
  ASSISTANCE_MAP_NEXT_HELP_TASK_ADD_CONSULTANTS_GET_READY:
    "Add your consultant, if you dont have any use the bin icon to remove this task.",

  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_ADVISORS:
    "Add your <strong>advisor</strong>",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_ADVISORS_GET_READY:
    "Add your <strong>advisor</strong>",
  ASSISTANCE_MAP_NEXT_HELP_TASK_ADD_ADVISORS:
    "Add your advisor, if you dont have any use the bin icon to remove this task.",
  ASSISTANCE_MAP_NEXT_HELP_TASK_ADD_ADVISORS_GET_READY:
    "Add your advisor, if you dont have any use the bin icon to remove this task.",

  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_INVESTORS:
    "Add your <strong>investor</strong>",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_INVESTORS_GET_READY:
    "Add your <strong>investor</strong>",
  ASSISTANCE_MAP_NEXT_HELP_TASK_ADD_INVESTORS:
    "Add your investor, if you dont have any use the bin icon to remove this task.",
  ASSISTANCE_MAP_NEXT_HELP_TASK_ADD_INVESTORS_GET_READY:
    "Add your investor, if you dont have any use the bin icon to remove this task.",

  ASSISTANCE_MAP_READY_INDIVIDUALS_HELP:
    "Follow this task list to create your profile on Clara. Use the tasks below or the '+' on the map to add your stakeholders.",
  ASSISTANCE_STAKEHOLDER_READY_HELP:
    "These are the tasks related to complete your profile.",
  ASSISTANCE_STAKEHOLDER_LBS_HELP:
    "These are all the tasks related to forming your company in {{{jurisdiction}}}.",
  ASSISTANCE_STAKEHOLDER_LBS_HELP_REOPEN:
    "These are the tasks that need to be completed to finish the renewal",
  ASSISTANCE_RENEWAL_HELP:
    "These are all the tasks need to complete for renewing.",
  ASSISTANCE_RENEWAL_ADGM_HELP:
    "These are all the tasks related to renewing your company in Abu Dhabi Global Market.",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_SHAREHOLDER_ADGM_BUY_REFER:
    "Add your <strong>shareholder</strong>",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_PENDING_GROUP_COMPANY_INCORPORATED:
    "Complete the company details for {{{company}}}",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_PENDING_GROUP_COMPANY_INCORPORATED_ACCELERATOR:
    "Complete the company details for {{{company}}}",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_PENDING_GROUP_COMPANY_FORMATION:
    "Complete the company details for {{{company}}}",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_PENDING_STAKEHOLDER_FORMATION:
    "Complete details for {{{params.stakeholder.fullName}}}",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_PENDING_STAKEHOLDER_INCORPORATED:
    "Complete details for {{{params.stakeholder.fullName}}}",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_PENDING_STAKEHOLDER_INCORPORATED_ACCELERATOR:
    "Complete details for {{{params.stakeholder.fullName}}}",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_SHAREHOLDERS_ADGM_BUY_REFER:
    "Add your <strong>shareholder</strong>",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_PENDING_GROUP_COMPANY:
    "Complete the company details for {{{company}}}",

  ASSISTANCE_GROUPCOMPANY_JURISDICTION_HELP:
    "These are all the tasks related to forming your company in {{{company}}}.",
  ASSISTANCE_FORMATIONS_TITLE: "{{{company }}} Formation ({{totals}})",
  ASSISTANCE_REOPEN_TITLE: "Stakeholder details ({{totals}})",
  ASSISTANCE_FORMATIONS_MULTIPLE_TITLE: "Form your companies ({{totals}})",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_SHAREHOLDERS_FORMATION:
    "Add your <strong>shareholders</strong>",

  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_SHAREHOLDERS:
    "Add your <strong>shareholders</strong>",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_SUBMIT:
    "Submit your <strong>formation</strong>",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_SUBMIT_ACCELERATOR:
    "Submit your <strong>application</strong>",

  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_BUY_PACKAGE_BUY_REFER:
    "Pay for your <strong>Formation</strong>",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_ENGAGE_CONCIERGE_REFER:
    "You need <strong>engage concierge</strong>",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_DOWNLOAD_GENERIC_BUILD:
    "Your company is <strong>already formed?<strong>",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_ADD_OR_GENERATE_N_FOUNDERS_AGREEMENT_ACCELERATOR:
    "To prepare for <strong>{{{acceleratorName}}}</strong>",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_SHARE_CLASSES_ADD:
    "Complete the tasks below.",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_CONVERTIBLES_ADD:
    "Complete the tasks below.",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_OPTION_PLANS_OPTION_GRANTS_ADD:
    "Complete the tasks below.",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_SHARE_ISSUANCES_ADD:
    "Complete the tasks below.",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_SHARE_CLASSES_ADD:
    "Complete the tasks below.",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_WARRANTS_ADD: "Complete the tasks below.",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_RENEWAL_KY_REVIEW_ECONOMIC_SUBSTANCE_CONFIRMATION:
    "Complete the tasks below.",
  ASSISTANCE_MAP_NEXT_SUBTITLE_TASK_RENEWAL_KY_UPLOAD_ANNUAL_ACCOUNTS:
    "Complete the tasks below.",
  ASSISTANCE_FORMATIONS_MULTIPLE_HELP:
    "These are all the tasks related to forming your companies",
  ASSISTANCE_ALL_FORMATIONS: "All formations",
  ASSISTANCE_ADGM: "ADGM",
  ASSISTANCE_DELAWARE: "Delaware",
  ASSISTANCE_KY: "Cayman",
  ASSISTANCE_DELAWARE: "Delaware",
  ASSISTANCE_DIFC: "DIFC",
  ASSISTANCE_OTHER: "Others",
  ASSISTANCE_GENERIC_BUILD: "Others formations",
  HIT_WAIT: "In the meantime, these profiles can be viewed but not edited:",
  ASSISTANCE_MAP_NEXT_SUBTITLE_ADGM_RENEWAL_GROUP_COMPANY_TASKS:
    "Complete the tasks below.",

  ASSISTANCE_CARD_COMPANY_CAYMAN_AWAITING_FUNDS_STARTED:
    "<b>Good news!</b> Clara has started the review of your Cayman Islands formation.",
  ASSISTANCE_CARD_COMPANY_ADGM_AWAITING_FUNDS_STARTED:
    "<strong>Good news!</strong> Clara has started the review of your ADGM formation.",
  ASSISTANCE_CARD_COMPANY_AWAITING_FUNDS_UNDER_REVIEW:
    "We will let you know when the funds have been received in our account. Only after this point the submission will be filed with the Regulator.",

  // ADGM TLS
  ASSISTANCE_TASKS_BLOCK_STEPS_TSL: {
    STEP_10: "Reviewing your information and submitting business plan",
    STEP_5: "Reviewing documents and preparing incorporation documents",
    STEP_20: "All your information have been reviewed",
    HIT_20:
      "Your business plan has been approved by ADGM. Now you can complete your lease details task.",
    STEP_30: "Awaiting your approval of incorporation documents",
    HIT_30:
      "We are awaiting for you to review the incorporation documents and approve the drafts.",
    STEP_40: "Preparing documents for signature",
    HIT_40:
      "We are preparing your incorporation documents for signing through our DocuSign integration.",
    STEP_50: "Awaiting signatures on incorporation documents",
    HIT_50:
      "Your incorporation pack is out for signatures. We are waiting for everyone to sign.",
    STEP_60: "Submitting application to ADGM",
    HIT_60:
      "We are finalizing your application and submitting the information to ADGM for their review.",
    STEP_70: "ADGM reviewing application",
    HIT_70:
      "ADGM is reviewing your application and will inform us when the process is complete.",
    STEP_80: "Preparing welcome pack",
    HIT_80:
      "We have received confirmation from ADGM and are now preparing your welcome pack.",
    STEP_90: "Awaiting share certificate signatures",
    HIT_90:
      "Your share certificates are out for signature. We are waiting for everyone to sign.",
    NORMAL: {
      HIT_10: "We are currently reviewing your information and business plan.",
    },
    HIT_5:
      "We are currently reviewing your information and preparing the draft of your incorporation documents.",
    PRE_APPROVED: {
      HIT_10:
        "All your information have been reviewed. Now you can complete your lease details task",
    },
    NO_APPROVED: {
      HIT_10:
        "Your business plan has been approved by ADGM. Now you can complete your lease details task",
    },
  },

  // ADGM SPV
  ASSISTANCE_TASKS_BLOCK_STEPS_SPV: {
    HIT_5:
      "We are currently reviewing your information and preparing the draft of your incorporation documents.",
    STEP_5: "Reviewing documents and preparing incorporation documents",
    STEP_11: "Reviewing documents and preparing incorporation documents",
    HIT_11:
      "We are currently reviewing your information and preparing the drafts of your incorporation documents.",
    STEP_22: "Awaiting your approval of incorporation documents",
    HIT_22:
      "We are waiting on you to review the incorporation documents and approve the drafts.",
    STEP_33: "Preparing documents for signature",
    HIT_33:
      "We are preparing your incorporation documents for signing through our DocuSign integration.",
    STEP_44: "Awaiting signatures on incorporation documents",
    HIT_44:
      "Your incorporation pack is out for signatures. We are waiting for everyone to sign.",
    STEP_56: "Submitting application to ADGM",
    HIT_56:
      "We are finalizing your application and submitting the information to ADGM for their review.",
    STEP_67: "ADGM reviewing application",
    HIT_67:
      "ADGM is reviewing your application and will inform us when the process is complete.",
    STEP_78: "Preparing welcome pack",
    HIT_78:
      "We have received confirmation from ADGM and are now preparing your welcome pack.",
    STEP_89: "Awaiting share certificate signatures",
    HIT_89:
      "Your share certificates are out for signature. We are waiting for everyone to sign.",
  },

  // Cayman Percents
  ASSISTANCE_TASKS_BLOCK_STEPS_EXEMPTED_COMPANY: {
    STEP_5: "Reviewing documents and preparing incorporation documents",
    HIT_5:
      "We are currently reviewing your information and preparing  draft of your incorporation documents",

    STEP_8: "Certifying passports",
    HIT_8:
      "We are currently certifying the passports of the directors and shareholders.",
    STEP_17: "Preparing KYC",
    HIT_17:
      "We are preparing the documents that you will need in order to complete our Know Your Client (KYC) requirements.",
    STEP_25: "KYC in draft",
    HIT_25:
      "We have prepared and your KYC documents and are reviewing them to make sure everything is in order.",
    STEP_33: "KYC ready",
    HIT_33:
      "The documents need for our Know Your Client (KYC) requiements are complete and will be sent to you shortly for signing through DocuSign.",
    STEP_42: "Waiting for KYC signatures",
    HIT_42:
      "The documents needed for our Know Your Client (KYC) requiements are out for signature. Please check your inbox for the relevant emails from DocuSign.",
    STEP_50: "Reviewing KYC",
    HIT_50:
      "Your KYC documents have been signed and we are reviewing everything.",
    STEP_58: "Preparing incorporation documents",
    HIT_58:
      "We are preparing the documents necessary for the incorporation of your company.",
    STEP_67: "Incorporation documents ready",
    HIT_67:
      "The documents required for incorporating your company are ready and we will submit shortly.",
    STEP_75: "Application submitted and under review",
    HIT_75:
      "We have submitted your application and it is under review by the Cayman General Registry.",
    STEP_83: "Preparing welcome pack",
    HIT_83:
      "Your application has been approved and we are preparing your welcome pack.",
    STEP_92: "Waiting for welcome pack signatures",
    HIT_92:
      "We have sent out your welcome pack for signature. Please check your inbox for the relevant DocuSign emails.",
  },

  // Delaware Percents
  ASSISTANCE_TASKS_BLOCK_STEPS_C_CORP: {
    STEP_20: "Reviewing data",
    HIT_20: "We are currently reviewing your information.",
    STEP_40: "Certificate of Incorporation ready",
    HIT_40:
      "The Certificate of Incorporation has been generated and sent to the Sole Incorporator  for signature.",
    STEP_60: "Certificate of Incorporation signed",
    HIT_60:
      "The Certificate of Incorporation has been signed and it’s now being filed.",
    STEP_80: "Welcome Pack ready",
    HIT_80:
      "The Certificate of Incorporation has been stamped and formally issued, and the Welcome Pack has been sent out for signature. Please check your inbox for the relevant DocuSign emails.",
    STEP_100: "Company incorporated",
    HIT_100: "Your formation process is now completed!",
  },

  // Cayman Renewal Percents
  ASSISTANCE_TASKS_BLOCK_STEPS_CAYMAN_RENEWAL: {
    STEP_5: "Awaiting funds",
    STEP_20: "Reviewing data",
    STEP_40: "Documents preparation",
    STEP_60: "Documents waiting for signature",
    STEP_80: "Government filings",
    STEP_100: "Company renewal complete",
  },
  // ADGM Renewal Percents
  ASSISTANCE_TASKS_BLOCK_STEPS_ADGM_RENEWAL: {
    STEP_5: "Awaiting funds",
    STEP_20: "Reviewing data",
    STEP_40: "Documents preparation",
    STEP_60: "Documents waiting for signature",
    STEP_80: "Government filings",
    STEP_100: "Company renewal complete",
  },
  ASSISTANCE_MAP_NEXT_SUBTITLE_ADGM_RENEWAL_STAKEHOLDERS_TASKS:
    "Complete the tasks below.",
};
