import React from "react";
import * as Yup from "yup";
import Form from "../../../../components/forms/Form/Form";
import useTranslate from "../../../generic/hooks/useTranslate";
import TextInput from "../../../../components/inputs/Text/TextInput";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import TemplateLabel from "../../../generic/components/Inputs/LabelInput";
import SubmitButton from "../../../generic/components/Buttons/SubmitButton";
import FormProps from "../../../generic/forms/interfaces/FormProps";
import TemplateFormDefault from "../../../generic/templates/Modal";
import cls from "./Form.module.scss";
import { Constants } from "../../../../v1/utils";
import CalendarInputDate from "../../../../components/inputs/Calendars/CalendarInputDate";
import PhoneInput from "../../../generic/components/Inputs/PhoneInput/index";

const schema = Yup.object().shape({
  dateOfBirth: Yup.date()
    .required("This field is required")
    .typeError("Invalid date")
    .nullable(),
  profession: Yup.string().nullable().required("This field is required"),
  email: Yup.string()
    .required("This field is required")
    .matches(Constants.REGEXP.EMAIL, {
      message: "Invalid Email",
      excludeEmptyString: true,
    }),
  phoneNumber: Yup.string()
    .nullable()
    .required("This field is required")
    .typeError("This field is required"),
});

const FormMoreDetails: React.FC<FormProps> = ({
  initialValues: defaultValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}) => {
  const { translate } = useTranslate();

  const handleSubmit = async (values) => {
    onCompleteSubmit && (await onCompleteSubmit(values));
  };

  return (
    <Form
      defaultValues={defaultValues}
      schema={schema}
      onSubmit={handleSubmit}
      onlySendDirty={false}
      optionsForm={{ mode: "onChange" }}
    >
      <Template
        buttons={{
          ...buttons,
          submit: <SubmitButton {...buttonSubmitProps}>Add</SubmitButton>,
        }}
        props={propsTemplate}
      >
        <TemplateLabel label={"Email"} className={cls.TemplateLabel} isRequired>
          <ControllerInput
            render={TextInput}
            name="email"
            type={"email"}
            defaultlabel={""}
            placeholder="name@example.com"
          />
        </TemplateLabel>

        <TemplateLabel
          label={"Telephone number"}
          className={cls.TemplateLabel}
          isRequired
        >
          <ControllerInput
            render={PhoneInput}
            disableDropdown={false}
            name="phoneNumber"
            defaultlabel={""}
            placeholder="Include numbers"
          />
        </TemplateLabel>
        <TemplateLabel
          label={"Date of birth"}
          className={cls.TemplateLabel}
          isRequired
        >
          <ControllerInput
            render={CalendarInputDate}
            name="dateOfBirth"
            defaultlabel={""}
            placeholder="dd/mm/yyyy"
          />
        </TemplateLabel>
        <TemplateLabel
          isRequired
          label={translate("MODULES.CLARA.FORM_PROFESSION")}
          className={cls.TemplateLabel}
        >
          <ControllerInput
            as={TextInput}
            name="profession"
            defaultValue={""}
            placeholder={translate("MODULES.CLARA.EXAMPLE_PROFESSION")}
            onChange={(e) => {}}
          />
        </TemplateLabel>
      </Template>
    </Form>
  );
};

export default FormMoreDetails;
