import useLazyQuery from 'src/hooks/custom/useLazyQuery'

const GET_TASK_BY_TYPE = `
  query getTaskByType($startupId: ID!, $cardCode: CardCode!, $entity: CardEntityInputType!) {
    getTaskByType(startupId: $startupId, cardCode: $cardCode, entity: $entity) {
      code
      entity {
        id
        type
        name
        avatar
      }
      tasks {
        code
        status
        additionalParams {
          entityId
          entityType
          name
          type
          country {
            code
          }
        }
      }
      subGroupTask {
        code
        entity {
          id
          type
          name
        }
        tasks {
          code
          status
          additionalParams {
            entityId
            entityType
            name
            type
            country {
              code
            }
          }
        }
      }
      count
      card {
        code
        entity {
          id
          name
          type
        }
        tags
        infoCard {
          useVAT
          price {
            amount
            currency
          }
        }
      }
    }
  }
`;
/**
 * Get count by task
 */
const useGetTaskByType = ({ variables, ...props }, config) => {
  const { manualQuery, loading, error, data, refetch } = useLazyQuery(
    GET_TASK_BY_TYPE, 
    { ...variables, },
    {...config,  notifyOnNetworkStatusChange: true, fetchPolicy: 'no-cache', nextFetchPolicy: 'no-cache'},
    props);
  return { manualQuery, loading, error, data, refetch };
};

export default useGetTaskByType;
