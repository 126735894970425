import { default as React, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import Form from "src/components/forms/Form/Form";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import * as Yup from "yup";
import Button from "src/modules/generic/components/Buttons/Button";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  clearAssistatText,
  addAssistantText,
} from "src/modules/generic/store/action";
import Typography from "src/modules/generic/components/Texts/Typography";
import FileUploaderInput from "src/components/inputs/FileUploader/FileUploaderInput";
import classes from "./classes.module.scss";
import useGetContentfulTemplate from "src/modules/generic/hooks/useGetContentfulTemplate";
function UploadAnnualAccountsStepForm() {
  const { next, context, cancel } = useMachine();

  const { translate } = useTranslate();

  const { fetch } = useGetContentfulTemplate("ANNUAL_ACCOUNTS", {
    cancelAutomaticFetch: true,
  });

  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.UploadAnnualAccountsStepForm ?? {};
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  };

  const handleSubmit = async (values) => {
    next(values);
  };

  useEffect(() => {
    dispatch(
      addAssistantText(
        translate(
          "MODULES.RENEWALS.UPLOAD_ANNUAL_ACCOUNTS_MODAL.STEP_1.TOOLTIP_1"
        )
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    documentAnnualAccounts: Yup.object()
      .nullable()
      .required("This field is required"),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: translate(
            "MODULES.RENEWALS.UPLOAD_ANNUAL_ACCOUNTS_MODAL.STEP_1.TITLE"
          ),
          skeleton: null,
          subTitle: translate(
            "MODULES.RENEWALS.UPLOAD_ANNUAL_ACCOUNTS_MODAL.STEP_1.TITLE"
          ),
          variant: "simple",
        }}
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          submit: (
            <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>
              {translate("GENERIC_BUTTON_ADD_GROUP")}
            </SubmitButton>
          ),
        }}
      >
        <>
          <Typography component="p" variant="body" weight="semibold">
            {translate(
              "MODULES.RENEWALS.UPLOAD_ANNUAL_ACCOUNTS_MODAL.STEP_1.CONTENT_1"
            )}{" "}
            <a onClick={fetch}>here.</a>
          </Typography>
          <TemplateLabel
            label={translate(
              "MODULES.RENEWALS.UPLOAD_ANNUAL_ACCOUNTS_MODAL.STEP_1.FIELD_DOCUMENT"
            )}
            isRequired
            variant={"top"}
          >
            <ControllerInput
              render={FileUploaderInput}
              name="documentAnnualAccounts"
              defaultlabel={""}
              className={classes.fileLoad}
              typeFileAllowed={
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              }
              icons={["XLXS"]}
            />
          </TemplateLabel>
        </>
      </TemplateWizard>
    </Form>
  );
}

export default UploadAnnualAccountsStepForm;
